<div class="pagetitle">
  <h2>{{ 'related-apps.parametersExtra' | translate }}</h2>
</div>


<!-- Sección de encabezado para mostrar los headers -->
<div class="header-inputs" *ngIf="headerInputs">
  <div>
    <label class="title-small">{{ 'relatedExtra.fileName' | translate }}: </label>
    <label>{{ headerInputs.fileName }}</label>
  </div>
  <div>
    <label class="title-small">{{ 'relatedExtra.storageType' | translate }}: </label>
    <label>{{ headerInputs.storageType }}</label>
  </div>
  <div>
    <label class="title-small">{{ 'relatedExtra.path' | translate }}: </label>
    <label>{{ headerInputs.path }}</label>
  </div>
  <div>
    <label class="title-small">{{ 'relatedExtra.version' | translate }}: </label>
    <label>{{ headerInputs.version }}</label>
  </div>
</div>

<app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

<div class="table-containter">

  <div id="tableDescription" style="display:none;">{{ 'relatedExtra.tableDescription' | translate }}</div>

  <table class="table related-extra-table" aria-labelledby="tableDescription">
    <thead>
      <tr>
        <th scope="col">{{ 'relatedExtra.serial' | translate }}</th> <!-- Columna Serial -->
        <th scope="col" *ngFor="let column of dynamicColumns">{{ column }}</th> <!-- Mostrar otras columnas dinámicas -->
        <th scope="col">{{ 'relatedExtra.used' | translate }}</th> <!-- Columna Usado -->
      </tr>
    </thead>
    <tbody class="related-extra-tbody">
      <tr *ngFor="let paramExtra of listRelatedExtra">
        <td class="related-extra-cells">{{ paramExtra.numSerie }}</td>
        <td class="related-extra-cells" *ngFor="let column of dynamicColumns">{{ paramExtra[column] }}</td> <!-- Mostrar valores de las columnas dinámicas -->
        <td class="related-extra-cells">
          {{ paramExtra.usado ? ('common.yes' | translate) : ('common.no' | translate) }} 
        </td>
      </tr>
    </tbody>
  </table>

  <!-- Empty state -->
  <ng-container *ngIf="!listRelatedExtra.length">
    <app-table-empty-state [icon]="false"></app-table-empty-state>
  </ng-container>

  <!-- Paginador -->
  <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent()"
    showFirstLastButtons aria-label="Select page of periodic elements">
  </mat-paginator>
</div>


<div class="botonera mt-3">
  <button mat-stroke-button class="custom-white-button" (click)="cancelChanges()">
      {{ 'common.cancel' | translate }}
  </button>
</div>