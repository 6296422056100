import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { TranslateModule } from '@ngx-translate/core';
import { AppliedFilter, 
  CompareValueType, 
  DateFilterData, 
  FieldType, 
  FilterData, 
  FilterDisplay, 
  FilterField, 
  FilterFormData, 
  FilterOperator, 
  FilterOption, 
  OperatorType 
} from 'src/app/models/filter.model';

@Component({
  standalone:true,
  imports:[
    CommonModule, 
    TranslateModule, 
    ReactiveFormsModule,
    MatLegacyInputModule,
    MatLegacyFormFieldModule,
    MatLegacyButtonModule,
    MatLegacySelectModule
  ],
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent {
  public filterForm: FormGroup;
  public fields:FilterField[] = [];
  public operators:FilterOperator[] = [];
  
  public inputType:string = 'text';
  public hasOptions:boolean = false;
  public options:FilterOption[] = [];

  constructor(
    @Inject(DIALOG_DATA) public data: FilterFormData,
    private formBuilder:FormBuilder,
    public dialog:DialogRef
  ){
    this.filterForm = this.formBuilder.group({
      field: [null, Validators.required],
      operator: [null, Validators.required],
      compareValue: ["", Validators.required],
    });
    this.fields = data.fields;
    this.operators = data.operation;

    this.filterForm.get('field')?.valueChanges.subscribe((field:FilterField)=>{
      this.updateCompareValueInputConfig(field);
      this.updateOperatorInputConfig(field,data.operation);
    })
  }

  private updateCompareValueInputConfig(field:FilterField){
    this.inputType = field.inputType;
    this.hasOptions = !!field.options?.length; 
    this.options = field.options ?? [];
  }

  private updateOperatorInputConfig(field:FilterField, baseOperators:FilterOperator[]){
    this.operators = baseOperators.filter((opt)=>{ 
      return opt.showOnType.includes(field.inputType);
    });
  }

  public getLabelFromOptions(value:CompareValueType): string {
    if(this.hasOptions){
      const optionSelected = this.options.find((opt)=>{ return opt.value === value});
      return optionSelected?.label ?? `${value}`;
    }
    return `${value}`;
  }

  public onFormSubmit(){
    console.log('onFormSubmit');
    const { field, operator, compareValue } = this.filterForm.value;
    const display = new FilterDisplay(field.label,operator.label, this.getLabelFromOptions(compareValue))
    const filterData = this.getFilterDataByType(field.id, field.type, operator.type, compareValue);
    const appliedFilter: AppliedFilter = new AppliedFilter(display,filterData);
    this.dialog.close(appliedFilter);
  }

  getFilterDataByType(field: string, type: FieldType, operation: OperatorType, compareValue: CompareValueType): FilterData {
    if(type === 'date') return new DateFilterData(field, operation, compareValue, type)
    return new FilterData(field,operation,compareValue,type);  
  }
}
