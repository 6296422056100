<div class="edit-panel">
  <div class="header">
    <div class="title">{{ 'operators.editOperator' | translate }}</div>
    <div class="action-buttons">
      <ng-container *ngIf="!readOnly">
        <button mat-flat-button class="custom-add-button" (click)="onFormSubmit()" 
        [disabled]="registerForm.pristine || registerForm.invalid">{{ 'common.submit' | translate }}</button>
      </ng-container>
      <button class="close-button" (click)="formExit()">{{ 'editPanel.close' | translate }}</button>
    </div>
  </div>

  <!-- Formulario principal de Operador -->
  <form [formGroup]="registerForm">
    <fieldset class="edit-container bordered">
      <legend class="details-header">
        <label class="title-small">{{ 'operators.details' | translate }}</label>
      </legend>
      <div class="edit-content">
        
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'operators.name' | translate }}<span class="obligatorio"></span></mat-label>
                <input matInput formControlName="nombre" type="text" required />
                <mat-error *ngIf="registerForm.get('nombre')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'operators.cod' | translate }}<span class="obligatorio"></span></mat-label>
                <input matInput formControlName="cod" type="text" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</div>