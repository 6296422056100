import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-input-files',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatIconModule],
  templateUrl: './input-files.component.html',
  styleUrls: ['./input-files.component.css']
})
export class InputFilesComponent {
  @Output() onChanges = new EventEmitter<File[]>();
  @Input() allowedExtensions:string[] = [];
  @Input() invalid:boolean = false;
  @Input() multiple:boolean = false;

  constructor(
    private notifier: NotifierService,
    private translate: TranslateService
  ){}
 
  /**
   * @param event 
   * Este método obtiene los archivos desde el input y lo emite.
   */
  async onFilesSelected(event: any): Promise<void> {
    const input = event.target as HTMLInputElement;
    if (input.files) {
        const filesFilteredByExtension = Array.from(input.files).filter((item)=> this.validateExtensionFile(item));
        this.onChanges.emit(filesFilteredByExtension);
    }
  }
  
  /**
   * @param event 
   * Este método detecta desde la acción de arrastre y lo emite.
   */
  onFilesDropTerminals(event: any) {
    event.preventDefault();
    const dropEvent = event.dataTransfer;
    if (dropEvent?.files) { 
      const filesFilteredByExtension = Array.from(dropEvent.files as File[]).filter((item)=> this.validateExtensionFile(item));
      this.onChanges.emit(filesFilteredByExtension);
  }
  }

  onDragOver(event: any) {
    event.preventDefault();
  }

  onDragLeave(event: any) {
    event.preventDefault();
  }

  /**
   * @param file 
   * Este método verifica que la extensión del archivo sea válida, 
   * según tipos que se definan en 'allowedExtensions'.
   */
  validateExtensionFile(file: File): boolean {
    if(!this.allowedExtensions.length) return true;
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    const validExtension = fileExtension ? this.allowedExtensions.includes(fileExtension) : false;
    if (!validExtension) this.notifier.notify('warning', this.translate.instant('warning.invalidCSVFile'));
    return validExtension;
  }
}
