
import { VendingMachineAtento } from "./vendingMachineAtento.model";

export class Groups {
  id!: number ;
  idEntorno!: number | undefined;
  idOperator!: number | undefined ;
  name!: string ;
  cod!: string ;

  listVendingMachine!:VendingMachineAtento[] | undefined;

  constructor(values: Object = {}) {
    Object.assign(this, values);
}
}

