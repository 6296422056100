import { Component ,ElementRef, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Chart, registerables } from 'chart.js';
import { AuthService } from './auth/auth.service';
import { Observable } from 'rxjs';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmDialogActions, ConfirmDialogData } from './components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { environment } from 'src/environments/environment';
import { LanguageService } from './services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { RolHandlerService } from './services/rol-handler.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IconRegistryService } from './services/icon-registry.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'})
export class AppComponent implements OnInit {
  typeSelected: string;
  textShown: string | undefined;

  isAuthenticated$: Observable<boolean>;
  isDoneLoading$: Observable<boolean>;
  canActivateProtectedRoutes$: Observable<boolean>;
  
  title = 'MDM';//'admindashboard';
  
  constructor(
    private elementRef: ElementRef,  
    public  _router: Router,
    public dialog: MatDialog,
    private spinnerService: NgxSpinnerService,
    private idle: Idle, 
    private keepalive: Keepalive,
    private authService: AuthService,
    private readonly languageService: LanguageService,
    private readonly icons:IconRegistryService,
    private translate: TranslateService,
    private rol:RolHandlerService,
   ) {
    this.icons.load();
    this.languageService.init();

    this.typeSelected = 'ball-spin';
    this.translate.get('common.loading').subscribe((translation: string) => {
      this.textShown = translation;
    });
    
    this.isAuthenticated$ = this.authService.isAuthenticated$;
    this.isDoneLoading$ = this.authService.isDoneLoading$;
    this.canActivateProtectedRoutes$ = this.authService.canActivateProtectedRoutes$;

    
    Chart.register(...registerables); 

    //this.loadCSS("FISERV");
    
    this.configureIdleSessionTimeouts(idle, keepalive);
    
    let url = document.location.href;

    this.isAuthenticated$.subscribe((v)=>{
      // si el usuario está autenticado redirigimos al dashboard.
      if(v) {
        // configuramos el idle del portal
        //this.configureIdleSessionTimeouts(idle, keepalive);
        console.debug("redirect to monitoring, is authenticated");
        const pathToNavigate = this.rol.getPathByPermissions(); 
        this._router.navigateByUrl(pathToNavigate);
        this.loadCSS(this.authService.domain);
      } else if(!url.includes('signup')) {
        console.debug("redirect to welcome, not authenticated anymore");
        idle.stop();
        this._router.navigateByUrl("/welcome");
      }
    });

    console.debug('constructor end');
 }

 loadCSS(i:string) {
  let fileRef;
  fileRef = document.createElement('link');
  fileRef.setAttribute('rel', 'stylesheet');
  fileRef.setAttribute('type', 'text/css');

  if (typeof fileRef !== 'undefined') {
      document.getElementsByTagName('head')[0].appendChild(fileRef);
  }
}



 idleState = 'Not started.';
 timedOut = false;
 lastPing ?: Date = undefined;

 private configureIdleSessionTimeouts(idle: Idle, keepalive: Keepalive) {
      // sets an idle timeout of 5 seconds, for testing purposes.
      idle.setIdle(60);//60
      // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
      idle.setTimeout(15*60); // 15*60 - 15 minutos
      // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  
      idle.onIdleEnd.subscribe(() => { 
        this.idleState = 'No longer idle.'
        console.debug(this.idleState);
        this.reset();
      });
      
      idle.onTimeout.subscribe(() => {
        this.idleState = 'Timed out!';
        this.timedOut = true;

        
        let  emergente = document.getElementsByClassName('custom-modalbox') as HTMLCollectionOf<HTMLElement>;
        let  sombra = document.getElementsByClassName('cdk-overlay-backdrop') as HTMLCollectionOf<HTMLElement>;
           
        for (let i = 0; i < sombra.length; i ++) {
          sombra[i].style.display = 'none';
        }
        for (let i = 0; i < emergente.length; i ++) {
          emergente[i].style.display = 'none';
        }
        
        console.debug(this.idleState);
        this.fullLogout();
        
        this._router.navigate(['/welcome']);
        
        this.openSessionIdleDialog();
      });
      
      idle.onIdleStart.subscribe(() => {
          this.idleState = 'You\'ve gone idle!'
          console.debug(this.idleState);
      });
      
      idle.onTimeoutWarning.subscribe((countdown) => {
        this.idleState = 'You will time out in ' + countdown + ' seconds!'
        console.debug(this.idleState);
      });
      
      // sets the ping interval to 15 seconds
      keepalive.interval(15);
  
      keepalive.onPing.subscribe(() => this.lastPing = new Date());
  
      this.reset();
 }

 @ViewChild('logoutForm', { read: ElementRef })
 logoutForm!: ElementRef; 

 public fullLogout() { 
  console.debug("logout() method");
  this.authService.logoutAndClear();
  setTimeout(() => this.logoutForm.nativeElement.submit(), 10000);
 }
 
 private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
 
 openSessionIdleDialog(): void {
  
  const dialogData = new ConfirmDialogData();
  dialogData.enableCancel = false;

  dialogData.titleI18n = $localize `Session timeout`;
  dialogData.textI18n = $localize `You have being idle for too long, please login again`;
  dialogData.svgIcon = 'assets/img/confirm._icon.svg';

  this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
    width: '35%', panelClass: 'custom-modalbox',
    data: dialogData
  });

  this.confirmDialogRef.afterClosed().subscribe((result: ConfirmDialogActions) => {
    if (result === ConfirmDialogActions.CONFIRM) {
      console.debug("confirmed!");
      this.logoutForm.nativeElement.submit();
    }
  });
}

 private reset() {
  this.idle.watch();
  this.idleState = 'Started.';
  this.timedOut = false;
}
/*
  private isTokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return expiry * 1000 > Date.now();
  }
*/

  ngOnInit() {
    
    let s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "./assets/js/main.js";

    this.elementRef.nativeElement.appendChild(s);
  }

  
  public showSpinner(text: string, timed: number): void {
    this.textShown = text;
    this.spinnerService.show();

    if (!isNaN(timed))
    {
      setTimeout(() => {
        this.spinnerService.hide();
      }, timed); // 5 seconds
    }
  }

  public hideSpinner(): void {
    this.spinnerService.hide();
    this.textShown = '';
  }


  
  login() { 
    this.authService.login();
  } // https://auth.server:9000
  
  logout() { 
    this.authService.logout(); 
    this._router.navigate(["/welcome"]);
  }
  refresh() { this.authService.refresh(); }
  reload() { window.location.reload(); }
  clearStorage() { this.authService.clearStorage(); }

  logoutExternally() {
    this._router.navigateByUrl("/");
  }

  get hasValidToken() { return this.authService.hasValidToken(); }
  get accessToken() { return this.authService.accessToken; }
  get refreshToken() { return this.authService.refreshToken; }
  get identityClaims() { return this.authService.identityClaims; }
  get idToken() { return this.authService.idToken; }


  get logoutBaseUrl(){
    return 'redlogout';
  }
  
  get redirect(){
    return window.location.origin + '/welcome';
  }

}
