
export class ServerResponse {
  public buckets : any = [];
  public content : any = [];
  public totalElements: number = 0;
  public totalPages: number = 0;
  public token! : string;
  public errorDescription! : string;  
  public data! : any;
  public status: number = 0;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }


}