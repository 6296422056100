<div class="pagetitle">
    <h1>{{ 'newApp.title' | translate }}</h1>
</div>

<mat-stepper [linear]="isLinear" #stepper>

    <!-- Paso 1: Detalles -->
    <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
            <ng-template matStepLabel>{{ 'newApp.step1' | translate }}</ng-template>

            <div class="details-container">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'newApp.name' | translate }} <span class="obligatorio"></span></mat-label>
                        <input matInput formControlName="name" placeholder="{{ 'newApp.namePlaceholder' | translate }}">
                        <mat-error *ngIf="firstFormGroup.get('name')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                        <mat-error *ngIf="firstFormGroup.get('name')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
                    </mat-form-field>
                </div>

                <div class="form-group-inline">
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'newApp.packageName' | translate }} <span
                                    class="obligatorio"></span></mat-label>
                            <input matInput formControlName="packageName" placeholder="{{ 'newApp.packagePlaceholder' | translate }}">
                            <mat-error *ngIf="firstFormGroup.get('packageName')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                            <mat-error *ngIf="firstFormGroup.get('packageName')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-group-inline">
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'common.factory'| translate }}</mat-label>
                            <mat-select formControlName="manufacID">
                                <mat-option *ngFor="let op of factories?.list" [value]="op.name"> {{ op.name }} </mat-option>
                            </mat-select>
                            <mat-error *ngIf="firstFormGroup.get('manufacID')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'common.terminalModel'| translate }}</mat-label>
                            <mat-select formControlName="modelNumber">
                                <mat-option [value]="null"></mat-option>
                                <mat-option *ngFor="let op of terminalModels" [value]="op.name"> {{ op.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <!-- Hierarchy Form-->
                    <app-hierarchy-selector formControlName="hierarchy"></app-hierarchy-selector>
                </div>

            </div>
            <div class="form-group">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'newApp.description' | translate }} <span class="obligatorio"></span></mat-label>
                    <textarea matInput formControlName="description" rows="4" maxlength="200" placeholder="{{ 'newApp.descriptionPlaceholder' | translate }}"
                    cdkTextareaAutosize   cdkAutosizeMinRows="3" cdkAutosizeMaxRows="6"></textarea>
                    <mat-error *ngIf="firstFormGroup.get('description')?.hasError('required')"> {{ 'error.required.field' | translate }}</mat-error>
                    <mat-hint align="end">{{ firstFormGroup.get('description')?.value?.length || 0 }} / 200</mat-hint>
                </mat-form-field>
            </div>

            <!-- selector de etiquetas -->
            <div class="form-group">
                <app-tags-selector formControlName="tags" [hierarchy]="firstFormGroup.get('hierarchy')"></app-tags-selector>
            </div>

            <div class="button-group">
                <button mat-stroke-button class="custom-white-button" matStepperNext>{{ 'common.next' | translate }}</button>
            </div>
        </form>
    </mat-step>

    <!-- Paso 2: Archivos -->
    <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
            <ng-template matStepLabel>{{ 'newApp.step2' | translate }}</ng-template>
            <div class="terminals-container">
                <!-- Zona de carga de archivos -->
                <div class="file-upload-container mb-3">
                    <app-input-files class="w-100" [invalid]="fileUploadError && !selectedFile" (onChanges)="onFileSelected($event)"></app-input-files>
                </div>

                <!-- Nombre del archivo seleccionado y botón de eliminar -->
                <div *ngIf="selectedFile" class="mb-3">
                    <app-file-display [fileName]="selectedFile.name" (delete)="deleteFile()"></app-file-display>
                </div>

                <!-- Campo "Versión" -->
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'newApp.version' | translate }}</mat-label>
                        <input matInput formControlName="version" placeholder="{{ 'newApp.versionPlaceholder' | translate }}" />
                        <mat-error *ngIf="secondFormGroup.get('version')?.hasError('required')"> {{'error.required.field' | translate }}</mat-error>
                        <mat-error *ngIf="secondFormGroup.get('version')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <!-- Botones para navegación -->
            <div class="button-group">
                <button mat-stroke-button class="custom-white-button" matStepperPrevious>{{ 'common.back' | translate
                    }}</button>
                <button mat-flat-button class="custom-add-button" (click)="onFormSubmit()">{{ 'common.submit' |
                    translate }}</button>
            </div>
        </form>
    </mat-step>

</mat-stepper>