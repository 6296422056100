<form class="example-form">
    <mat-form-field class="w-100" appearance="outline" >
      <mat-label>{{ title }}</mat-label>
      <input type="text" matInput [formControl]="filterControl" [matAutocomplete]="auto">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="emitValue($event)" (closed)="onClosePanel()">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">{{option}}</mat-option>
      </mat-autocomplete>
      <mat-icon matSuffix svgIcon="chevron-down"></mat-icon>
      <mat-error *ngIf="filterControl?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
    </mat-form-field>
  </form>