
export class InfoDay {

    numVend: number=0;
    valor: number =0;
    valVend: number =0;

    listHour= new Map<number, InfoHour>();

}




export class InfoHour {

    numVend: number=0;
    valor: number =0;
    valVend: number =0;
        
}

