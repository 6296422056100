import { Operator } from "./operator.model";

export interface ContactData {
  direccion: string;	
	localidad: string;	
	codPostal: string;
	email: string;
	phone: string;
}

export interface BaseClientI extends ContactData{
	id: number;
	name: string;
	cod: string;
	idEntorno: number;
	idOperator: number;
	cif: string;	
	razonSocial: string;	
	dateDelete: Date;
	dominio: string;
	type: string;
	web: string;
	personContact: string;
	note: string;
}

export class BaseClient implements BaseClientI {
	id!: number;
	name!: string;
	cod!: string;
	idEntorno!: number;
	idOperator!: number;
	cif!: string;	
	razonSocial!: string;	
	dateDelete!: Date;
	dominio!: string;
	type!: string;
	web!: string;
	personContact!: string;
	note!: string;
	direccion!: string;	
	localidad!: string;	
	codPostal!: string;
	email!: string;
	phone!: string;
}

// Interface que se envía al back para crear 
export class Client extends BaseClient {}


// Interface que se devuelve /getListClientCompleta/
export class ClientCompleto extends Client {
	operator!:Operator;
}

//Interface que se utiliza para visualizar la tabla
export class ClientListItem extends Client {
	operatorName!:string;
}

// Interface que se envía al back para crear 
export class SubClient extends BaseClient {
	idClient!: number;
}

// Interface que se devuelve /getListSubClientCompleta/
export class SubClientCompleto extends SubClient {
	client!:ClientCompleto;
}

//Interface que se utiliza para visualizar la tabla
export class SubClientListItem extends SubClient {
	operatorName!:string;
	clientName!:string;
}
