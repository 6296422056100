<div class="edit-panel">
  <div class="header">
    <div class="title">{{ data.param.name }}</div>
    <div class="action-buttons">
      <ng-container *ngIf="!readOnly">
        <button mat-flat-button class="custom-add-button" (click)="onSave()" [disabled]="form.pristine || form.invalid">
          {{ 'common.save' | translate }}
        </button>
      </ng-container>
      <button class="close-button" (click)="onCancel()">×</button>
    </div>
  </div>

  <form [formGroup]="form">

    <!-- Details Section -->
    <fieldset class="edit-container bordered">
      <legend class="details-header" (click)="toggleSection('details')">
        <label class="title-small">{{ 'common.details' | translate }}</label>
        <mat-icon class="toggle-icon">{{ isOpen['details'] ? 'expand_less' : 'expand_more' }}</mat-icon>
      </legend>
      <div class="edit-content" *ngIf="isOpen['details']">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'parameters.name' | translate }}</mat-label>
            <input matInput formControlName="name" placeholder="{{ 'parameters.placeholder.name' | translate }}"
              required>
            <mat-error *ngIf="form.get('name')?.hasError('required')">
              {{ 'error.required.field' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'parameters.description' | translate }}</mat-label>
            <textarea matInput formControlName="description" rows="4" cdkTextareaAutosize   cdkAutosizeMinRows="3" cdkAutosizeMaxRows="6"
              placeholder="{{ 'parameters.placeholder.description' | translate }}" required></textarea>
            <mat-error *ngIf="form.get('description')?.hasError('required')">{{ 'error.required.field' | translate
              }}</mat-error>
            <mat-error *ngIf="form.get('description')?.hasError('maxlength')">{{ 'error.maxLength' | translate
              }}</mat-error>
          </mat-form-field>
        </div>

        <div class="form-group-inline">
          <div class="form-group w-50">
              <mat-form-field appearance="outline">
                  <mat-label>{{ 'parameters.version' | translate }} <span
                          class="obligatorio"></span></mat-label>
                  <input matInput formControlName="version"
                      placeholder="{{ 'parameters.placeholder.version' | translate }}" required>
                  <mat-error *ngIf="form.get('version')?.hasError('required')">
                      {{ 'error.required.field' | translate }}
                  </mat-error>
              </mat-form-field>
          </div>

          <!-- Campo de tipo de archivo-->
          <div class="form-group pt-3">
              <mat-checkbox class="checkbox-input" formControlName="storageType">{{ 'maintenace.storageType.publicDirectory' | translate }}</mat-checkbox>
          </div>
        </div>

        <!-- selector de etiquetas -->
        <div class="form-group">
          <app-tags-selector formControlName="tags" [hierarchy]="form.get('hierarchy')"></app-tags-selector>
        </div>
      </div>
    </fieldset>

    
    <!-- Hierarchy Section -->
    <fieldset class="edit-container bordered">
      <legend class="details-header" (click)="toggleSection('hierarchy')">
        <label class="title-small">{{ 'editPanel.hierarchy' | translate }}</label>
        <mat-icon class="toggle-icon">{{ isOpen['hierarchy'] ? 'expand_less' : 'expand_more' }}</mat-icon>
      </legend>
      
      <div class="edit-content row" *ngIf="isOpen['hierarchy']">
        <div class="form-group">
          <!-- Hierarchy Form-->
          <app-hierarchy-selector formControlName="hierarchy"></app-hierarchy-selector>
        </div>
      </div>
    </fieldset>

    <!-- Dates Section -->
    <fieldset class="edit-container bordered">
      <legend class="details-header" (click)="toggleSection('dates')">
        <label class="title-small">{{ 'parameters.date' | translate }}</label>
        <mat-icon class="toggle-icon">{{ isOpen['dates'] ? 'expand_less' : 'expand_more' }}</mat-icon>
      </legend>
      <div class="edit-content row" *ngIf="isOpen['dates']">
        <div class="form-group col col-lg-6">
          <label class="title-xs">{{ 'parameters.dateCreate' | translate }}</label>
          <mat-form-field appearance="outline">
            <mat-icon matPrefix>calendar_month</mat-icon>
            <input matInput formControlName="dateCreate" type="text"
              placeholder="{{ 'parameters.dateCreate' | translate }}"> </mat-form-field>
        </div>

        <div class="form-group col col-lg-6">
          <label class="title-xs">{{ 'parameters.dateUpdate' | translate }}</label>
          <mat-form-field appearance="outline">
            <mat-icon matPrefix>calendar_month</mat-icon>
            <input matInput formControlName="dateUpdate" type="text"
              placeholder="{{ 'parameters.dateUpdate' | translate }}"> </mat-form-field>
        </div>
      </div>
    </fieldset>

    <!-- File Upload Section -->
    <fieldset class="edit-container bordered">
      <legend class="details-header" (click)="toggleSection('fileUpload')">
        <label class="title-small">{{ 'parameters.file' | translate }}</label>
        <mat-icon class="toggle-icon">{{ isOpen['fileUpload'] ? 'expand_less' : 'expand_more' }}</mat-icon>
      </legend>
      <div class="edit-content" *ngIf="isOpen['fileUpload']">
        <div *ngIf="selectedFile || form.get('fileName')?.value" class="mb-3">
            <app-file-display [fileName]="form.get('fileName')?.value" 
              [canDelete]="!readOnly" (delete)="deleteFile()" 
              [canDownload]="true" (download)="downloadVersion()"></app-file-display>
        </div>
        <div class="file-upload-container mb-3" *ngIf="!selectedFile">
          <app-input-files class="w-100" [invalid]="fileUploadError && !selectedFile" (onChanges)="onFileSelected($event)"></app-input-files>
        </div>
      </div>
    </fieldset>

  </form>

  <div class="footer" *ngIf="!readOnly && rol.canByInput(rol.functIds.delete_parameters)">
    <button mat-stroke-button class="custom-white-button" (click)="onDelete()">
      {{ 'common.delete' | translate }}
      <img class="delete-icon" src="assets/img/delete_icon.svg" alt="Delete icon">

    </button>
  </div>
</div>