

export enum TIPE_CARD {
    TIPE_CHIP_OK="emv_ok",
    TIPE_CLESS_OK="cless_ok",
    TIPE_KEY_OK="manual_ok",
    TIPE_BAND_OK="stripe_ok",
    TIPE_CHIP_KO="emv_err",
    TIPE_CLESS_KO="cless_err",
    TIPE_KEY_KO="manual_err",
    TIPE_BAND_KO="stripe_err"
}

export enum COLOR_TIPE_CARD {
  TIPE_CHIP_OK= "rgb(0, 227, 150)",
  TIPE_CHIP_KO="rgb(255, 69, 96)",
  TIPE_BAND_OK="rgb(0, 143, 251)",
  TIPE_BAND_KO="rgb(254, 176, 25)",
  TIPE_CLESS_OK="rgb(102, 251, 0)",
  TIPE_CLESS_KO="rgb(255, 139, 69)",
  TIPE_KEY_OK="rgb(0, 27, 251)",
  TIPE_KEY_KO="rgb(252, 29, 29)",
}



export enum COMM_REPORT {
  COMM_OK= "primary_correct",
  COMM_KO="primary_error",
  SEC_COMM_OK="second_correct",
  SEC_COMM_KO="second_error",
}


export enum TICKET {
  PRINT="without",
  MAIL="by_sms",
  SMS="by_e-mail",
  NO="printed",
}

export enum CONSUME {
  TOTAL="total",
  READ="read",
  SENT="sent"
}