<mat-form-field appearance="outline" class="w-100">
    <mat-label>{{ 'common.tags' | translate }}</mat-label>
    <mat-select [formControl]="tagsInput" [compareWith]="compareTagById" multiple>
      <mat-select-trigger>
        {{ tagsInput.value?.[0]?.name || ''}}
        <span *ngIf="( tagsInput.value?.length || 0) > 1" class="example-additional-selection">
          (+{{(tagsInput.value?.length || 0) - 1}} {{ tagsInput.value?.length === 2 ? 'other' : 'others'}})
        </span>
      </mat-select-trigger>
      <mat-option *ngFor="let tag of tagsList" [value]="tag">{{ tag.name }}</mat-option>
    </mat-select>
  </mat-form-field>