import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'custosFecha'
})
export class FechaPersonalPipe implements PipeTransform {

  transform(value: string): unknown {
    if (!value) return null; // Manejo de valor nulo o indefinido

    // Convertir el valor en un formato Date
    const dateParts = value.split(/[- :]/); 
    if (dateParts.length < 6) return value; 

    // Crear el objeto Date
    const date = new Date(
      +dateParts[2], // Año
      +dateParts[1] - 1, // Mes (0-11)
      +dateParts[0], // Día
      +dateParts[3], // Hora
      +dateParts[4], // Minutos
      +dateParts[5]  // Segundos
    );

    // Comprobar si la fecha es válida
    if (isNaN(date.getTime())) {
      console.error(`Error converting date: ${value}`);
      return value; // Retorna el valor original si no se puede convertir
    }

    // Utilizar el DatePipe para el formato deseado
    const datePipe = new DatePipe("es-ES");
    const resultado = datePipe.transform(date, 'dd-MM-yyyy HH:mm:ss');

    return resultado;
  }
}
