import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NotifierService } from 'angular-notifier';
import { SelectMachineWindowComponent } from 'src/app/pages/comun/select-machine-window/select-machine-window.component';
import { Profile } from 'src/app/models/profiles.model';
import { PerfilesService } from '../perfiles.service';
import { AppsService } from '../../aplications/aplications.service';
import { Aplications } from 'src/app/models/aplications.model';
import { Parameters } from 'src/app/models/parameters.model';
import { ParamService } from '../../param/param.service';
import { ParamExtraFiles, PerfilApp } from 'src/app/models/perfilApp.model';
import { EditAppsComponent } from '../edit-apps/edit-apps.component';
import { EditMachinesComponent } from '../edit-machines/edit-machines.component';
import { ListRelatedMachinesComponent } from '../list-related-machines/list-related-machines.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { RolHandlerService } from 'src/app/services/rol-handler.service';

@Component({
  selector: 'app-edit-perfiles',
  templateUrl: './edit-perfiles.component.html',
  styleUrls: ['./edit-perfiles.component.css']
})
export class EditPerfilesComponent implements OnInit {
  isAdmin: boolean = true;
  isLinear = true;
  selectedFile: File | null = null;
  resultListApp: PerfilApp[] = [];
  showDropZone = false;
  dialogRef!: MatDialogRef<SelectMachineWindowComponent>;
  profile: Profile = new Profile;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;


  @Output() closePanel = new EventEmitter<void>();
  uploadProgress = 0;
  isDetailsOpen = true;
  isDateFieldsOpen = true;
  

  // Form groups
  form!: FormGroup;
  appsFormGroup!: FormGroup;

  isOpen: { [key: string]: boolean } = {
    details: true,
    hierarchy:true,
    dateFields: true,
    terminals: true,
    apps: true,
    distance: true,
  };

  // Variables for apps and parameters
  availableApps: Aplications[] = [];
  parameters: Parameters[] = [];
  hasEditedRelateds:boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number },
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    public dialog: MatDialog,
    private translate: TranslateService,
    public dialogNewProfile: MatDialogRef<EditPerfilesComponent>,
    private perfilesService: PerfilesService,
    private appsService: AppsService,
    private paramService: ParamService,
    private profileService: PerfilesService,
    public rol: RolHandlerService
  ) { 
    // Intercept cases of modal closure, and send if the profile has changed.
    this.dialogNewProfile.beforeClosed().subscribe((value)=>{
      if(value === undefined) {
        const dialogResponse = this.hasEditedRelateds ? ConfirmDialogActions.CONFIRM : ConfirmDialogActions.CANCEL;
        this.dialogNewProfile.close(dialogResponse); 
      } 
    });
  }

  async ngOnInit(): Promise<void> {
    this.form = this.formBuilder.group({
      name: new FormControl("", Validators.required),
      description: new FormControl("", Validators.compose([Validators.required, Validators.maxLength(200)])),
      hierarchy:new FormControl({ value: { idOperator:null, idClient:null, idSubClient:null }, disabled:true }),
      dateCreate: new FormControl({ value: "", disabled: true }),
      dateUpdate: new FormControl({ value: "", disabled: true }),
      enableLocation: new FormControl(true),
      distanceLocation: new FormControl(0, Validators.compose([Validators.required, Validators.max(1000)])),
      distanceUnit: new FormControl("km",Validators.required )
    });

    this.form.get('enableLocation')?.valueChanges.subscribe((value)=>{
      if(!value){
        this.form.get('distanceLocation')?.disable();
        this.form.get('distanceUnit')?.disable();
      } else {
        this.form.get('distanceLocation')?.enable();
        this.form.get('distanceUnit')?.enable();
      }
    });

    this.loadAvailableApps();
    this.loadParameters();
    this.loadProfile();
  }

  private async loadProfile() {
    const response = await this.profileService.get(this.data.id);
    if (response && response.data) {
      this.profile = response.data as Profile;
      this.form.get("name")?.setValue(this.profile.name);
      this.form.get("description")?.setValue(this.profile.description);
      this.form.get("dateCreate")?.setValue(this.profile.dateCreate);
      this.form.get("dateUpdate")?.setValue(this.profile.dateUpdate);
      this.form.get("enableLocation")?.setValue(this.profile.enableLocation ?? true);
      this.form.get("distanceLocation")?.setValue(this.profile.distanceLocation ?? 0);
      this.form.get("distanceUnit")?.setValue(this.profile.distanceUnit ?? "m");
      this.form.get('hierarchy')?.setValue({ idOperator:this.profile.idOperator, idClient:this.profile.idClient, idSubClient:this.profile.idSubClient })
      
      this.profile.listApp = this.profile.listApp?.map((item:PerfilApp)=>{
        const perfilApp = new PerfilApp;
        perfilApp.mapFromServer(item);
        return perfilApp;
      })
    } else {
      this.notifier.notify('error', 'Error al obtener perfil');
      this.onCancel();
    }
  }

  private async loadAvailableApps(): Promise<void> {
    const result = await this.appsService.find({} as any);
    if (result?.status === 0) {
      this.availableApps = result.data;
    }
  }

  private async loadParameters(): Promise<void> {
    const result = await this.paramService.find({} as any);
    if (result?.status === 0) {
      this.parameters = result.data;
    }
  }

  openEditTerminalsScreen() {
    const dialogEditProfile: MatDialogRef<ListRelatedMachinesComponent> = this.dialog.open(ListRelatedMachinesComponent, {
      width: '700px',
      panelClass: 'custom-modalbox-big',
      data: { profile: this.profile }
    });

    dialogEditProfile.afterClosed().subscribe((response) => {
      if (response?.result === 1) {
        this.hasEditedRelateds = true;
        this.loadProfile();
      }
    });
  }

  async openAddTerminalsScreen() {
    const dialogEditProfile: MatDialogRef<EditMachinesComponent> = this.dialog.open(EditMachinesComponent, {
      width: '700px',
      panelClass: 'custom-modalbox-big',
      data: { profile: this.profile }
    });

    dialogEditProfile.afterClosed().subscribe((response) => {
      if (response?.result === 1) {
        this.hasEditedRelateds = true;
        this.loadProfile();
      }
    });
  }

  async updateRelatedAppsScreen(perfilApp?:PerfilApp) {
    const dialogEditProfile: MatDialogRef<EditAppsComponent> = this.dialog.open(EditAppsComponent, {
      width: '700px',
      panelClass: 'custom-modalbox-big',
      data: { profile: this.profile, selected: perfilApp ?? null }
    });

    dialogEditProfile.afterClosed().subscribe((response) => {
      if (response?.result === 1) {
        this.hasEditedRelateds = true;
        this.loadProfile();
      }
    });
  }

  async deleteApp(appIndex: number) {
    const listApp = this.profile.listApp ?? [];
    listApp.splice(appIndex, 1);
    this.profile.listApp = [...listApp];

    const profile = { ...this.profile };
    const response = await this.profileService.saveApps(profile);
    if (response?.status && response?.status >= 0) {
      this.notifier.notify('success', this.translate.instant('confirmDialog.profileUpdated', { name: this.profile.name }));
    }
  }

  toggleDropZone(): void {
    this.showDropZone = !this.showDropZone;
  }

  toggleSection(section: string): void {
    this.isOpen[section] = !this.isOpen[section];
  }

  onSave(): void {
    if (!this.profile) return;

    // Validación del formulario
    if (!this.form.valid) {
      this.notifier.notify('warning', this.translate.instant('confirmDialog.completeFields')); // "Please complete all required fields"
      return;
    }

    const formData = this.form.value;
    const updatedApp = new Profile;
    updatedApp.id = this.profile.id;
    updatedApp.name = formData.name;
    updatedApp.description = formData.description;
    updatedApp.enableLocation = formData.enableLocation;
    updatedApp.distanceLocation = formData.distanceLocation;
    updatedApp.distanceUnit = formData.distanceUnit
    updatedApp.cod = this.profile.cod;

    updatedApp.idOperator = this.profile.idOperator;
    updatedApp.idClient = this.profile.idClient;
    updatedApp.idSubClient = this.profile.idSubClient;


    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = this.translate.instant('confirmDialog.updateProfileTitle'); // "Update Profile"
    dialogData.textI18n = this.translate.instant('confirmDialog.updateProfileText'); // "Are you sure you want to update?"
    dialogData.svgIcon = 'assets/img/confirm._icon.svg';

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%',
      panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        try {
          await this.perfilesService.updateProfile(updatedApp);
          this.dialogNewProfile.close(1);
          this.notifier.notify('success', this.translate.instant('confirmDialog.profileUpdated', { name: this.profile.name }));

        } catch (error) {
          const errorMessage = (error as Error).message || 'Unknown error';
          this.notifier.notify('error', `${this.translate.instant('confirmDialog.updateProfile')} ${errorMessage}`); // "Error updating: "
        }
      }
    });
  }

  onDelete(): void {
    if (!this.profile) return;

    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = this.translate.instant('confirmDialog.deleteProfileTitle'); // "Delete Profile"
    dialogData.textI18n = this.translate.instant('confirmDialog.deleteProfileText'); // "Are you sure you want to delete?" 
    dialogData.svgIcon = 'assets/img/delete_icon_dialog.svg'; 
    dialogData.isDeleteAction = true; 

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%',
      panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        try {
          await this.profileService.delete(this.profile.id, this.profile.name);
          this.dialogNewProfile.close(1);
          this.notifier.notify('success', this.translate.instant('confirmDialog.profileDeleted')); // "Deleted successfully"
        } catch (error) {
          const errorMessage = (error as Error).message || 'Unknown error';
          this.notifier.notify('error', `${this.translate.instant('confirmDialog.deleteProfile')} ${errorMessage}`); // "Error deleting: "        }
        }
      }
    });
  }

  onCancel(): void {
    this.dialogNewProfile.close();
  }


}
