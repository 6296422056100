<div class="edit-panel">
  <div class="header">
    <div class="title">{{ app.name }}</div>
    <div class="action-buttons">
      <ng-container *ngIf="!readOnly">
        <button *ngIf="rol.canByInput(rol.functIds.edit_applications)" mat-flat-button class="custom-add-button"
          (click)="onSave()" [disabled]="firstFormGroup.pristine || firstFormGroup.invalid">{{ 'editPanel.save' |
          translate }}</button>
      </ng-container>
      <button class="close-button" (click)="onCancel()">{{ 'editPanel.close' | translate }}</button>
    </div>
  </div>

  <!-- Formulario principal -->
  <form [formGroup]="firstFormGroup">
    <!-- Details Section -->
    <fieldset class="edit-container bordered">
      <legend class="details-header" (click)="toggleSection('details')">
        <label class="title-small">{{ 'editPanel.details' | translate }}</label>
        <mat-icon class="toggle-icon">{{ isOpen['details'] ? 'expand_less' : 'expand_more' }}</mat-icon>
      </legend>
      <div class="edit-content" *ngIf="isOpen['details']">
        <div class="form-group">
          <label class="title-xs">{{ 'editPanel.name' | translate }}</label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="name" placeholder="{{ 'editPanel.namePlaceholder' | translate }}" />
            <mat-error *ngIf="firstFormGroup.get('name')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
            <mat-error *ngIf="firstFormGroup.get('name')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <label class="title-xs">{{ 'editPanel.packageName' | translate }}</label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="packageName" placeholder="{{ 'editPanel.packagePlaceholder' | translate }}" />
            <mat-error *ngIf="firstFormGroup.get('packageName')?.hasError('required')">{{ 'error.required.field' |
              translate }}</mat-error>
          </mat-form-field>
        </div>

        <!-- selector de etiquetas -->
        <div class="form-group">
          <app-tags-selector formControlName="tags" [hierarchy]="firstFormGroup.get('hierarchy')"></app-tags-selector>
        </div>
      </div>
    </fieldset>


    <!-- Hierarchy Section -->
    <fieldset class="edit-container bordered">
      <legend class="details-header" (click)="toggleSection('hierarchy')">
        <label class="title-small">{{ 'editPanel.hierarchy' | translate }}</label>
        <mat-icon class="toggle-icon">{{ isOpen['hierarchy'] ? 'expand_less' : 'expand_more' }}</mat-icon>
      </legend>
      
      <div class="edit-content row" *ngIf="isOpen['hierarchy']">
        <div class="form-group">
          <!-- Hierarchy Form-->
          <app-hierarchy-selector formControlName="hierarchy"></app-hierarchy-selector>
        </div>
      </div>
    </fieldset>

    <!-- Dates Section -->
    <fieldset class="edit-container bordered">
      <legend class="details-header" (click)="toggleSection('dates')">
        <label class="title-small">{{ 'editPanel.registrationDate' | translate }}</label>
        <mat-icon class="toggle-icon">{{ isOpen['dates'] ? 'expand_less' : 'expand_more' }}</mat-icon>
      </legend>
      <div class="edit-content row" *ngIf="isOpen['dates']">
        <div class="form-group col col-lg-6">
          <label class="title-xs">{{ 'editPanel.registrationDate' | translate }}</label>
          <mat-form-field appearance="outline">
            <mat-icon matPrefix>calendar_month</mat-icon>
            <input matInput formControlName="dateCreate" type="text"
              placeholder="{{ 'editPanel.registrationDate' | translate }}" />
          </mat-form-field>
        </div>

        <div class="form-group col col-lg-6">
          <label class="title-xs">{{ 'editPanel.modificationDate' | translate }}</label>
          <mat-form-field appearance="outline">
            <mat-icon matPrefix>calendar_month</mat-icon>
            <input matInput formControlName="dateUpdate" type="text"
              placeholder="{{ 'editPanel.modificationDate' | translate }}" />
          </mat-form-field>
        </div>
      </div>
    </fieldset>
  </form>


    <!-- Versiones Section -->
    <fieldset class="edit-container bordered">
      <legend class="details-header" (click)="toggleSection('versiones')">
        <div class="d-flex">
          <label class="title-small">{{ 'editPanel.versions' | translate }}</label>
          <!-- Botón para agregar nueva versión -->
          <div *ngIf="!readOnly">
            <button mat-button (click)="startNewVersion($event)">{{ 'editPanel.addNewVersion' | translate }}</button>
          </div>  
        </div>
        <mat-icon class="toggle-icon">{{ isOpen['versiones'] ? 'expand_less' : 'expand_more' }}</mat-icon>
      </legend>

      <div class="edit-content" *ngIf="isOpen['versiones']">
        <!-- Lista de versiones con scroll -->
        <div class="versions-list">
          <ul>
            <li *ngFor="let version of versions">
              <span [matTooltip]="version.version"><strong>{{ version.version }}</strong></span>
              <span [matTooltip]="version.manufacID"><strong>{{ version.manufacID }}</strong></span>
              <span [matTooltip]="version.modelNumber ?? ''"><strong>{{ version.modelNumber ?? ('common.all' | translate) }}</strong></span>
              <span [matTooltip]="version.dateCreate.toString() | dateOnTimeZone:true">{{ version.dateCreate.toString() | dateOnTimeZone:true }}</span>
              <span [matTooltip]="version.description" class="limit-description">{{ version.description || ('common.noDescription' | translate) }}</span>
              
              <div class="versions-list-actions">
                <button mat-icon-button (click)="downloadVersion(version)">
                  <mat-icon svgIcon="download-01"></mat-icon>
                </button>
              
                <ng-container *ngIf="rol.canByInput(rol.functIds.delete_applications) && !readOnly">
                  <button mat-icon-button class="delete-icon-button" (click)="removeVersion(version.id)">
                    <mat-icon svgIcon="trash-01"></mat-icon>
                  </button>
                </ng-container>
              </div>

            </li>
          </ul>
        </div>

        <!-- Zona de carga de archivos -->
        <div *ngIf="isAddingVersion">
          <!-- Segundo Formulario para Archivos y Versiones -->
          <form [formGroup]="secondFormGroup">

          <div class="file-upload-container">
            <!-- Zona de carga de archivos -->
            <div class="mb-3">
              <ng-container *ngIf="!selectedFile; else hasFile">
                <app-input-files class="w-100" (onChanges)="onFileSelected($event)"></app-input-files>
              </ng-container>
            
              <ng-template #hasFile>
                <app-file-display [fileName]="selectedFile?.name" (delete)="resetFileSelection()"></app-file-display>
              </ng-template>
            </div>
            
            <!-- Input para la nueva versión -->
            <div class="form-group">
              <label class="title-xs">{{ 'editPanel.version' | translate }}</label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="version" placeholder="{{ 'editPanel.version' | translate }}" />
                <mat-error *ngIf="secondFormGroup.get('version')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                <mat-error *ngIf="secondFormGroup.get('version')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                  <label class="title-xs">{{ 'common.factory'| translate }}</label>
                  <mat-form-field appearance="outline">
                      <mat-select formControlName="manufacID">
                          <mat-option *ngFor="let op of factories?.list" [value]="op.name"> {{ op.name }} </mat-option>
                      </mat-select>
                      <mat-error *ngIf="secondFormGroup.get('manufacID')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                  </mat-form-field>
              </div>
              <div class="col-12 col-md-6">
                  <label class="title-xs">{{ 'common.terminalModel'| translate }}</label>
                  <mat-form-field appearance="outline">
                      <mat-select formControlName="modelNumber">
                          <mat-option [value]="null"></mat-option>
                          <mat-option *ngFor="let op of terminalModels" [value]="op.name"> {{ op.name }} </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
          </div>
            
            <div class="form-group">
              <label class="title-xs">{{ 'editPanel.description' | translate }}</label>
              <mat-form-field appearance="outline">
                <textarea matInput formControlName="description"
                  placeholder="{{ 'editPanel.description' | translate }}" maxlength="200" rows="3" cdkTextareaAutosize
                  cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5">
                </textarea>
                <mat-error *ngIf="secondFormGroup.get('description')?.hasError('required')">
                  {{ 'error.required.field' | translate }}
                </mat-error>
                <mat-hint align="end">{{ secondFormGroup.get('description')?.value?.length || 0 }} / 200</mat-hint>
              </mat-form-field>
            </div>

            <div class="add-versions-actions">
              <button mat-button (click)="onNewVersion()">{{ 'editPanel.confirmNewVersion' | translate }}</button>
              <button mat-button (click)="cancelNewVersion()">{{ 'common.cancel' | translate }}</button>
            </div>
          </div>
          </form>
        </div>
      </div>
    </fieldset>

  <div class="footer" *ngIf="!readOnly">
    <button *ngIf="rol.canByInput(rol.functIds.delete_applications)" mat-stroke-button class="custom-white-button"
      (click)="onDelete()">
      {{ 'editPanel.deleteVersion' | translate }}
      <img class="delete-icon" src="assets/img/delete_icon.svg" alt="Delete icon">
    </button>
  </div>
</div>