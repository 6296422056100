export type CompareValueType = string | number | Date | boolean;

export type FieldType = 'string' | 'number' | 'date' | 'boolean' | 'long';

export type InputType = 'text' | 'number' | 'date' | 'boolean' | 'select';

export class FilterOption {
    label:string;
    value:CompareValueType;
    constructor(_label:string, _value:CompareValueType){
        this.label=_label;
        this.value=_value;
    }
}

export class FilterField {
    id:string;
    label:string;
    type: FieldType;
    options?:FilterOption[];

    constructor(_id:string,_label:string,_type:FieldType,_options?:FilterOption[]){
        this.id=_id;
        this.label=_label;
        this.type=_type;
        this.options=_options;
    }

    public get inputType(): InputType {
        if(this.options?.length) return "select";
        if(this.type === 'string') return "text";
        if(this.type === 'long') return 'number';
        return this.type as InputType;
    }
}

export type OperatorType = 'EQUALS' | 'DISTINCT' | 'LIKE' | 'NOTLIKE' | 'GREATER_THAN' | 'LESS_THAN' | 'GREATER_THAN_OR_EQUAL' | 'LESS_THAN_OR_EQUAL' | 'NULL' | 'NOTNULL' | 'IN' | 'NOTIN' | 'STARTSWITH' | 'ENDSWITH,BETWEEN,EXISTS' | 'ISTRUE' | 'ISFALSE';
		

export interface FilterOperator {
    type:OperatorType;
    label:string;
    showOnType:InputType[];
}

export const OPERATORS:FilterOperator[] = [
    {
        type:'LIKE',
        label:'operator.contains',
        showOnType:['text','boolean']
    },
    {
        type:'NOTLIKE',
        label:'operator.notContains',
        showOnType:['text','boolean']
    },
    {
        type:'EQUALS',
        label:'operator.equals',
        showOnType:['number','date', 'select']
    },
    {
        type:'LESS_THAN_OR_EQUAL',
        label:'operator.max',
        showOnType:['number','date']
    },
    {
        type:'GREATER_THAN_OR_EQUAL',
        label:'operator.min',
        showOnType:['number','date']
    }
]

export class FilterFormData {
    fields: FilterField[];
    operation:FilterOperator[];
    
    constructor(_fields:FilterField[], _operators:FilterOperator[]){
        this.fields = _fields;
        this.operation = _operators;
    }
}

export class AppliedFilter {
    display:FilterDisplay;
    filter:FilterData;

    constructor(_display:FilterDisplay, _filter:FilterData){
        this.display = _display;
        this.filter = _filter;
    }
}

export class FilterDisplay {
    fieldLabel:string;
    operationLabel:string;
    valueLabel:string;

    constructor(_field:string, _operation:string, _value:string){
        this.fieldLabel = _field;
        this.operationLabel = _operation;
        this.valueLabel = _value
    }
}

export class FilterData {
    column:string;
    operation:OperatorType;
    value:CompareValueType;
    type:FieldType;

    constructor(_column:string, _operation:OperatorType, _value:CompareValueType, _type:FieldType){
        this.column = _column;
        this.operation = _operation;
        this.type = _type;
        this.value = this.formatValue(_value);
    }

    protected formatValue(value:CompareValueType){
        return value;
    }
}

export class DateFilterData extends FilterData {
    override formatValue(value:CompareValueType){
        if(this.operation === 'LESS_THAN_OR_EQUAL') return new Date(value as string).setHours(23,59,59);
        return new Date(value as string).getTime();
    }
}
