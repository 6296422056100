<main id="main" class="main">
    <div class="pagetitle">
        <h1> {{ 'maintenace.tabs.sendFile' | translate }}</h1>
    </div>

    <section class="row w-100">
        <ng-container  *ngIf="!hasDefaultVendingMachine">
            <article class="col-lg-6 px-3 form-container">
                <app-select-machine-detail [dataSource]="selectedVendingMachines"></app-select-machine-detail>
            </article>
        </ng-container>
        
        <article class="col-lg-6 px-3 form-container">
            <form [formGroup]="fileForm">
                <div class="row mb-2">
                    <app-input-files [multiple]="true" (onChanges)="onFilesSelected($event)"></app-input-files>
                </div>

                <div class="row my-2">
                    <div class="d-flex flex-column gap-2">
                        <ng-container *ngFor="let file of fileList; index as idx">
                            <app-file-display [fileName]="file.name" (delete)="removeFile(idx)"></app-file-display>
                        </ng-container>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <mat-form-field appearance="outline" ngDefaultControl>
                                <mat-label>{{ 'maintenace.input.startTime' | translate }}</mat-label>
                                <input type="datetime-local" matInput formControlName="initTime">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <mat-form-field appearance="outline" ngDefaultControl class="misColores">
                                <mat-label>{{ 'maintenace.input.endTime' | translate }}</mat-label>
                                <input  type="datetime-local" matInput formControlName="endTime">
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <!-- Tipo de Archivo -->
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label> {{ 'common.fileType' | translate }}</mat-label>
                        <mat-select formControlName="typeFile" required>
                            <mat-option *ngFor="let type of sendFileTypes" [value]="type.value">{{ type.label| translate }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="fileForm.get('typeFile')?.hasError('required')">
                            {{ 'error.required.field' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- Tipo de directorio -->
                <div class="form-group" *ngIf="fileForm.get('storageType')?.enabled">
                    <mat-form-field appearance="outline">
                        <mat-label> {{ 'common.directoryType' | translate }}</mat-label>
                        <mat-select formControlName="storageType" required>
                            <mat-option *ngFor="let type of storageTypes" [value]="type">{{ 'maintenace.storageType.'+type | translate }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="fileForm.get('storageType')?.hasError('required')">
                            {{ 'error.required.field' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- Nombre del paquete -->
                <div class="form-group" *ngIf="fileForm.get('packageName')?.enabled">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'maintenace.input.packageName' | translate }}<span
                                class="obligatorio"></span></mat-label>
                        <input matInput formControlName="packageName"
                            placeholder="{{ 'maintenace.placeholder.packageName' | translate }}" required>
                        <mat-error *ngIf="fileForm.get('packageName')?.hasError('required')">
                            {{ 'error.required.field' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                
                <!-- Ruta -->
                <div class="form-group" *ngIf="fileForm.get('path')?.enabled">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'maintenace.input.path' | translate }} <span
                                class="obligatorio"></span></mat-label>
                        <input matInput formControlName="path"
                            placeholder="{{ 'maintenace.placeholder.path' | translate }}" required>
                        <mat-icon matSuffix matTooltip="{{ 'maintenace.tooltip.path' | translate }}" class="custom-icon-color">info</mat-icon>
                        <mat-error *ngIf="fileForm.get('path')?.hasError('required')">
                            {{ 'error.required.field' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="botonera">
                    <button mat-flat-button class="custom-add-button" (click)="onFormSubmit()">
                        {{ 'common.submit' | translate }}
                    </button>
                </div>
            </form>
        </article>
    </section>
</main>