<div class="pagetitle">
    <h1>{{ 'profiles.new' | translate }}</h1>
</div>

<mat-stepper [linear]="isLinear" #stepper>

    <!-- Details Step -->
    <mat-step [stepControl]="detailsFormGroup">
        <form [formGroup]="detailsFormGroup">
            <div class="pagetitle">
                <h2>{{ 'profiles.details' | translate }}</h2>
            </div>
            <ng-template matStepLabel>{{ 'profiles.details' | translate }}</ng-template>
            <div class="details-container">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'profiles.name' | translate }} <span class="obligatorio"></span></mat-label>
                        <input matInput formControlName="name" placeholder="{{ 'profiles.placeholder.name' | translate }}" required>
                        <mat-error *ngIf="detailsFormGroup.get('name')?.hasError('required')">
                            {{ 'error.required.field' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'profiles.description' | translate }}<span class="obligatorio"></span></mat-label>
                        <textarea matInput formControlName="description" rows="4" cdkTextareaAutosize   cdkAutosizeMinRows="3" cdkAutosizeMaxRows="6"
                        placeholder="{{ 'profiles.placeholder.description' | translate }}"
                            required></textarea>
                        <mat-error *ngIf="detailsFormGroup.get('description')?.hasError('required')">
                            {{ 'error.required.field' | translate }}
                        </mat-error>
                        <mat-error *ngIf="detailsFormGroup.get('description')?.hasError('maxlength')">
                            {{ 'error.maxLength' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            
      
                <div class="form-group">
                    <!-- Hierarchy Form (only in this step) -->
                    <app-hierarchy-selector formControlName="hierarchy"></app-hierarchy-selector>
                </div>
            </div>

            <div class="button-group">
                <button mat-stroke-button class="custom-white-button" matStepperNext [disabled]="detailsFormGroup.invalid">{{ 'common.next' | translate }}</button>
            </div>
        </form>
    </mat-step>

    <!-- Terminals Step -->
    <mat-step>
        <form>
            <div class="pagetitle">
                <h2>{{ 'profiles.terminals' | translate }}</h2>
            </div>
            
            <ng-template matStepLabel>{{ 'profiles.terminals' | translate }}</ng-template>

            <div class="terminals-container">
                <app-terminals-selector [(machines)]="machines" [hierarchy]="hierarchyInput"></app-terminals-selector>
            </div>

            <div class="button-group">
                <button mat-stroke-button class="custom-white-button" matStepperPrevious>
                    {{ 'common.back' | translate }}
                </button>
                <button mat-stroke-button class="custom-white-button" matStepperNext>
                    {{ 'common.next' | translate }}
                </button>
            </div>
        </form>
    </mat-step>

    <!-- Apps Step -->
    <mat-step [stepControl]="appsFormGroup">
        <form [formGroup]="appsFormGroup">
            <div class="pagetitle">
                <h2>{{ 'profiles.aplications' | translate }}</h2>
            </div>
            <ng-template matStepLabel>{{ 'profiles.aplications' | translate }}</ng-template>

            <div class="apps-container">
                <!-- Select for Apps and Versions -->
                  <div class="app-version-row">
                    <mat-form-field appearance="outline" class="select-app-field">
                        <mat-label>{{ 'profiles.selectApps' | translate }}</mat-label>
                        <mat-select (selectionChange)="onAppSelect($event)" formControlName="selectedApp">
                            <mat-option *ngFor="let app of appsBBDD" [value]="app">
                                {{ app.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    
                    
                    <mat-form-field appearance="outline" class="select-version-field">
                        <mat-label>{{ 'profiles.selectVersions' | translate }}</mat-label>
                        <mat-select (selectionChange)="onVersionSelect($event)" formControlName="selectedVersion">
                            <mat-option *ngFor="let app of versionBBDD" [value]="app">
                                {{ app.version }} ({{ app.manufacID + ' - ' +  (app.modelNumber || ('common.all' | translate))}})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Parameters and File Upload Area -->
                <div class="parameter-file-column">
                    <ng-container  *ngFor="let selectedApp of resultListApp; let i = index">
                        <app-new-perfil-app class="w-100" [data]="selectedApp" (remove)="removeApp($event)" [parameters]="parameters"></app-new-perfil-app>
                    </ng-container>
                </div>
            </div>

            <div class="button-group">
                <button mat-stroke-button class="custom-white-button" matStepperPrevious>
                    {{ 'common.back' | translate }}
                </button>
                <button mat-stroke-button class="custom-white-button" matStepperNext>
                    {{ 'common.next' | translate }}
                </button>
            </div>
        </form>
    </mat-step>

    <!-- Distance Step -->
    <mat-step [stepControl]="distanceFormGroup">
        <form [formGroup]="distanceFormGroup">
            <div class="pagetitle">
                <h2>{{ 'profiles.distance' | translate }}</h2>
            </div>
            <ng-template matStepLabel>{{ 'profiles.distance' | translate }}</ng-template>
            <div class="distance-container">
                <!-- Slide toggle on top -->
                <mat-slide-toggle formControlName="enableLocation" class="toggle-selector">
                    {{ 'profiles.distanceLocation' | translate }}
                </mat-slide-toggle>
                <!-- Flex container for Distance and Units -->
                
                <div class="d-flex my-3">
                    <!-- Distance -->
                    <div class="w-50 mx-2">
                        <label class="title-xs">{{ 'profiles.distance' | translate }}</label>
                        <mat-form-field appearance="outline">
                            <input matInput formControlName="distanceLocation" type="number"  placeholder="{{ 'profiles.placeholder.distance' | translate }}">
                            <mat-error *ngIf="distanceFormGroup.get('distanceLocation')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                            <mat-error *ngIf="distanceFormGroup.get('distanceLocation')?.hasError('max')">{{ 'error.maxLength' | translate }}</mat-error>
                        </mat-form-field>
                    </div>
        
                    <!-- Distance Unit -->
                    <div class="w-50 mx-2">
                        <label class="title-xs">{{ 'profiles.units' | translate }}</label>
                        <mat-form-field appearance="outline">
                            <mat-select formControlName="distanceUnit">
                                <mat-option value="km">{{'common.kilometers' | translate }}</mat-option>
                                <mat-option value="m">{{'common.meters' | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="button-group">
                <button mat-stroke-button class="custom-white-button" matStepperPrevious>
                    {{ 'common.back' | translate }}
                </button>
                <button mat-flat-button class="custom-add-button" (click)="onFinalSubmit()" [disabled]="distanceFormGroup.invalid">
                    {{ 'common.submit' | translate }}
                </button>
            </div>
        </form>
    </mat-step>

    <!-- Icon overrides -->
    <ng-template matStepperIcon="number">
        <mat-icon>radio_button_unchecked</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="checked">
        <mat-icon>check_circle</mat-icon>
    </ng-template>

</mat-stepper>