import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { TranslateModule } from "@ngx-translate/core";

@Component({
    standalone:true,
    imports:[
      CommonModule,
      MatIconModule,
      MatLegacyButtonModule,
      TranslateModule
    ],
    selector: 'app-file-display',
    templateUrl:'./file-display.component.html',
    styleUrls: ['./file-display.component.css']
  })
  export class FileDisplayComponent{
    @Input() fileName?:string = '';
    @Input() index?:number | null = null;
    @Input() canDownload:boolean = false;
    @Input() canDelete:boolean = true;
    @Input() invalid:boolean = false;
    
    @Output() delete = new EventEmitter();
    @Output() download = new EventEmitter();

    deleteFile(){
        this.delete.emit(this.index);
    }

    downloadFile(){
      this.download.emit(this.index);
    }
}