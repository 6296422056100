export class ConfirmDialogData {
  titleI18n: string | undefined;
  textI18n: string | undefined;
  textValue: string | undefined;
  enableCancel: boolean = true;
  visibleButtom: boolean = true;
  svgIcon: string | undefined;
  isDeleteAction?: boolean;
  icon?:string;
  class:string = '';
}

export enum ConfirmDialogActions {
  CONFIRM = 1,
  CANCEL = 0
}

