import { Aplications } from "./aplications.model";
import { VendingMachineAtento } from "./vendingMachineAtento.model";

export class FirmwareUpload {
  	machines!: VendingMachineAtento[];
	listIdAtento!:string[];
	listExcludeId!:number[];
	idFirmware!:number;
	idOperator:number| undefined;
	idClient:number| undefined;
	idSubClient:number| undefined;
	idEntorno:number| undefined;
	id!:number;
	destination!:string;
	fileList!:(FileDescription | Aplications)[];
	comprimir:boolean = false;
	reset:boolean = false;
	active:boolean = false;
	horaIni!:string;
	duracion!:string;
	version:string | undefined;
	type:string | undefined;
	model:string | undefined;
	cod!:string;
	name!:string;
	dateCreate!:string;
	fileName!:string;
}

export class FileDescription {
	base64!: string;
	name!: string;
	pakage!: string;
	path!: string;
	storageType!: string;
	typeFile!:number; // 1 : aplicación, 2:parametro, 0:undefined (opciones)
}


