import { ANDROID_VERSIONS } from "../util/android-versions";
import { olderThan } from "../util/util";
import { Profile } from "./profiles.model";

const ANDRIOD_NAME = 'ANDROID:';

export class VendingMachineAtento {
  id: number | undefined;
  idVM: number | undefined;
  name: string | undefined;
  idAtento: string | undefined;
  idEntornoAtento: number | undefined;
  idOperator: number | undefined;
  idClient: number | undefined;
  idSubClient: number | undefined;

  manufacID: string | undefined;
  modelNumber: string | undefined;
  swRev: string | undefined;
  serialNumber: string | undefined;

  commerce: string | undefined;
  
  location: string | undefined;
  ip: string | undefined;
  imei: string | undefined;
  sim: string | undefined;
  macBT: string | undefined;
  puntoVenta: string | undefined;  
  
  nameOperator: string | undefined;
  nameClient: string | undefined;
  nameSubClient: string | undefined;

  valueVend: number | undefined;      
  lastUpdate?: Date;      
  lastConect?: Date;  
  dateCreate?: Date;
  dateInstall?: Date;   
  lastVend?: Date;     
  lastVendCard?: Date;  
 
  hasPending:boolean = false;
  coverageLevel!: number; 
  coverageType!: string;

  versionKernel?:string;
	bootVersion?:string;
	osVersion?:string;
  appList?: string;
  listPerfil?:Profile[];
  latitude?:number;
  longitude?:number;

  //tiempo definido para marcar la terminal como inactiva.
  minInactivo?:number;
  isDisconnected:boolean = false;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  get osVersionLabel(){
    if(!this.osVersion) return '-';
    
    const isAndriod = this.osVersion?.toUpperCase().includes(ANDRIOD_NAME);
    if(!isAndriod) return this.osVersion;
    
    const [so, version] = this.osVersion?.split(':');
    const andriodVersion =  ANDROID_VERSIONS[Number(version)] ?? '';
    return `${so} ${andriodVersion} (API level ${version})`;
  }
  
  get isInactive(): boolean {
    return olderThan(this.lastConect, this.minInactivo);
  }

  get statusClass(): string {
    if (this.isInactive) return 'status-icon red';
    if (!this.listPerfil?.length) return 'status-icon yellow';
    if (this.hasPending) return 'status-icon blue';
    if (this.isDisconnected) return 'status-icon gray';
    return 'status-icon green'; // Predeterminado para "activo"
  }
  
  get statusLabel(): string {
    if (this.isInactive) return 'monitoring.status.inactive';
    if (!this.listPerfil?.length) return 'monitoring.status.noProfile';
    if (this.hasPending) return 'monitoring.status.pending';
    if (this.isDisconnected) return 'monitoring.status.disconnected';
    return 'monitoring.status.active';
  }

}

