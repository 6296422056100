import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'diasDesdeFecha'
})
export class DiasDesdeFechaPipe implements PipeTransform {

  /**
   * 
   * @param value formato dd-mm-yyyy HH:MM:SS
   * @returns 
   */
  transform(value: string): unknown {
    if(!value) return "";
  let resultado

  let fecha1 = moment(new Date(value));
  let fecha2 = moment();

  let diff_m = fecha2.diff(fecha1, 'm'); // Diff in minutos
  if(diff_m>=60){    
    let diff_h = fecha2.diff(fecha1, 'h'); // Diff in hours
    if(diff_h>=24){
      let diff_d = fecha2.diff(fecha1, 'd'); // Diff in days
      resultado=diff_d+"d"
    }else{
      let minutosSobra = diff_m%24;
      resultado=diff_h+"h "+minutosSobra+"m"
    }
  }else{
    resultado=diff_m+"m"
  }
    

   return resultado;
  }

}
