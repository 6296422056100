<div class="pagetitle">
  <h1>{{ 'subClient.title' | translate }}</h1>
</div>
<section class="section">
  <form [formGroup]="registerForm">
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'subClient.name' | translate }}<span class="obligatorio"></span></mat-label>
            <input matInput class="form-field" formControlName="name" type="text" required>
            <mat-error *ngIf="registerForm.get('name')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'subClient.cod' | translate }}</mat-label>
            <input matInput class="form-field" formControlName="cod" type="text">
          </mat-form-field>
        </div>
      </div>
    </div>
    <!-- Dropdown para seleccionar operadores, visible solo para ADMIN -->
    <div class="row" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_1)">
      <div class="col-12">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'subClient.operator' | translate }}<span class="obligatorio"></span></mat-label>
            <mat-select formControlName="idOperator" required>
              <mat-option *ngFor="let operator of operators" [value]="operator.id">
                {{ operator.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="registerForm.get('idOperator')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'subClient.client' | translate }}<span class="obligatorio"></span></mat-label>
            <mat-select formControlName="idClient" required>
              <mat-option *ngFor="let client of clients" [value]="client.id">
                {{ client.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="registerForm.get('idClient')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'subClient.cif' | translate }}</mat-label>
            <input matInput class="form-field" formControlName="cif" type="text">
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'subClient.localidad' | translate }}</mat-label>
            <input matInput class="form-field" formControlName="localidad" type="text">
          </mat-form-field>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'subClient.codPostal' | translate }}</mat-label>
            <input matInput class="form-field" formControlName="codPostal" type="text">
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'subClient.web' | translate }}</mat-label>
            <input matInput class="form-field" formControlName="web" type="text">
            <mat-error *ngIf="registerForm.get('web')?.hasError('pattern')">{{ 'error.pattern' | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'subClient.email' | translate }}</mat-label>
            <input matInput class="form-field" formControlName="email" type="email">
            <mat-error *ngIf="registerForm.get('email')?.hasError('email')">{{ 'error.email' | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'subClient.phone' | translate }}</mat-label>
            <input matInput class="form-field" formControlName="phone" type="phone">
            <mat-icon matSuffix matTooltip="{{ 'info.phoneValidation' | translate }}" class="custom-icon-color">info</mat-icon>
            <mat-error *ngIf="registerForm.get('phone')?.hasError('pattern')">{{ 'error.pattern' | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'subClient.personContact' | translate }}</mat-label>
            <input matInput class="form-field" formControlName="personContact" type="text">
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="button-group">
      <button mat-stroke-button class="custom-white-button" (click)="formExit()">{{ 'common.cancel' | translate }}</button>
      <ng-container *ngIf="rol.canByInput(rol.functIds.edit_subclients)">
        <button mat-flat-button class="custom-add-button" (click)="onFormSubmit()">{{ 'common.submit' | translate }}</button>
      </ng-container>
    </div>
  </form>
</section>
