import { FilterAndPagBean } from "./FilterAndPagBean";

export class ListFileRequest {
	idVM!:number;
    filterAndPagBean!:FilterAndPagBean;
}


export class ListFile {
    id!:number;
	idVM!:number;
	name!:string;
	dateCreate!:Date;
}

export class ListFileExtended extends ListFile {
    base64!:string;
}