import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { SelectMachineDetailComponent } from 'src/app/pages/comun/select-machine-detail/select-machine-detail.component';
import { MatLegacyTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Command, COMMAND_TYPES, LEVEL_TYPES } from './command.model';
import { NotifierService } from 'angular-notifier';
import { CommandsService } from './commands.service';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { MatLegacyDialog, MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacySlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { AutocompleteComponent } from 'src/app/components/autocomplete/autocomplete.component';

@Component({
  selector: 'app-commands',
  standalone: true,
  imports: [CommonModule, 
    TranslateModule, 
    SelectMachineDetailComponent, 
    MatLegacyFormFieldModule,
    MatLegacySelectModule, 
    MatLegacyInputModule,
    ReactiveFormsModule,
    MatLegacyDialogModule,
    MatLegacySlideToggleModule,
    AutocompleteComponent
  ],
  templateUrl: './commands.component.html',
  styleUrls: ['./commands.component.css']
})
export class CommandsComponent implements OnInit{
  @Input('data') vendingMachine!:VendingMachineAtento;
  hasVendingMachineSelected:boolean = false;
  selectedVendingMachines = new MatLegacyTableDataSource<VendingMachineAtento>();
  commandTypes:string[] = Object.values(COMMAND_TYPES);
  levelTypes:string[] = Object.values(LEVEL_TYPES);

  commandParams: FormGroup;

  constructor(
    private formBuilder:FormBuilder, 
    private notifier:NotifierService,
    private service:CommandsService,
    private dialog:MatLegacyDialog,
    private translate:TranslateService
  ){
    this.commandParams = this.formBuilder.group({
      command:['',Validators.required],
      packageName:[{value:'', disabled:true},Validators.required],
      level:[{value:'', disabled:true},Validators.required],
      recurrent:[{value:false, disabled:true}]
    });
  }

  ngOnInit(): void {
    this.subscribeAndSetInteractions();
    
    if(this.vendingMachine){
      this.selectedVendingMachines.data.push(this.vendingMachine);
      this.hasVendingMachineSelected = true
    }
  }

  subscribeAndSetInteractions(){
    this.commandParams.get('command')?.valueChanges.subscribe((value)=>{
      console.log('change command', value)
      switch(value){
        case COMMAND_TYPES.UNINSTALL:
          this.commandParams.get('packageName')?.enable();
          this.commandParams.get('level')?.disable()
          this.commandParams.get('recurrent')?.disable()
          break;
        case COMMAND_TYPES.ACTIVATE_LOG:
          this.commandParams.get('packageName')?.enable()
          this.commandParams.get('level')?.enable()
          this.commandParams.get('recurrent')?.enable()
          break;
        case COMMAND_TYPES.REQUEST_LOG:
          this.commandParams.get('packageName')?.enable()
          this.commandParams.get('level')?.disable()
          this.commandParams.get('recurrent')?.disable()
          break;
        default:
          this.commandParams.get('packageName')?.disable()
          this.commandParams.get('level')?.disable()
          this.commandParams.get('recurrent')?.disable()
          break;
      }
    });
  }

  async onFormSubmit(){
    if (!this.selectedVendingMachines.data.length) {
      this.notifier.notify('warning', this.translate.instant('warning.terminalIsMissing'));
      return;
    }

    if(this.commandParams.invalid){
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }
    
    const newCommand = new Command;
    newCommand.machines = this.selectedVendingMachines.data.map((vm)=> { return { id : vm.id as number }});
    newCommand.params.push(this.commandParams.value);
    this.createCommand(newCommand);
  }

  createCommand(command:Command){
    const dialogData = new ConfirmDialogData(); 
    dialogData.titleI18n = this.translate.instant('confirmDialog.createCommandTitle');
    dialogData.textI18n = this.translate.instant('confirmDialog.createCommandText');
    dialogData.svgIcon = 'assets/img/confirm._icon.svg';

    const dialog = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    dialog.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {        
        const response = await this.service.create(command);
        if(response?.status === 0) this.notifier.notify('success',this.translate.instant('success.commandCreated'));
      }
    });
  }

}
