<div class="headerStyle">
  <div class="header-content">
    <!-- Ícono y nombre del terminal -->
    <div class="terminal-info">
      <img src="assets/img/Featured_icon.svg" alt="Terminal Icon" class="terminal-icon" />
      <div class="terminal-name">#
        {{ data.terminalName }} <span class="terminal-id"></span>
      </div>
      <div class="status-indicator" *ngIf="data">
        <i [ngClass]="data.statusClass" class="status-icon"></i>
        <span class="status-label">{{ data.statusLabel | translate }}</span>
      </div>
      
    </div>

    <!-- Botón de cerrar -->
    <div class="action-buttons">
      <button class="close-button" (click)="closeScreen()">
        {{ 'editPanel.close' | translate }}
      </button>
    </div>
  </div>
</div>


<mat-tab-group [selectedIndex]="selectedTabIndex" (selectedTabChange)="changeTab($event)" class="custom-tabs">
  <mat-tab>
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.info' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-info-terminal></app-info-terminal>
    </ng-template>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.installedApps' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-installed-apps></app-installed-apps>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="rol.canByInput(rol.functIds.view_sells)">
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.sells' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-ventas></app-ventas>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="rol.canByInput(rol.functIds.view_alerts)">
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.alerts' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-alerts></app-alerts>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="rol.canByInput(rol.functIds.view_incidences)">
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.incidences' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-incidences></app-incidences>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="rol.canByInput(rol.functIds.view_sensors)">
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.sensors' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-sensores></app-sensores>
    </ng-template>
  </mat-tab>

  <mat-tab
    *ngIf="rol.canByInputs([rol.functIds.send_file,rol.functIds.get_file,rol.functIds.commands,rol.functIds.view_file])">
    <ng-template mat-tab-label>
      <span>{{'monitoring.tabs.maintenance' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-tabs-mantenance></app-tabs-mantenance>
    </ng-template>
  </mat-tab>
</mat-tab-group>