
  <div class="edit-panel">
    <div class="header">
      <div class="title">{{ 'incidences.title' | translate }}</div>
      <div class="action-buttons">
        <ng-container *ngIf="!readOnly">
          <button *ngIf="client.canByInput(client.functIds.edit_incidences)" mat-flat-button class="custom-add-button" (click)="onFormSubmit()" 
          [disabled]="registerForm.pristine || registerForm.invalid">{{ 'common.submit' | translate }}</button>
        </ng-container>
        <button class="close-button" (click)="formExit()">{{ 'editPanel.close' | translate }}</button>
      </div>
    </div>


    <form [formGroup]="registerForm">
      <fieldset class="edit-container bordered">
        <legend class="details-header">
          <label class="title-small">{{ 'incidences.details' | translate }}</label>
        </legend>
        <div class="edit-content">
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'incidences.type' | translate }}<span class="obligatorio"></span></mat-label>
                  <mat-select formControlName="type" [value]="typeSelect" required>
                    <mat-option value="NULL"> </mat-option>
                    <mat-option value="0">{{ 'incidences.types.other' | translate }}</mat-option>
                    <mat-option value="1">{{ 'incidences.types.suggestion' | translate }}</mat-option>
                    <mat-option value="3">{{ 'incidences.types.terminalBreakdown' | translate }}</mat-option>
                    <mat-option value="4">{{ 'incidences.types.nonActiveTerminal' | translate }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="registerForm.get('type')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'incidences.operator' | translate }}<span class="obligatorio"></span></mat-label>
                  <mat-select formControlName="operator" required>
                    <mat-option *ngFor="let operator of operatorList" [value]="operator.id">
                      {{ operator.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="registerForm.get('operator')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
  
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="form-group">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'incidences.serialNumber' | translate }}<span class="obligatorio"></span></mat-label>
                  <mat-select formControlName="numTer" required>
                    <mat-option *ngFor="let terminal of terminals" [value]="terminal.id">{{ terminal.serialNumber }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="registerForm.get('numTer')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
  
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'incidences.description' | translate }}<span class="obligatorio"></span></mat-label>
                  <textarea formControlName="commet" rows="7" matInput placeholder="" cdkTextareaAutosize   cdkAutosizeMinRows="3" cdkAutosizeMaxRows="10"></textarea>
                  <mat-error *ngIf="registerForm.get('commet')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
  
          <!-- Campo de resolución visible solo si es una incidencia cerrada -->
          <div class="row" *ngIf="isCloseIncidence">
            <div class="col-12">
              <div class="form-group">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'incidences.resolution' | translate }}<span class="obligatorio"></span></mat-label>
                  <textarea formControlName="resolution" rows="7" matInput placeholder="" cdkTextareaAutosize   cdkAutosizeMinRows="3" cdkAutosizeMaxRows="10"></textarea>
                  <mat-error *ngIf="registerForm.get('resolution')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  