import { VendingMachineAtento } from "./vendingMachineAtento.model";

export class TerminalFromFile {
    numero_serie:string;
    fabricante:string;
    modelo: string;

    constructor(_numero_serie:string,_fabricante:string,_modelo:string){
        this.numero_serie = _numero_serie;
        this.fabricante = _fabricante;
        this.modelo = _modelo;
    }

    public get id(){
        return (this.fabricante+'#'+this.modelo+'#'+this.numero_serie).toUpperCase();
    }
    
    public isTerminalDataComplete(): boolean {
        return !!(this.numero_serie && this.fabricante && this.modelo);
    }

    public mapToTerminal():VendingMachineAtento{
        const vendingMachine = new VendingMachineAtento();
        vendingMachine.idAtento = this.id;        
        vendingMachine.manufacID = this.fabricante.toUpperCase();     
        vendingMachine.modelNumber = this.modelo.toUpperCase();
        vendingMachine.serialNumber = this.numero_serie;
        return vendingMachine;
    }
}