import { Injectable } from '@angular/core';
import { Signup } from 'src/app/models/signup.model';
import { Users } from 'src/app/models/users.model';
import { CommonService } from 'src/app/services/common.service';
import { RestService } from 'src/app/services/merchant/Rest.service';


@Injectable({
  providedIn: 'root',
})
export class PagesRegisterService {
  
  constructor(
    private merchantRest : RestService,
    public commonService: CommonService) {

    }

  async getInfo(_body: Signup){
    let value = await this.merchantRest.commonRestCallAuth(_body, 'newuser/invitationInfo',true,true)
    .catch(err => {      
      return null
    })
    return value;   
  }


  async save(_body: Users){
    let value = await this.merchantRest.commonRestCallAuth(_body, 'newuser/enroll',true,true)   
    .catch(err => {
      return null
    })

    return value;   
  }


 
}