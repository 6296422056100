import { ChangeDetectionStrategy, Component, inject, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataPeriod } from 'src/app/models/dataPeriod.model';
import { ApexAxisChartSeries, ApexChart, ApexFill, ApexTooltip, ApexXAxis, ApexYAxis, NgApexchartsModule } from 'ng-apexcharts';
import { parseStringToUTCDate } from 'src/app/util/util';
import { CosumeChartPeriod } from './consume-chart-period.model';
import { TranslateService } from '@ngx-translate/core';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: any;
  fill: ApexFill;
  tooltip: ApexTooltip;
};

@Component({
  selector: 'app-consume-chart',
  standalone: true,
  imports: [CommonModule, NgApexchartsModule],
  templateUrl: './consume-chart.component.html',
  styleUrls: ['./consume-chart.component.css'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class ConsumeChartComponent implements OnChanges{
  translate = inject(TranslateService);

  @Input() list:DataPeriod[] = [];
  options?:ChartOptions; 

  groupDataByDay:CosumeChartPeriod[] = [];

  ngOnChanges(): void {
    this.mapDataPerDay();
    this.initializeChartOptions();  
  }

  setGroupPeriodData(periodDate:string, previousPeriodState:(CosumeChartPeriod | undefined), actualPeriod:DataPeriod): CosumeChartPeriod{
    const txData =  (previousPeriodState?.txData ?? 0) + (actualPeriod.txData ?? 0); 
    const rxData =  (previousPeriodState?.rxData ?? 0) + (actualPeriod.rxData ?? 0);

    return new CosumeChartPeriod(periodDate, txData, rxData);
  }


  private mapDataPerDay(){
    const mapListPerDay = new Map<string, CosumeChartPeriod>();
    this.groupDataByDay = [];

    this.list.forEach((dataPeriod:DataPeriod) =>{
      const periodDate = parseStringToUTCDate(dataPeriod.period as string).toLocaleDateString('en');
      const previousPeriod = Array.from(mapListPerDay.values()).pop();
      mapListPerDay.set(periodDate, this.setGroupPeriodData(periodDate, previousPeriod, dataPeriod));
    });

    mapListPerDay.forEach(period=>{
      this.groupDataByDay.push(period);
    });
  }

  initializeChartOptions(): void {
    this.options = {
      series: this.series,
      chart: {
        height: 350,
        type:'line',
        zoom:{
          enabled:false
        },
        locales:[this.chartLocale],
        defaultLocale:this.defaultLocale
      },
      stroke: {
        width: [2, 2, 2],
        curve: "smooth"
      },
      fill: this.fillConfig,
      xaxis: this.xAxisConfig,
      yaxis: this.yAxisConfig,
      tooltip: this.tooltipConfig
    };
  }

  private get series():ApexAxisChartSeries{
    console.debug('get series', this.groupDataByDay);
    return [
      {
        name: this.translate.instant('sensors.consum-tx'),
        type: "line",
        data: this.groupDataByDay.map(item => item.getTxSeriesData())
      },
      {
        name: this.translate.instant('sensors.consum-rx'),
        type: "line",
        data: this.groupDataByDay.map(item => item.getRxSeriesData())
      },
      {
        name: this.translate.instant('sensors.consum-total'),
        type: "area",
        data: this.groupDataByDay.map(item => item.getTotalSeriesData())
      }
    ];    
  }

  private get tooltipConfig(): ApexTooltip{
    return  {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y: number) {
          if (typeof y !== "undefined") {
            return `${y.toFixed(3)} Mb`;
          }
          return y;
        }
      }
    }
  }

  
  private get xAxisConfig(): ApexXAxis {
    return {
      type: 'category',
      labels: {
        formatter: function (val: string) {
          return new Date(val).toLocaleDateString();
        }
      }
    };
  }

  private get yAxisConfig(): ApexYAxis{
    return {
      title: {
        text: this.translate.instant('sensors.consum-xAxis')
      },
      labels: {
        formatter: function (value: number) {
          return `${value.toFixed(2)} Mb`;
        }
      },
      min: 0
    }
  }

  private get fillConfig(): ApexFill {
    return {
      opacity: [0.85, 0.25, 0.85],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100]
      }
    }
  }

  get defaultLocale(){
    return 'es'
  }

  get chartLocale(){
    return  {
        name: 'es',
        options: {
          toolbar: {
            donwload:this.translate.instant('common.downloadSVG'),
            exportToSVG: this.translate.instant('common.downloadSVG'),
            exportToPNG: this.translate.instant('common.downloadPNG'),
            exportToCSV: this.translate.instant('common.downloadCSV'),
          }
        }
    };
  }
}
