<div class="badges-container">
    <ng-container *ngFor="let tag of tagsList | slice:0:2 ">
        <div class="badge">
            <i [ngStyle]="{'background-color': getTagColor(tag) }" class="badge-icon"></i>
            <span class="badge-label">{{ getTagLabel(tag) }}</span>
        </div>
    </ng-container>
    <div class="badge" *ngIf="limit && tags.length > limit">
        <span class="badge-label"> +{{( tags.length || 0) - limit}}</span>
    </div>
</div>