<div class="pagetitle">
    <h1>{{ 'newAlert.title' | translate }}</h1>      
</div>
<section class="section">
    <form [formGroup]="registerForm">
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <!-- Hierarchy Form-->
                    <app-hierarchy-selector formControlName="hierarchy"></app-hierarchy-selector>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'newAlert.type' | translate }}<span class="obligatorio"></span></mat-label>
                        <mat-select formControlName="type">
                            <mat-option *ngFor="let type of listaAlertTypes" [value]="type.value">{{ type.label }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('type')?.hasError('required')" i18n>{{ 'error.required.field' | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'newAlert.operation' | translate }}<span class="obligatorio"></span></mat-label>
                        <mat-select formControlName="operacion">
                            <mat-option value=">">{{ 'newAlert.operationType.greater' | translate }}</mat-option>
                            <mat-option value="<">{{ 'newAlert.operationType.less' | translate }}</mat-option>
                            <mat-option value="=">{{ 'newAlert.operationType.equal' | translate }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('operacion')?.hasError('required')" i18n>{{ 'error.required.field' | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'newAlert.value' | translate }}</mat-label>
                        <input formControlName="value" matInput type="text">
                        <mat-error *ngIf="registerForm.get('value')?.hasError('required')" i18n>{{ 'error.required.field' | translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('value')?.hasError('pattern')" i18n>{{ 'error.pattern' | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'newAlert.priority' | translate }}</mat-label>
                        <mat-select formControlName="priority">
                            <mat-option value="3">{{ 'newAlert.priorityType.high' | translate }}</mat-option>
                            <mat-option value="2">{{ 'newAlert.priorityType.medium' | translate }}</mat-option>
                            <mat-option value="1">{{ 'newAlert.priorityType.low' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="button-group">
            <button mat-stroke-button class="custom-white-button" (click)="formExit()">{{ 'common.cancel' | translate }}</button>
            <button mat-flat-button class="custom-add-button" (click)="onFormSubmit()">{{ 'common.submit' | translate }}</button> 
        </div>
    </form>
</section>