import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { CommonService } from 'src/app/services/common.service';
import { Operator } from 'src/app/models/operator.model';
import { OperatorService } from './operator.service';
import { FormNewOperatorComponent } from './form-new-operator/form-new-operator.component';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AppliedFilter, FieldType, FilterField } from 'src/app/models/filter.model';
import { FilterableValue, FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { TranslateService } from '@ngx-translate/core';
import { FormEditOperatorComponent } from './form-edit-operator/form-edit-operator.component';

enum Columns {
  name = 'name',
  cod = 'cod',
  view = 'view'
}

const FILTER_COLUMNS:{ field:string, type: FieldType }[] = [
  { field:'name', type:'string'},
  { field:'cod', type:'string' },
]

@Component({
  selector: 'app-operator',
  templateUrl: './operator.component.html',
  styleUrls: ['./operator.component.css'],
  providers:[{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})
export class OperatorComponent implements OnInit, AfterViewInit {

  displayedColumns =  Object.keys(Columns);
  dataSource = new MatTableDataSource<Operator>();
  private dialogRef!: MatDialogRef<FormNewOperatorComponent>;
  exportFileName: string | undefined;

  pageLength = 0;
  pageSize = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  sortId:string | null = Columns.name+"" ;
  direccion: string | null = "ASC";

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  // Filters config
  filterColumns:FilterField[] = FILTER_COLUMNS.map((column)=>{
    const fielterField = new FilterField(column.field, `operators.${column.field}`, column.type);
    return fielterField;
  })
  filterList: FilterableValue[] = [];

  constructor(public dialog: MatDialog, 
    public servicios:OperatorService,
    public commonService : CommonService,
    private translate:TranslateService
  ) {     
  }
  
  async ngOnInit(): Promise<void> {
    this.lanzarLLamada();
    this.exportFileName = this.translate.instant('export.fileOperator');

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async lanzarLLamada(){
    const request = this.getFilterAndPagBean();
    const value = await this.servicios.find(request);
    if(value){
      this.dataSource = new MatTableDataSource<Operator>(value.data);
      this.pageLength = value.data.length ?? 0;
    }
  }
  
  private getFilterAndPagBean():FilterAndPagBean{
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize:this.pageSize };
    const startPage = pageIndex*pageSize;
    const endPage = startPage+pageSize;
    return new FilterAndPagBean(
        this.direccion, 
        this.sortId, 
        this.filterList, 
        startPage,
        endPage,
        pageIndex
      );
  }

  onNew(){
    this.dialogRef = this.dialog.open(FormNewOperatorComponent, {
      width: '600px', panelClass: 'custom-modalbox-big',
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }
 
  onEdit(row:Operator){
    const dialogRef = this.dialog.open(FormEditOperatorComponent, {
      backdropClass: 'custom-edit-panel-backdrop',
      panelClass: 'edit-panel' ,
      data: { operatorId : row.id }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }

  refrescar() {
    this.lanzarLLamada();
  }

  pageEvent() {
    this.lanzarLLamada();
  }

  announceSortChange(sortState: Sort) {
    let active = sortState.active.replace("_",".")
    this.sortId=active;
    if(sortState.direction){
      this.direccion=sortState.direction.toUpperCase()
    }else{
      this.direccion=null
      this.sortId=null
    }
    this.paginator.firstPage();
    this.lanzarLLamada();
  }
  
  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  getNotExportColumns(){
    const actionsColIdx = (this.displayedColumns.length-1);
    return [actionsColIdx];
  }
}
