export class Aplications {
	id!:number;
	name!:string; 
	dateCreate!: Date; 
	dateUpdate!: Date;
	description!: string;
	fileName!:string;
	base64!:string;
	package!:string;
	pakage!:string;
	version!:string;
	manufacID!: string;
	modelNumber?: string;
	
	path!:string;
	storageType!:string;
	isLast!:boolean;
	idOperator?:number;
	idClient?:number;
	idSubClient?:number;
	listEtiquetas?:number[];
}
