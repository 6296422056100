<ng-container *ngIf="profile">
  <div class="edit-panel">
    <div class="header">
      <div class="title">{{ profile.name }}</div>
      <div class="action-buttons">
        <button mat-flat-button class="custom-add-button" (click)="onSave()" [disabled]="!form.valid">{{ 'common.save' |
          translate }}</button>
        <button class="close-button" (click)="onCancel()">×</button>
      </div>
    </div>

    <!-- Main Form -->
    <form [formGroup]="form">
      <!-- Details Section -->
      <fieldset class="edit-container bordered">
        <legend class="details-header" (click)="toggleSection('details')">
          <label class="title-small">{{ 'profiles.details' | translate }}</label>
          <mat-icon class="toggle-icon">{{ isOpen['details'] ? 'expand_less' : 'expand_more' }}</mat-icon>
        </legend>
        <div class="edit-content" *ngIf="isOpen['details']">
          <div class="form-group">
            <label class="title-xs">{{ 'profiles.name' | translate }}</label>
            <mat-form-field appearance="outline">
              <input matInput formControlName="name" placeholder="{{ 'profiles.placeholder.name' | translate }}" required>
              <mat-error *ngIf="form.get('name')?.hasError('required')">
                {{ 'error.required.field' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-group">
            <label class="title-xs">{{ 'profiles.description' | translate }}</label>
            <mat-form-field appearance="outline">
              <textarea matInput formControlName="description" rows="4" cdkTextareaAutosize   cdkAutosizeMinRows="3" cdkAutosizeMaxRows="6"
                placeholder="{{ 'profiles.placeholder.description' | translate }}" class="textarea">
              </textarea>
              <mat-error *ngIf="form.get('description')?.hasError('required')">
                {{ 'error.required.field' | translate }}
              </mat-error>
              <mat-error *ngIf="form.get('description')?.hasError('maxlength')">
                {{ 'error.maxLength' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          
        </div>
      </fieldset>

        <!-- Hierarchy Section -->
      <fieldset class="edit-container bordered">
        <legend class="details-header" (click)="toggleSection('hierarchy')">
          <label class="title-small">{{ 'editPanel.hierarchy' | translate }}</label>
          <mat-icon class="toggle-icon">{{ isOpen['hierarchy'] ? 'expand_less' : 'expand_more' }}</mat-icon>
        </legend>
        
        <div class="edit-content row" *ngIf="isOpen['hierarchy']">
          <div class="form-group">
            <!-- Hierarchy Form-->
            <app-hierarchy-selector formControlName="hierarchy"></app-hierarchy-selector>
          </div>
        </div>
      </fieldset>

      <!-- Date Fields Section -->
      <fieldset class="edit-container bordered">
        <legend class="details-header" (click)="toggleSection('dateFields')">
          <label class="title-small">{{ 'profiles.dates' | translate }}</label>
          <mat-icon class="toggle-icon">{{ isOpen['dateFields'] ? 'expand_less' : 'expand_more' }}</mat-icon>
        </legend>
        <div class="edit-content row" *ngIf="isOpen['dateFields']">
          <!-- Registration Date -->
          <div class="form-group col col-lg-6">
            <label class="title-xs">{{ 'profiles.dateCreate' | translate }}</label>
            <mat-form-field appearance="outline">
              <mat-icon matPrefix>calendar_month</mat-icon>
              <input matInput formControlName="dateCreate" type="text"
                placeholder="{{ 'profiles.dateCreate' | translate }}">
            </mat-form-field>
          </div>

          <!-- Activation Date -->
          <div class="form-group col col-lg-6">
            <label class="title-xs">{{ 'profiles.dateUpdate' | translate }}</label>
            <mat-form-field appearance="outline">
              <mat-icon matPrefix>calendar_month</mat-icon>
              <input matInput formControlName="dateUpdate" type="text"
                placeholder="{{ 'profiles.dateUpdate' | translate }}">
            </mat-form-field>
          </div>
        </div>
      </fieldset>

      <!-- Terminals Section -->
      <fieldset class="edit-container bordered">
        <legend class="details-header" (click)="toggleSection('terminals')">
          <label class="title-small">{{ 'profiles.terminals' | translate }}</label>
          <mat-icon class="toggle-icon">{{ isOpen['terminals'] ? 'expand_less' : 'expand_more' }}</mat-icon>
        </legend>
        <div class="edit-content" *ngIf="isOpen['terminals']">
          <mat-action-list class="p-0">
            <button mat-list-item class="mat-action-list" (click)="openEditTerminalsScreen()">
              <div mat-line>{{ 'profiles.associatedTerminals' | translate }}</div>
              <div mat-line class="detail">{{ profile.numMaquinas }} {{ 'profiles.associated' | translate }}</div>
              <div matListItemIcon class="mat-action-list-action">
                <label>{{ 'common.modify' | translate }}</label>
                <mat-icon>chevron_right</mat-icon>
              </div>
            </button>
            <button mat-list-item class="mat-action-list" (click)="openAddTerminalsScreen()">
              <div mat-line>{{ 'profiles.newTerminals' | translate }}</div>
              <div matListItemIcon class="mat-action-list-action">
                <label>{{ 'common.add' | translate }}</label>
                <mat-icon>chevron_right</mat-icon>
              </div>
            </button>
          </mat-action-list>
        </div>
      </fieldset>

      <!-- Apps Section -->
      <fieldset class="edit-container bordered">
        <legend class="details-header" (click)="toggleSection('apps')">
          <label class="title-small">{{ 'profiles.aplications' | translate }}</label>
          <mat-icon class="toggle-icon">{{ isOpen['apps'] ? 'expand_less' : 'expand_more' }}</mat-icon>
        </legend>
        <div class="edit-content" *ngIf="isOpen['apps']">
          <div class="c">
            <label class="title-xs">{{ 'profiles.associatedApps' | translate }} {{ profile.listApp?.length }}</label>
            <app-list-related-apps [idProfile]="profile.id" [data]="profile.listApp ?? []" [apps]="availableApps"
              [parameters]="parameters" (delete)="deleteApp($event)" (edit)="updateRelatedAppsScreen($event)"></app-list-related-apps>
            <mat-action-list>
              <button mat-list-item class="mat-action-list" (click)="updateRelatedAppsScreen()">
                <div mat-line>{{ 'profiles.newApp' | translate }}</div>
                <div matListItemIcon class="mat-action-list-action">
                  <label>{{ 'common.add' | translate }}</label>
                  <mat-icon>chevron_right</mat-icon>
                </div>
              </button>
            </mat-action-list>
          </div>
        </div>
      </fieldset>

      <!-- Distance Section -->
      <fieldset class="edit-container bordered">
        <legend class="details-header" (click)="toggleSection('distance')">
          <label class="title-small">{{ 'profiles.distance' | translate }}</label>
          <mat-icon class="toggle-icon">{{ isOpen['distance'] ? 'expand_less' : 'expand_more' }}</mat-icon>
        </legend>
        <div class="edit-content" *ngIf="isOpen['distance']">
          <!-- Slide toggle on top -->
          <mat-slide-toggle formControlName="enableLocation" class="toggle-selector">
            {{ 'profiles.distanceLocation' | translate }}
          </mat-slide-toggle>
          <!-- Flex container for Distance and Units -->
          <div class="form-group distance-units-container">
            <!-- Distance -->
            <div class="distance-field">
              <label class="title-xs">{{ 'profiles.distance' | translate }}</label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="distanceLocation" type="number" placeholder="{{ 'profiles.placeholder.distance' | translate }}">
                <mat-error *ngIf="form.get('distanceLocation')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                <mat-error *ngIf="form.get('distanceLocation')?.hasError('max')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>

            <!-- Distance Unit -->
            <div class="units-field">
              <label class="title-xs">{{ 'profiles.units' | translate }}</label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="distanceUnit">
                  <mat-option value="km">{{'common.kilometers' | translate }}</mat-option>
                  <mat-option value="m">{{'common.meters' | translate }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </fieldset>

      <!-- Footer with Buttons -->
      <div class="footer" *ngIf="rol.canByInput(rol.functIds.delete_profiles)">
        <button mat-stroke-button class="custom-white-button" (click)="onDelete()">
          {{ 'common.delete' | translate }}
          <img class="delete-icon" src="assets/img/delete_icon.svg" alt="Delete icon">
        </button>
      </div>
    </form>
  </div>
</ng-container>