import { Component, OnInit,Inject,ViewChild, AfterViewInit } from '@angular/core';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FilterAndPagBean, FilterableValue } from 'src/app/models/FilterAndPagBean';
import { Pending } from 'src/app/models/pending.model';
import { environment } from 'src/environments/environment';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CommonService } from 'src/app/services/common.service';
import { NotifierService } from 'angular-notifier';
import { IncidenceService } from './incidence.service';
import { Incidence } from 'src/app/models/incidence.model';
import { FormNewIncidenceComponent } from './form-new-incidence/form-new-incidence.component';
import { TabsComponent } from '../../monitoring/tabs/tabs.component';
import { MonitoringService } from '../../monitoring/monitoring.service';
import { AppliedFilter, FieldType, FilterField, FilterOption } from 'src/app/models/filter.model';
import { TranslateService } from '@ngx-translate/core';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { UtilService } from 'src/app/services/util.service';
import { Operator } from 'src/app/models/operator.model';
import { FormEditIncidenceComponent } from './form-edit-incidence/form-edit-incidence.component';

enum BaseColumns {//en el orden que se quiera mostrar
  serialNumber = 'serialNumber',
  operator = 'operator',
  type = 'type',
  dateCreate = 'dateCreate', 
  description = 'description',
  userCreate = 'userCreate',
  resolution = 'resolution',
  dateDelete = 'dateDelete',
  userDelete = 'userDelete',
  status = 'status',
  view = 'view',
}

const { serialNumber, operator, ...Columns } = BaseColumns;

@Component({
  selector: 'app-incidences',
  templateUrl: './incidences.component.html',
  styleUrls: ['./incidences.component.css'],
  providers:[{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})
export class IncidencesComponent implements OnInit, AfterViewInit  {
  displayedColumns =  Object.keys(Columns);
  dataSource = new MatTableDataSource<Pending>();

  pageLength=0;
  pageSize=environment.defaultPaginacion;
  pageSizeOptions=environment.paginacion;
  sortId:string | null = Columns.dateCreate+"" ;
  direccion:string | null ="DESC";
  exportFileName: string | undefined;
  
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  TYPE_OPTIONS:FilterOption[] = [
    { value:"0", label: this.translate.instant("incidences.types.other")  },
    { value:"1", label: this.translate.instant("incidences.types.suggestion")  },
    { value:"3", label: this.translate.instant("incidences.types.terminalBreakdown")  },
    { value:"4", label: this.translate.instant("incidences.types.nonActiveTerminal") },
  ]
  
  FILTER_COLUMNS:{ field:string, type: FieldType, options?:FilterOption[]}[] = [
    { field:'serialNumber', type:'string' },
    { field:'type', type:'number', options: this.TYPE_OPTIONS},
    { field:'dateCreate', type:'date' },
    { field:'description', type:'string' },
    { field:'userCreate', type:'string' },
    { field:'resolution', type:'string' },
  ]
  

  filterColumns:FilterField[] = this.FILTER_COLUMNS.map((column)=>{
    const fielterField = new FilterField(column.field, `incidences.${column.field}`, column.type, column.options);
    return fielterField;
  })
  filterList: FilterableValue[] = [];

  private dialogRef!: MatDialogRef<FormNewIncidenceComponent>;
  private dialogRefEdit!: MatDialogRef<FormEditIncidenceComponent>;
  private dialogMonitoringRef!: MatDialogRef<TabsComponent>;
  isOnTerminalContext:boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data:{ terminalId:number }, 
    private notifier: NotifierService , 
    private servicios : IncidenceService,
    private monitoringService : MonitoringService,
    public dialog: MatDialog,
    public commonService : CommonService,
    public rol:RolHandlerService,
    private translate:TranslateService,
    private utils:UtilService
  ) {}
    
  private getFilterableValue(column:string): FilterableValue | undefined {
    return this.filterList.find((filter) => filter.column === column);
  }

  async lanzarLLamada() {
    const idVMFilter = this.getFilterableValue("idVM");   
    if(this.data.terminalId && !idVMFilter){
      const idVM = this.data.terminalId;
      this.filterList.push(new FilterableValue("idVM", idVM, "long", "EQUALS"));
    }

    const paginatorState = this.getPaginatorState();
    const request =  new FilterAndPagBean(
      this.direccion, 
      this.sortId, 
      this.filterList, 
      paginatorState.startPage,
      paginatorState.endPage,
      paginatorState.pageIndex
    );

    let value = await this.servicios.find(request);
    let pagCount = await this.servicios.countTotal(request);
    
    if (value) {
      this.dataSource = new MatTableDataSource<Pending>(value.data);
      if (pagCount) this.pageLength = pagCount.data?.total ?? 0;
    }
  }

  private getPaginatorState(){
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize:this.pageSize };
    const startPage = pageIndex*pageSize;
    const endPage = startPage+pageSize;

    return { startPage, endPage, pageIndex };
  }

  async cargar(){   
    //operadores
    this.utils.findOperators().subscribe((operators:Operator[]) => {
      const operationsOptions = operators.map((op:Operator)=>{ return { label:op.name, value:(op.id as number) }});
      this.filterColumns.push(new FilterField('operator.id', 'incidences.operator', 'long', operationsOptions));
    });
  }
  
  ngOnInit(): void {
    this.exportFileName = this.translate.instant('export.fileIncidence');
    
    const isAdmin =  this.rol.isAdmin();
    if (isAdmin && !this.data.terminalId) {
      this.displayedColumns = [ operator, ...this.displayedColumns];
      this.cargar();
    }
 
    if(this.data.terminalId){
      this.isOnTerminalContext = true;
    } else {
      this.displayedColumns = [ serialNumber, ...this.displayedColumns];
    }
    this.lanzarLLamada();
  }
    
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  refrescar() {
    this.lanzarLLamada();
  }

  pageEvent(){
    this.lanzarLLamada();
  }

  announceSortChange(sortState: Sort) {
    let active = sortState.active.replace("_",".")
    this.sortId=active;
   
    if(sortState.direction){
      this.direccion=sortState.direction.toUpperCase()
    }else{
      this.direccion=null
      this.sortId=null
    }
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  async onView(row: any){
    this.monitoringService.getMachine(row.idVM).then((response)=>{
      if(!response) return;
      
      const terminal = response?.data;
      this.dialogMonitoringRef = this.dialog.open(TabsComponent, {
        width: '60%',
        backdropClass: 'custom-edit-panel-backdrop',
        panelClass: 'edit-panel',
        data: { terminalId: terminal.id, terminalName:terminal.serialNumber }
      });
  
      this.dialogMonitoringRef.afterClosed().subscribe((result) => {
        if (result === 1) {        
          this.lanzarLLamada();
        }
      });
    });


  }

  async getMachine(idVM:number): Promise<any> {
    return await this.monitoringService.getMachine(idVM);
  }


  async onEdit(row:Incidence) {
    const terminal = await this.getMachine(row.idVM); 
    if(!terminal) return;

    this.dialogRefEdit = this.dialog.open(FormEditIncidenceComponent, {
      backdropClass: 'custom-edit-panel-backdrop',
      panelClass: 'edit-panel' ,
      data: { incidencia:row,vm:terminal.data,close:false }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        this.notifier.notify('success', this.translate.instant('success.incidenceSave')) 
        this.lanzarLLamada();
      }
    });
  }

  async onClose(row:Incidence) {
    const terminal = await this.getMachine(row.idVM); 
    if(!terminal) return;

    this.dialogRef = this.dialog.open(FormNewIncidenceComponent, {
      width: '800px', panelClass: 'custom-modalbox-big',
      data: { incidencia:row,vm:terminal.data,close:true }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        this.notifier.notify('success', this.translate.instant('success.incidenceClosed')) 
        this.lanzarLLamada();
      }
    });
  }

  async onNew() {
    let terminalData = null;
    if(this.data.terminalId){
      const terminal = await this.getMachine(this.data.terminalId); 
      if(terminal) terminalData = terminal.data;
    }
    
    this.dialogRef = this.dialog.open(FormNewIncidenceComponent, {
      width: '600px', panelClass: 'custom-modalbox-big',
      data: { vm:terminalData }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        this.notifier.notify('success', this.translate.instant('success.incidenceSave')) 
        this.lanzarLLamada();
      }
    });
  }

  // Método para determinar la clase del ícono basado en la presencia de `resolution`
  getStatusIconClass(element: any): string {
    // Verificar si `resolution` existe (significa que está cerrada)
    if (element.resolution) {
      return 'status-icon green';  // Verde para incidencias cerradas
    }
    return 'status-icon red';  // Rojo para incidencias abiertas
  }

  // Método para determinar el texto del estado
  getStatusLabel(element: any): string {
    // Verificar si `resolution` existe (significa que está cerrada)
    if (element.resolution) {
      return this.translate.instant('incidences.status.closed'); // Traducción para "cerrada"
    }
    return this.translate.instant('incidences.status.open'); // Traducción para "abierta"
  }

   
  getNotExportColumns(){
    const actionsColIdx = (this.displayedColumns.length-1);
    return [actionsColIdx];
  }
}
