<main class="main">
    <section class="section">

            <!-- JERARQUIA -->
            <fieldset class="edit-container bordered">
                <legend class="details-header" (click)="toggleSection('jerarquia')">
                    <label class="title-small">{{ 'information.titleH3' | translate }}</label>
                    <mat-icon class="toggle-icon">{{ isOpen['jerarquia'] ? 'expand_less' : 'expand_more' }}</mat-icon>
                </legend>
                <div class="edit-content" *ngIf="isOpen['jerarquia']">
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="info-block">
                                <label>{{ 'information.organizationField' | translate }}</label>
                                <p>{{ infoTerminal.idOperator | levelName:'LEVEL_1' }}</p>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="info-block">
                                <label>{{ 'information.divisionField' | translate }}</label>
                                <p>{{ infoTerminal.idClient | levelName:'LEVEL_2' }}</p>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="info-block">
                                <label>{{ 'information.regionField' | translate }}</label>
                                <p>{{ infoTerminal.idSubClient | levelName:'LEVEL_3' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>

            <!-- INFO -->
            <fieldset class="edit-container bordered">
                <legend class="details-header" (click)="toggleSection('info')">
                    <label class="title-small">{{ 'information.profilesA' | translate }}</label>
                    <mat-icon class="toggle-icon">{{ isOpen['info'] ? 'expand_less' : 'expand_more' }}</mat-icon>
                </legend>
                <div class="edit-content" *ngIf="isOpen['info']">
                    <div class="row" *ngIf="rol.isAdmin()">
                        <div class="col-lg-6">
                            <div class="info-block">
                                <label>{{ 'information.terminalNum' | translate }}</label>
                                <p>{{ infoTerminal.idAtento ?? '-' }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="info-block">
                                <label>{{ 'information.serialNumber' | translate }}</label>
                                <p>{{ infoTerminal.serialNumber ?? '-' }}</p>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="info-block">
                                <label>{{ 'information.manufacture' | translate }}</label>
                                <p>{{ infoTerminal.manufacID ?? '-' }}</p>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="info-block">
                                <label>{{ 'information.model' | translate }}</label>
                                <p>{{ infoTerminal.modelNumber ?? '-' }}</p>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="info-block">
                                <label>{{ 'information.swVersion' | translate }}</label>
                                <p>{{ infoTerminal.swRev ?? '-' }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="info-block">
                                <label>{{ 'information.ipAddress' | translate }}</label>
                                <p>{{ infoTerminal.ip ?? '-' }}</p>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="info-block">
                                <label>{{ 'information.macBT' | translate }}</label>
                                <p>{{ infoTerminal.macBT ?? '-' }}</p>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="info-block">
                                <label>{{ 'information.sim' | translate }}</label>
                                <p>{{ infoTerminal.sim ?? '-' }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="info-block">
                                <label>{{ 'information.instalation' | translate }}</label>
                                <p>{{ infoTerminal.dateCreate?.toString() |  dateOnTimeZone:true }}</p>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="info-block">
                                <label>{{ 'information.commerce' | translate }}</label>
                                <p>{{ infoTerminal.commerce ?? '-' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>

            <!-- ESTADO -->
            <fieldset class="edit-container bordered">
                <legend class="details-header" (click)="toggleSection('estado')">
                    <label class="title-small">{{ 'information.titleH3Term' | translate }}</label>
                    <mat-icon class="toggle-icon">{{ isOpen['estado'] ? 'expand_less' : 'expand_more' }}</mat-icon>
                </legend>
                <div class="edit-content" *ngIf="isOpen['estado']">
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="info-block">
                                <label>{{ 'information.kernelVersion' | translate }}</label>
                                <p>{{ infoTerminal.versionKernel  ?? '-' }}</p>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="info-block">
                                <label>{{ 'information.bootVersion' | translate }}</label>
                                <p>{{ infoTerminal.bootVersion  ?? '-' }}</p>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="info-block">
                                <label>{{ 'information.osVersion' | translate }}</label>
                                <p>{{ infoTerminal.osVersionLabel  }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="info-block">
                                <label>{{ 'information.profile' | translate }}</label>
                                <div class="profiles-list">
                                    <ng-container *ngFor="let profile of validProfiles">
                                        <a class="reditect-tag" (click)="onView(profile)">{{ profile?.name }}</a>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="info-block">
                                <label>{{ 'information.connection' | translate }}</label>
                                <app-coverage-icon [level]="infoTerminal.coverageLevel" [type]="infoTerminal.coverageType"
                                    [isInactive]="infoTerminal.isInactive">
                                </app-coverage-icon>
                            </div>
                        </div>                        
                    </div>

                </div>
            </fieldset>
            
            <fieldset class="edit-container bordered">
                <legend class="details-header" (click)="toggleSection('mapa')">
                    <label class="title-small">{{ 'information.map' | translate }}</label>
                    <mat-icon class="toggle-icon">
                        {{ isOpen['mapa'] ? 'expand_less' : 'expand_more' }}
                    </mat-icon>
                </legend>
                <div class="edit-content" *ngIf="isOpen['mapa']">
                    <div *ngIf="coordinates.length; else noLocationMessage">
                        <div class="map-container">
                            <app-map [coordinates]="coordinates"></app-map>
                        </div>
                    </div>
                    <ng-template #noLocationMessage>
                        <div class="charts-empty-state">
                            <p>{{ 'information.noLocationAvailable' | translate }}</p>
                        </div>
                    </ng-template>
                </div>
            </fieldset>
    </section>

    <div class="footer mb-4">
        <button *ngIf="rol.canByInput(rol.functIds.delete_terminals)" mat-stroke-button class="custom-white-button" (click)="onDelete()">
            {{ 'common.delete' | translate }}
        <img class="delete-icon" src="assets/img/delete_icon.svg" alt="Delete icon">
        </button>
    </div>
</main>