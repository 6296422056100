import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/auth/auth.service';
import { EventsService } from 'src/app/services/events.service';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { UtilService } from 'src/app/services/util.service';
import { NAVIGATION_SECTIONS, NavigationSectionType } from 'src/app/util/constants';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  isAdmin: boolean = false;
  public selectedAdminItem: string = '';

  constructor(
    private authService: AuthService,
    private utilService: UtilService,
    private events:EventsService,
    private router:Router,
    public hierarchy:HierarchyHandlerService,
    public rol:RolHandlerService
  ) {}


  ngOnInit(): void {
    this.isAdmin = this.utilService.isAdmin();
  
    this.events.navigationChange.subscribe((section:NavigationSectionType)=>{
      const sectionConfig = NAVIGATION_SECTIONS[section];
      this.marcarMenu(sectionConfig.button);
      this.router.navigateByUrl(sectionConfig.path);
      let element = document.getElementById(sectionConfig.collapse);
      element?.click();
    })
  }


  closeAllCollapse(){
    const collapseElementList = document.querySelectorAll('.collapse');
    collapseElementList.forEach((element) => element.classList.remove('show') );
    
    const togglerElementList = document.querySelectorAll('.nav-link');
    togglerElementList.forEach((element) => element.classList.add('collapsed') );
  }

  marcarMenu(id_Li:any){
    const testElements = document.getElementsByClassName('toc');
    const testDivs = Array.prototype.filter.call(testElements,
      (testElement) => testElement.checked = false,
    );

    const slectToc = document.getElementById(id_Li) as HTMLInputElement;    
    if(slectToc){
      slectToc.checked = true;
    }
    this.selectedAdminItem = id_Li
  }

  public checkMenuAccessShown(): boolean  {
    if (this.authService.hasValidAccessToken() && this.authService.hasValidIdToken()) {
      try {
        let tk = this.authService.getUserAccessToken();
        let permissions = tk!.auth!.permissions!['merchantsApi'];
        let permission : string = permissions['merchant'];
        permission += permissions['notification'];
        permission += permissions['terminal'];
        permission += permissions['transaction'];
        permission += permissions['organization'];
        return ((permission != undefined) && (permission.length > 0));
      } catch(err) {
        return false;
      }
    } else {
      return false;
    }
  }


  public checkMenuAccess(item:string): boolean  {
    
    if (this.authService.hasValidAccessToken() && this.authService.hasValidIdToken()) {
      try {        
        let tk = this.authService.getUserAccessToken();
        let permissions = tk!.auth!.permissions!['merchantsApi'];
        let permission : string = permissions[item];
        return ((permission != undefined) && (permission.length > 0));      
      } catch(err) {
        return false;
      }
    } else {
      return false;
    }
  }

  public checkUserAccessShown(): boolean  {
    if (this.authService.hasValidAccessToken() && this.authService.hasValidIdToken()) {
      try {
        let tk = this.authService.getUserAccessToken();
        let permissions = tk!.auth!.permissions!.authserver;
        let permission : string = permissions['user'];
        permission += permissions['role'];
        permission += permissions['group'];
        return ((permission != undefined) && (permission.length > 0));
      } catch(err) {
        return false;
      }
    } else {
      return false;
    }
  }

  public checkUserAccess(item:string): boolean  {
    if (this.authService.hasValidAccessToken() && this.authService.hasValidIdToken()) {
      try {
        let tk = this.authService.getUserAccessToken();
        let permissions = tk!.auth!.permissions!.authserver;
        let permission : string = permissions[item];
        return ((permission != undefined) && (permission.length > 0));
      } catch(err) {
        return false;
      }
    } else {
      return false;
    }
  }

}
