export const PERMISSIONS_DEFINITIONS = [
    {
      category: 'Monitorizacion',
      items: [
        { name: 'edit_terminals', label: 'roles.permissions.edit_terminalsg', icon: 'visibility' },
        { name: 'view_terminals', label: 'roles.permissions.view_terminals', icon: 'visibility' },
        { name: 'delete_terminals', label: 'roles.permissions.delete_terminals', icon: 'visibility' },
        { name: 'view_sells', label: 'roles.permissions.view_sells', icon: 'visibility' }
      ]
    },
    {
      category: 'Estadisticas',
      items: [
        { name: 'view_statistics', label: 'roles.permissions.view_statistics', icon: 'visibility' },
        { name: 'view_functionality', label: 'roles.permissions.view_functionality', icon: 'visibility' },
        { name: 'view_status', label: 'roles.permissions.view_status', icon: 'visibility' },
        { name: 'view_sensors', label: 'roles.permissions.view_sensors', icon: 'visibility' },
      ]
    },
    {
      category: 'Soporte',
      items: [
        { name: 'view_support', label: 'roles.permissions.view_support', icon: 'visibility' },
        { name: 'view_alerts', label: 'roles.permissions.view_alerts', icon: 'visibility' },
        { name: 'view_incidences', label: 'roles.permissions.view_incidences', icon: 'visibility' },
        { name: 'edit_incidences', label: 'roles.permissions.edit_incidences', icon: 'edit' }
      ]
    },
    {
      category: 'Configuracion',
      items: [
        { name: 'view_applications', label: 'roles.permissions.view_applications', icon: 'visibility' },
        { name: 'edit_applications', label: 'roles.permissions.edit_applications', icon: 'edit' },
        { name: 'delete_applications', label: 'roles.permissions.delete_applications', icon: 'delete' },
        { name: 'view_parameters', label: 'roles.permissions.view_parameters', icon: 'visibility' },
        { name: 'edit_parameters', label: 'roles.permissions.edit_parameters', icon: 'edit' },
        { name: 'delete_parameters', label: 'roles.permissions.delete_parameters', icon: 'delete' },
        { name: 'view_profiles', label: 'roles.permissions.view_profiles', icon: 'visibility' },
        { name: 'edit_profiles', label: 'roles.permissions.edit_profiles', icon: 'edit' },
        { name: 'delete_profiles', label: 'roles.permissions.delete_profiles', icon: 'delete' },
        { name: 'view_custom_alerts', label: 'roles.permissions.view_custom_alerts', icon: 'visibility' },
        { name: 'edit_custom_alerts', label: 'roles.permissions.edit_custom_alerts', icon: 'edit' },
        { name: 'delete_custom_alerts', label: 'roles.permissions.delete_custom_alerts', icon: 'delete' },
        { name: 'view_tags', label: 'roles.permissions.view_tags', icon: 'visibility' },
        { name: 'edit_tags', label: 'roles.permissions.edit_tags', icon: 'edit' },
        { name: 'delete_tags', label: 'roles.permissions.delete_tags', icon: 'delete' },
      ]
    },
    {
      category: 'Administrador',
      items: [
        { name: 'view_users', label: 'roles.permissions.view_users', icon: 'visibility' },
        { name: 'edit_users', label: 'roles.permissions.edit_users', icon: 'edit' },
        { name: 'delete_users', label: 'roles.permissions.delete_users', icon: 'delete' },
        { name: 'view_clients', label: 'roles.permissions.view_clients', icon: 'visibility' },
        { name: 'edit_clients', label: 'roles.permissions.edit_clients', icon: 'edit' },
        { name: 'delete_clients', label: 'roles.permissions.delete_clients', icon: 'delete' },
        { name: 'view_subclients', label: 'roles.permissions.view_subclients', icon: 'visibility' },
        { name: 'edit_subclients', label: 'roles.permissions.edit_subclients', icon: 'edit' },
        { name: 'delete_subclients', label: 'roles.permissions.delete_subclients', icon: 'delete' },
        { name: 'view_rols', label: 'roles.permissions.view_rols', icon: 'visibility' },
        { name: 'edit_rols', label: 'roles.permissions.edit_rols', icon: 'edit' },
        { name: 'delete_rols', label: 'roles.permissions.delete_rols', icon: 'delete' }
      ]
    },
    {
      category: 'Mantenimiento',
      items: [
        { name: 'send_file', label: 'roles.permissions.send_file', icon: 'visibility' },
        { name: 'get_file', label: 'roles.permissions.get_file', icon: 'visibility' },
        { name: 'view_file', label: 'roles.permissions.view_file', icon: 'visibility' },
        { name: 'delete_file', label: 'roles.permissions.delete_file', icon: 'visibility' },
        { name: 'commands', label: 'roles.permissions.commands', icon: 'visibility' },
        { name: 'pending_tasks', label: 'roles.permissions.pending_tasks', icon: 'visibility' },
        { name: 'edit_pending_tasks', label: 'roles.permissions.edit_pending_tasks', icon: 'edit' },
        { name: 'delete_pending_tasks', label: 'roles.permissions.delete_pending_tasks', icon: 'delete' },
        { name: 'history', label: 'roles.permissions.history', icon: 'visibility' }
      ]
    }
  ];



export const PERMISSIONS_RELATED = [
    { view: 'view_terminals', edit: 'edit_terminals', delete: 'delete_terminals' },
    { view: 'view_sells', edit: null, delete: null },
    { view: 'view_functionality', edit: null, delete: null },
    { view: 'view_sensors', edit: null, delete: null },
    { view: 'view_status', edit: null, delete: null },
    { view: 'view_alerts', edit: null, delete: null },
    { view: 'view_incidences', edit: 'edit_incidences', delete: null },
    { view: 'view_applications', edit: 'edit_applications', delete: 'delete_applications' },
    { view: 'view_parameters', edit: 'edit_parameters', delete: 'delete_parameters' },
    { view: 'view_profiles', edit: 'edit_profiles', delete: 'delete_profiles' },
    { view: 'view_file', edit: null, delete: 'delete_file' },
    { view: 'pending_tasks', edit: 'edit_pending_tasks', delete: 'delete_pending_tasks' },
    { view: 'view_users', edit: 'edit_users', delete: 'delete_users' },
    { view: 'view_clients', edit: 'edit_clients', delete: 'delete_clients' },
    { view: 'view_subclients', edit: 'edit_subclients', delete: 'delete_subclients' },
    { view: 'view_tags', edit: 'edit_tags', delete: 'delete_tags' },
    { view: 'view_rols', edit: 'edit_rols', delete: 'delete_rols' },
    { view: 'view_custom_alerts', edit: 'edit_custom_alerts', delete: 'delete_custom_alerts' }

  ];