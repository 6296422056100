import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { Incidence } from 'src/app/models/incidence.model';
import { RestService } from 'src/app/services/merchant/Rest.service';


@Injectable({
  providedIn: 'root',
})
export class IncidenceService {
  
  constructor(private merchantRest : RestService,private authService:AuthService) {}
  
  async find(filterAndPagBean:FilterAndPagBean ){

    let _body = filterAndPagBean;

    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getListIncidence/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }


  async countTotal( filterAndPagBean:FilterAndPagBean ){
         
    let _body = new FilterAndPagBean(filterAndPagBean?.orderSentido,filterAndPagBean?.order,filterAndPagBean?.filterGeneric,null,null,null);

    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getListIncidenceCount/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }
  


  async addUpdate(incidence:Incidence){

    let _body= incidence
         
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'addIncidence/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }


  async closeIncidence(incidence:Incidence){

    let _body= incidence
         
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'closeIncidence/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }

 
}