<main id="main" class="main">
    <div class="pagetitle">
        <h1>{{ 'roles.title' | translate }}</h1>
        <div>
            <button mat-raised-button class="custom-white-button" (click)="refrescar()">
                <i class="bi bi-arrow-clockwise"></i> {{ 'common.refresh' | translate }}
            </button>
            <ng-container *ngIf="rol.canByInput(rol.functIds.edit_rols)">
                <button mat-raised-button class="custom-add-button" (click)="onNew()">
                    <i class="bi bi-plus"></i> {{ 'roles.add' | translate }}
                </button>
            </ng-container>
        </div>
    </div>

    <div id="tableDescription" hidden>{{ 'roles.tableDescription' | translate }}</div>

    <div class="mat-elevation-z2 tablaPersonal">
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
            aria-describedby="tableDescription" matTableExporter #exporter="matTableExporter">

            <!-- Columna Name -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header>{{ 'roles.name' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>


            <!-- description Column -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>
                    <div  mat-sort-header>{{ 'roles.description' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.description || ' ' }} </td>
            </ng-container>

            <!-- idOperator Column -->
            <ng-container matColumnDef="idOperator">
                <th mat-header-cell *matHeaderCellDef>
                    <div>{{ 'hierarchy.level_1' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.idOperator |  levelName:'LEVEL_1' }} </td>
            </ng-container>

            <!-- actions Column -->
            <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef style="width: 90px;"></th>
                <td mat-cell *matCellDef="let element" class="button" style="width: 90px;">
                    <div *ngIf="rol.canByInput(rol.functIds.edit_rols)">
                        <button matTooltip="{{ 'common.edit' | translate }}" (click)="onEdit(element)" mat-icon-button class="iconoTabla">
                            <mat-icon>chevron_right</mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <!-- Empty state -->
        <ng-container *ngIf="!dataSource.data.length">
            <app-table-empty-state></app-table-empty-state>
        </ng-container>
        <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent()" showFirstLastButtons aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>
</main>