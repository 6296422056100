import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { ConsoleRequest } from 'src/app/models/console.request.model';
import { FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { Rol } from 'src/app/models/rol.model';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { CommonService } from 'src/app/services/common.service';
import { RestService } from 'src/app/services/merchant/Rest.service';

@Injectable({
  providedIn: 'root',
})
export class RolService {
  constructor(
    private merchantRest: RestService,
    public commonService: CommonService
  ) {}

  async find(filterAndPagBean: FilterAndPagBean) {
    // Llamada a la API para obtener la lista de roles
    let value = await this.merchantRest.commonRestCallConsolaRest({ filterAndPagBean }, 'getListRol/', true, true)
      .catch(err => null);

    return value;
  }

  async countTotal(filterAndPagBean: FilterAndPagBean) {
    let value = await this.merchantRest.commonRestCallConsolaRest({ filterAndPagBean }, 'countListRol/', true, true)
      .catch(err => null);

    return value;
  }

  async addRol(rol: Rol) {
    let value = await this.merchantRest.commonRestCallConsolaRest(rol, 'addRol/', true, true)
      .catch(err => null);

    return value;
  }

  async updateRol(rol: Rol) {
    const value = await this.merchantRest.commonRestCallConsolaRest(rol, 'addRol/', true, true)
      .catch(err => null);
  
    return value;
  }

  async deleteRol(rolId: number) {
    const body = { id: rolId };  
    let value = await this.merchantRest.commonRestCallConsolaRest(body, 'deleteRol/', true, true)
      .catch(err => null);

    return value;
  }
}
