<div class="pagetitle">
  <h1>{{ profileName }}</h1>
</div>

<div class="section-content">
  <img [src]="qrCodeUrl" alt="QR Code" loading="lazy"/>
  <p> {{ 'profiles.code' | translate }}: {{ code }}</p>
</div>

<div class="section-footer">
  <button mat-stroke-button class="custom-white-button" mat-dialog-close>{{ 'profiles.enrollment.close' | translate }}</button>
</div>

