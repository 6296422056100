import { FilterAndPagBean } from "./FilterAndPagBean";

export class Pending {

    id!: number;
    dateCreate: Date | undefined;
    idOperator: number | undefined;
	idClient: number | undefined;
	idEntorno: number | undefined;
    sendJson: string | undefined;
    retries: number | undefined;

	filterAndPagBean: FilterAndPagBean | undefined;

	idVM: number | undefined;
	typeSend: string | undefined;
	idUser: number | undefined;
	codError: number | undefined;
	idFile: number | undefined;
	idOriginal: number | undefined;
	dateIni: string | undefined;
	horaDuration: string | undefined;
	setMachine:boolean = false;

	lastBlock: number | undefined;
	numBlocks: number | undefined;
	dateLastBlock: Date | undefined;
	
	serialNumber: string | undefined;

	
	constructor(values: Object = {}) {
		Object.assign(this, values);
	}

	get packegeName():string{
		if(!this.sendJson) return '-';
		const parsedJson = JSON.parse(this.sendJson);
		const pending = Array.isArray(parsedJson) ? parsedJson[0] : parsedJson;
		const packageName = pending?.packageName ?? pending?.pakage; 
		return `${ packageName ?? '-' }`;
	}

	get commandParams(): string {
		if(!this.sendJson) return '-';
		const parsedJson = JSON.parse(this.sendJson);
		const pending = parsedJson[0];
		const packageName = pending?.packageName ?? pending?.pakage;
		const command = pending?.command;	 
		return `${command} ${packageName ?? ''}`;
	}

	get pendingConfig():string{
		if(this.typeSend === PENDING_TASKS_TYPES.CONFIG) return this.commandParams;
		return this.packegeName;
	}

	get percentage():number{
		let val = 0
		if (this.lastBlock && this.numBlocks) {
		  val = this.lastBlock * 100 / this.numBlocks
		}
		return Math.floor(val);
	  }
	
}


export enum PENDING_TASKS_TYPES {
	FILE_APP = "1",
	DISCOUNT = "2",
	RESET = "3",
	AUDITPERIOD = "4",
	GETDEX = "5",
	PROFILE = "6",
	CONFIG = "7",
	VEND = "8",
	GETFILE = "9",
	FILE_PARAM = "10",
	FILE_WEB = "11",
	CONFIG_PINPAD = "25",
	CAMPANA_PINPAD = "50"
  }
  
  export type PendingTasksTypes = typeof PENDING_TASKS_TYPES;