import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { RolHandlerService } from 'src/app/services/rol-handler.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements OnInit {
  selectedTabIndex: number = 0; // o el índice que necesites

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { terminalId:number, terminalName:string, statusClass:string, statusLabel:string }, 
    private dialogRef: MatLegacyDialogRef<TabsComponent>, 
    public rol:RolHandlerService,
    public translate: TranslateService,
    private cdr: ChangeDetectorRef

  ){}

  ngOnInit(): void {    this.selectedTabIndex = 0; 
    console.log('Data recibido por TabsComponent:', this.data);
  }

  ngAfterViewInit(): void {
    this.selectedTabIndex = 0;  // Seleccionar la primera pestaña
  }

  changeTab(event: any) {
    console.log('Tab seleccionado:', event);
  }

  closeScreen(): void {
    this.dialogRef.close();
  }

}
