import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator, MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog, MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { ListFile, ListFileExtended, ListFileRequest } from 'src/app/models/list-file.models';
import { ListFilesService } from './list-files.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { NotifierService } from 'angular-notifier';
import { downloadFileFromBase64 } from 'src/app/util/util';
import { AppliedFilter, FieldType, FilterField } from 'src/app/models/filter.model';
import { FilterableValue, FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { environment } from 'src/environments/environment';
import { DateOnTimeZonePipe } from 'src/app/pages/comun/pipes/date-on-timezone.pipe';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { TableEmptyStateComponent } from 'src/app/components/table-empty-state/table-empty-state.component';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { RolHandlerService } from 'src/app/services/rol-handler.service';

enum Columns {
  name = 'name',
  pakage = 'pakage',
  mode = 'mode',
  dateCreate = 'dateCreate',
  view = 'view'
}

const FILTER_COLUMNS:{ field:string, type: FieldType }[] = [
  { field:'name', type:'string' },
  { field:'dateCreate', type:'date' },
]

@Component({
  selector: 'app-list-files',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatDialogModule,
    MatTableExporterModule,
    MatPaginatorModule,
    MatSortModule,
    MatLegacyButtonModule,
    TranslateModule,
    FilterComponent,
    DateOnTimeZonePipe,
    TableEmptyStateComponent,
    MatIconModule,
    MatLegacyTooltipModule
  ],
  templateUrl: './list-files.component.html',
  styleUrls: ['./list-files.component.css'],
  providers:[{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})
export class ListFilesComponent implements OnInit, AfterViewInit {
  @Input('data') vendingMachine!:VendingMachineAtento;

  displayedColumns =  Object.keys(Columns);
  dataSource = new MatTableDataSource<ListFile>();
  exportFileName: string | undefined;
  filterColumns = FILTER_COLUMNS.map((filter)=>{
    return new FilterField(filter.field, `listFiles.${filter.field}`, filter.type);
  })
  filterList: FilterableValue[] = [];
  
  pageLength=0;
  pageSize=environment.defaultPaginacion;
  pageSizeOptions=environment.paginacion;
  sortId:string | null = Columns.dateCreate;
  direccion:string | null ="DESC";
   
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  
  constructor(private service:ListFilesService,
    private dialog:MatDialog,
    private notifier:NotifierService,
    private translate:TranslateService,
    public rol:RolHandlerService
  ){}

  public ngOnInit(): void {
    this.lanzarLLamada();
    this.exportFileName = this.translate.instant('export.fileList');
  }

  public ngAfterViewInit(): void {
    this.dataSource.paginator=this.paginator;
    this.dataSource.sort=this.sort;
  }

  async lanzarLLamada(){
    const query:ListFileRequest = new ListFileRequest;
    if(this.vendingMachine) query.idVM = this.vendingMachine.id as number;
    query.filterAndPagBean = this.getFilterAndPagBean();

    let value = await this.service.find(query);
    let pagCount = await this.service.count(query);
    if(value){
      this.dataSource = new MatTableDataSource<ListFile>(value.data);
      if(pagCount) this.pageLength = pagCount.data?.total ?? 0;
    }
  }

  private getFilterAndPagBean():FilterAndPagBean{
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize:this.pageSize };
    const startPage = pageIndex*pageSize;
    const endPage = startPage+pageSize;
    return new FilterAndPagBean(
        this.direccion, 
        this.sortId, 
        this.filterList, 
        startPage,
        endPage,
        pageIndex
      );
}

  public async onDownload(item:ListFile): Promise<void> {
    let result = await this.service.findById(item.id);
    if (result!.status >= 0) this.download(result?.data);
  }

  private download(item:ListFileExtended){
    downloadFileFromBase64(item.name, item.base64);
  }
 
  public onDelete(item:ListFile): void {
    const dialogData = new ConfirmDialogData;
    dialogData.titleI18n = "Borrar";
    dialogData.textI18n = "¿Está seguro de borrar el archivo? Esta operación no se puede deshacer.";
    dialogData.svgIcon = 'assets/img/delete_icon_dialog.svg'; 
    dialogData.isDeleteAction = true; 

    const deleteDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', 
      panelClass: 'custom-modalbox',
      data: dialogData
    });

    deleteDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) this.delete(item);
    });
  }

  private async delete(item:ListFile){
    let result = await this.service.delete(item.id)
    if (result!.status >= 0) {
      this.notifier.notify('success', 'Custom Alert deleted successfully');
      this.lanzarLLamada();
    }
  }

  public onFilterApply(appliedFilters:AppliedFilter[]){
      this.filterList = [];
      this.filterList = appliedFilters.map((appliedFilter)=>{
        const filter = appliedFilter.filter;
        return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
      });
      this.paginator.firstPage();
      this.lanzarLLamada();
  }

  public pageEvent(){
      this.lanzarLLamada();
  }

  public announceSortChange(sortState: Sort) {
      console.log("ordenar="+JSON.stringify(sortState)) 
      let active = sortState.active.replace("_",".")
      this.sortId=active;
    
      if(sortState.direction){
        this.direccion=sortState.direction.toUpperCase()
      }else{
        this.direccion=null
        this.sortId=null
      }
      this.paginator.firstPage();
      this.lanzarLLamada();
  }

  public refrescar() {
      this.lanzarLLamada();
  }

  getNotExportColumns(){
    const actionsColIdx = (this.displayedColumns.length-1);
    return [actionsColIdx];
  }
}
