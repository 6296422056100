import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'; // Usando ngx-translate para las traducciones

@Pipe({
  name: 'operationPipe'
})
export class OperationPipe implements PipeTransform {

  constructor(private translate: TranslateService) {}

  transform(value: string): unknown {
    if (value === null || value === undefined) {
      return this.translate.instant('common.undefined'); 
    }

    // Traducir usando el valor numérico del enum
    return this.translate.instant(`historico.operation.${value}`);
  }
}
