import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NotifierService } from 'angular-notifier';
import { Aplications } from 'src/app/models/aplications.model';
import { AppsService } from '../aplications.service';
import { BooleanInput } from '@angular/cdk/coercion';
import { FirmwareUpload } from 'src/app/models/firmwareUpload.model';
import { FormCreateActions } from 'src/app/util/constants';
import { FilterableValue, FilterAndPagBean, OPERATION_FILTER, TYPE_FILTER } from 'src/app/models/FilterAndPagBean';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { convertFileToBase64, downloadFileFromBase64, extractVersion } from 'src/app/util/util';
import { TranslateService } from '@ngx-translate/core';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { Tag } from '../../tags/tag.model';
import { Factories, TerminalModel } from 'src/app/models/factories.model';
import { FactoriesService } from 'src/app/services/factories.service';

@Component({
  selector: 'app-edit-app',
  templateUrl: './edit-app.component.html',
  styleUrls: ['./edit-app.component.css']
})
export class EditAppComponent implements OnInit {
  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  isLinear: BooleanInput;
  selectedFile: File | null = null;
  isFileSelected: boolean | undefined;
  versions: Aplications[] = [];
  filterList: FilterableValue[] = [];
  isAddingVersion = false;
  app: Aplications = new Aplications;

  isOpen: { [key: string]: boolean } = {
    details: true,
    hierarchy:true,
    dates: true,
    versiones: true,
  };
  confirmDialogRef: any;
  readOnly: boolean = false;

  factories?: Factories;
  terminalModels: TerminalModel[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditAppComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { app: Aplications, readOnly: boolean },
    private appService: AppsService,
    public rol: RolHandlerService,
    private translate: TranslateService,
    private factoriesService:FactoriesService
  ) {
    if (data?.app) {
      this.app = data.app; // Inicializa 'app' directamente si está presente
    }
  }


  private async loadFactoriesData(){
    const response = await this.factoriesService.getFactoriesList();
    if(response && response.data){
      this.factories = new Factories(response.data);
    }
  }

  ngOnInit(): void {
    this.loadFactoriesData();
    
    this.readOnly = !!this.data.readOnly;
    // Establece un valor inicial a 'app' para evitar errores
    this.app = this.data?.app || new Aplications();
    // Primer formulario para los datos de la aplicación
    this.firstFormGroup = this.formBuilder.group({
      name: [{ value: '', disabled: this.readOnly }, [Validators.required,Validators.maxLength(50)]],
      packageName: [{ value: '', disabled: true }, Validators.required],
      dateCreate: [{ value: '', disabled: true }],
      dateUpdate: [{ value: '', disabled: true }],
      hierarchy:[{ value:{ idOperator:null, idClient:null, idSubClient:null }, disabled:true}],
      tags: [{ value: [], disabled: this.readOnly }]
    });

    // Segundo formulario para gestionar los archivos y la versión
    this.secondFormGroup = this.formBuilder.group({
      uploadFiles: [null, Validators.required],
      fileBase64: [''],
      version: ['', [Validators.required,Validators.maxLength(25)]],
      description: ['', [Validators.required, Validators.maxLength(200)]], // Campo de descripción con validaciones
      manufacID: [null, Validators.required],
      modelNumber: [null],
    });

    this.secondFormGroup.get('manufacID')?.valueChanges.subscribe((value)=>{
      this.terminalModels = this.factories ? this.factories.getModelList(value) : [];
    });


    if (!this.data) return;
    this.lanzarLlamada();
  }

  lanzarLlamada(){
    // Verifica que los datos estén disponibles
    const hasPackage = !!this.data.app.pakage;
    if (hasPackage) {
    this.loadFullVersionsData();
    } else {
    this.loadVersionData();
    }
  }

  async loadFullVersionsData() {
    const { pakage } = this.data.app;
    const packageFilter = new FilterableValue("pakage", pakage, TYPE_FILTER.String, OPERATION_FILTER.EQUALS);
    const request = new FilterAndPagBean("ASC","version", [packageFilter], 0, 0, 0);
    const { id } = this.data.app; 
    // Llama al servicio para obtener los datos del backend
    const response = await this.appService.find(request, id)
    if (response && response.data?.length) {
      const dataAppVersion: Aplications[] = response.data;
      const lastApp = dataAppVersion.find((app) => app.isLast);
      this.loadFormData(lastApp ? lastApp : dataAppVersion[0]);
      this.versions = [...dataAppVersion];
    }
  }

  async loadVersionData() {
    const { id } = this.data.app;
    const response = await this.appService.get(id);
    if (response && response.data) {
      const loadedApp = response.data;
      this.loadFormData(loadedApp);
      this.versions = [...[loadedApp]];
    }
  }
  
  // Método para cargar los datos en el formulario
  loadFormData(data: Aplications): void {
    this.app = data; // Actualiza 'app' con los datos cargados
    this.firstFormGroup.patchValue({
      name: data.name,
      packageName: data.pakage,
      dateCreate: data.dateCreate,
      dateUpdate: data.dateUpdate,
      tags: data.listEtiquetas ?? [],
      hierarchy: { idOperator: data.idOperator, idClient: data.idClient, idSubClient: data.idSubClient }
    });
    
    this.firstFormGroup.markAsDirty();
  }



  onSave(): void {
    // Valido que el primer formulario sea válido, 
    if (!this.firstFormGroup.valid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return
    }

    // Crea el objeto FirmwareUpload para enviar al backend
    let objNew: FirmwareUpload = new FirmwareUpload();
    objNew.id = this.app.id;

    // Extraemos los valores de ambos formularios
    const { name, tags } = this.firstFormGroup.value;

    // Creamos el objeto Aplications combinando los datos de ambos formularios
    const updateApp: Aplications = new Aplications();
    updateApp.name = name;
    updateApp.description = this.app.description;
    updateApp.dateCreate = this.app.dateCreate;
    updateApp.pakage = this.app.pakage.trim();
    updateApp.version = this.app.version;
    updateApp.base64 = this.app.base64;
    updateApp.fileName = this.app.fileName;
    updateApp.listEtiquetas = (tags.length) ? tags.map((tag: Tag) => ({ id: tag.id })) : [];
    
    updateApp.manufacID = this.app.manufacID;
    updateApp.modelNumber = this.app.modelNumber;
    
    // valores originales para Operador y Cliente
    updateApp.idOperator = this.app.idOperator;
    updateApp.idClient = this.app.idClient;
    updateApp.idSubClient = this.app.idSubClient;

    // Asegúrate de que la lista de aplicaciones contenga la versión correcta y otros datos
    let udpateListApps: Aplications[] = [updateApp];
    objNew.fileList = udpateListApps;

    // Llama a la función para guardar en el backend
    this.saveToBackend(objNew);
  }

  async saveToBackend(objNew: FirmwareUpload): Promise<void> {
    const response = await this.appService.addUpdate(objNew)
    if(response && response.status === 0){
      this.notifier.notify('success', this.translate.instant('success.appSaved'));
      this.dialogRef.close(FormCreateActions.SAVED);
    }
  }

  onDelete(): void {
    const { id } =  this.app;
    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = this.translate.instant('confirmDialog.deleteAppTitle'); // "Delete App"
    dialogData.textI18n = this.translate.instant('confirmDialog.deleteAppText'); // "Are you sure you want to delete this app?"
    dialogData.svgIcon = 'assets/img/delete_icon_dialog.svg';
    dialogData.isDeleteAction = true;

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%',
      panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        const response = await this.appService.delete(id);
        if (response?.status === 0) {
          this.notifier.notify('success', this.translate.instant('success.appDeleted'));
          this.dialogRef.close(FormCreateActions.SAVED)
        }
      }
    });
  }

  /*
   * Logica para agregar/modificar versiones de la aplicacion
   */
 
  startNewVersion(event:Event) {
    event.preventDefault();
    event.stopPropagation();
    this.isAddingVersion = true;
    this.secondFormGroup.reset(); // Reiniciar el formulario
    this.selectedFile = null; // Limpiar el archivo seleccionado
    this.isFileSelected = false; // Indicar que no hay archivo seleccionado
  }

  cancelNewVersion(){
    this.isAddingVersion = false;
    this.secondFormGroup.reset(); // Reiniciar el formulario
    this.selectedFile = null; // Limpiar el archivo seleccionado
    this.isFileSelected = false; // Indicar que no hay archivo seleccionado
  }

  resetFileSelection(){
    this.secondFormGroup.reset(); // Reiniciar el formulario
    this.selectedFile = null; // Limpiar el archivo seleccionado
    this.isFileSelected = false; // Indicar que no hay archivo seleccionado
  }

  // Método que maneja la subida y confirmación de la nueva versión
  async onNewVersion(): Promise<void> {
    // Validar si el formulario es válido
    if (this.secondFormGroup.invalid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }

    // Verificar que haya un archivo y que cumpla con los requisitos de nombre
    const { fileBase64, version, description, manufacID, modelNumber } = this.secondFormGroup.value;

    if (this.isVersionDuplicated(version, manufacID, modelNumber)) {
      this.notifier.notify('warning', this.translate.instant('warning.duplicateVersion'));
      return;
    }

    if (!fileBase64 || !this.selectedFile) {
      this.notifier.notify('warning', this.translate.instant('warning.fileUploadValidation'));
      return;
    }

    // Crear el objeto de aplicación (Aplications) basado en la nueva versión
    const newVersion: Aplications = new Aplications;
    newVersion.version = version.trim();
    newVersion.description = description;
    newVersion.manufacID = manufacID;
    newVersion.modelNumber = modelNumber;
    newVersion.base64 = fileBase64;
    newVersion.fileName = this.selectedFile ? this.selectedFile.name : '';
    
    // Asigno parámetros de la aplicación global
    const { name, pakage, listEtiquetas, idOperator, idClient, idSubClient } = this.app;
    newVersion.name = name;
    newVersion.pakage = pakage.trim();
    newVersion.listEtiquetas = listEtiquetas;
    newVersion.idOperator = idOperator;
    newVersion.idClient = idClient;
    newVersion.idSubClient = idSubClient;

    // Crear un objeto FirmwareUpload para hacer la actualización
    let objNew: FirmwareUpload = new FirmwareUpload();
    objNew.fileList = [newVersion]; // Añadimos la nueva versión a la lista de archivos

    // Actualizamos en el backend la nueva versión
    const response = await this.appService.addUpdate(objNew);

    if(response && response.status === 1){
      // Actualizar dataSource para reflejar el cambio
      this.notifier.notify('success', this.translate.instant('success.versionSaved'));
      // Reiniciar los estados y limpiar el formulario
      this.isAddingVersion = false;
      this.selectedFile = null; 
      this.lanzarLlamada();
    }
      
  }
  

  removeVersion(id: number): void {
    if (this.versions.length <= 1) {
      this.notifier.hideNewest();
      this.notifier.notify('warning', this.translate.instant('warning.singleVersionRequired'));
      return;
    }

    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = this.translate.instant('confirmDialog.deleteVersionTitle');
    dialogData.textI18n = this.translate.instant('confirmDialog.deleteVersionText');
    dialogData.svgIcon = 'assets/img/delete_icon_dialog.svg';
    dialogData.isDeleteAction = true;

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%',
      panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        const response = await this.appService.deleteVersion(id);
        if (response?.status === 0) { // Verificar el estado de respuesta
          this.lanzarLlamada();
          this.notifier.notify('success', this.translate.instant('success.versionDeleted'));
        }
      }
    });
  }

  mapFileToBase64(file: File): void {
    convertFileToBase64(file).then((base64String) => {
      if (!base64String) {
        this.notifier.notify('warning', this.translate.instant('error.fileEmpty'));
        return;
      }

      this.selectedFile = file;
      this.secondFormGroup.patchValue({
        uploadFiles: this.selectedFile,
        fileBase64: base64String,
        version:  extractVersion(file.name) // Extraer versión del nombre del archivo
      });
      this.secondFormGroup.get('version')?.enable(); // Habilitar el campo de versión
      this.isFileSelected = true;
    }).catch(() => {
      this.notifier.notify('warning', this.translate.instant('error.fileInvalid'));
    });
  }
  
  // Método auxiliar para verificar si la versión ya existe
  private isVersionDuplicated(version:string, manufacID:string, modelNumber:string): boolean {
    return this.versions.some(item => (item.version === version && 
      item.manufacID === manufacID && 
      item.modelNumber === modelNumber 
    ));
  }
  
  // Método que se llama cuando se selecciona un archivo
  async onFileSelected(files: File[]): Promise<void> {
    if (files.length > 0) {
      const file = files[0];
      //  mapear el archivo a Base64 si la versión es válida y no está duplicada
      this.mapFileToBase64(file);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
  
  toggleSection(section: string): void {
    this.isOpen[section] = !this.isOpen[section];
  }

  async downloadVersion(version: Aplications): Promise<void> {
    if (version.base64) {
      downloadFileFromBase64(version.fileName, version.base64);
    } else {
      const response = await this.appService.get(version.id);
      if (response && response.data) {
        const versionApp = response.data;
        downloadFileFromBase64(versionApp.fileName, versionApp.base64);
      } else {
        this.notifier.notify('error', this.translate.instant('error.errorOnDownload'));
      }
    }
  }

}
