import { Aplications } from "./aplications.model";
import { Parameters } from "./parameters.model";

export enum ParamExtraFiles {
    ZIP = 'ZIP',
    LIST = 'LIST',
    CSV = 'CSV'
}

export class PerfilApp {
    idApp!: number;
    idParam!: number | null;
    paramExtra!: string;
    typeParamExtra: ParamExtraFiles | null = null;
    appPakage!:string;
    dateStart:string | null =  null;
    //Fields for presentation
    appName?:string;
    appVersion?: string;
    paramName?:string;
    paramVersion?:string;
    fileName?: string;
	manufacID?: string;
	modelNumber?: string;

    map(perfilApp:PerfilApp){
        this.idApp = perfilApp.idApp;
        this.idParam = perfilApp.idParam;
        this.paramExtra = perfilApp.paramExtra;
        this.typeParamExtra = perfilApp.typeParamExtra;
        this.appPakage = perfilApp.appPakage;
        this.dateStart = perfilApp.dateStart;
        this.appName = perfilApp.appName;
        this.appVersion = perfilApp.appVersion;
        this.paramName = perfilApp.paramName;
        this.paramVersion = perfilApp.paramVersion;
        this.fileName = perfilApp.fileName;
        this.manufacID = perfilApp?.manufacID;
        this.modelNumber = perfilApp?.modelNumber;
    }

    
    mapFromServer(fromServer:PerfilApp) {
        this.idApp = fromServer.idApp;
        this.idParam = fromServer.idParam;
        this.paramExtra = fromServer.paramExtra;
        this.typeParamExtra = fromServer.typeParamExtra ?? (fromServer.paramExtra ? ParamExtraFiles.LIST : null) ;
        this.appPakage = fromServer.appPakage;
        this.dateStart = fromServer.dateStart;
    }
   
    mapToServer(): PerfilApp {
        return <PerfilApp>{
            idApp : this.idApp,
            idParam : this.idParam,
            paramExtra : this.paramExtra,
            typeParamExtra : this.typeParamExtra,
            appPakage : this.appPakage,
            dateStart : this.dateStart
        }
    }

    mapToPresentation(perfilApp:PerfilApp, app?:Aplications, param?:Parameters){
        this.idApp = perfilApp.idApp;
        this.appName = app?.name ?? '';
        this.appVersion = app?.version ?? '';
        this.idParam = perfilApp.idParam;
        this.paramName = param?.name;
        this.paramVersion = param?.version;   
        this.paramExtra = perfilApp.paramExtra;
        this.typeParamExtra = perfilApp.typeParamExtra;
        this.appPakage = app?.pakage ?? '';
        this.dateStart = perfilApp.dateStart;
        this.fileName = perfilApp.paramExtra ? this.paramExtraFile : '';
        this.manufacID = app?.manufacID;
        this.modelNumber = app?.modelNumber;
    }

    get paramExtraFile(){
        if(!this.paramExtra) return '';
        const parsedArray = <ParamExtra[]>JSON.parse(this.paramExtra);
        const parsedHeader = <FileData>JSON.parse(parsedArray[0].headers);
        return parsedHeader.fileName;
    }
}


interface FileData {
    fileName:string;
    storageType:string;
    path:string;
}

interface ParamExtra {
    id:number;
    idPerfil:number;
    idApp:number;
    numSerie:number;
    json: { [key:string]:string },
    usado:boolean
    headers:string;
}
