import { OnInit, Component, Inject} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CommonService } from 'src/app/services/common.service';
import { FilterableValue, FilterAndPagBean, OPERATION_FILTER, TYPE_FILTER } from 'src/app/models/FilterAndPagBean';
import { MonitoringService } from '../../views/monitoring/monitoring.service';
import { CustosDualListComponent } from '../custos-dual-list/custos-dual-list.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { FormCreateActions } from 'src/app/util/constants';
import { AppliedFilter, FieldType, FilterField } from 'src/app/models/filter.model';
import { CommonModule } from '@angular/common';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { HierarchyInput } from 'src/app/components/hierarchy-selector/hierarchy-selector.component';

const FILTER_COLUMNS:{ field:string, label:string, type: FieldType }[] = [
  { field:'serialNumber', type:'string', label:'vendingMachine.serialNumber' },
  { field:'modelNumber', type:'string', label:'vendingMachine.model' },
  { field: 'manufacID', type: 'string',  label:'vendingMachine.manufacID'}
  ]

@Component({
  standalone:true,
  imports:[CommonModule,FilterComponent, CustosDualListComponent, TranslateModule, MatLegacyButtonModule],
  selector: 'app-select-machine-window',
  templateUrl: './select-machine-window.component.html',
  styleUrls: ['./select-machine-window.component.css']
})
export class SelectMachineWindowComponent implements OnInit {
  keepSorted = true;
  key: string ='id';
  display: string = 'displayName';
  filter = true;
  source: Array<any> = [];
  confirmed: Array<any> = [];
  userAdd = '';
  disabled = false;
  sourceLeft = true;
  format = { add: "Add", remove: 'Remove', all: 'All', none: 'None', 
            direction: CustosDualListComponent.LTR, draggable: true, locale: undefined };

  filterColumns:FilterField[] = FILTER_COLUMNS.map((column)=>{
    const fielterField = new FilterField(column.field, column.label, column.type);
    return fielterField;
  })

  
  filterList: FilterableValue[] = [];
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: { hierarchy: HierarchyInput },
    private servicios : MonitoringService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SelectMachineWindowComponent>,
    public commonService: CommonService,
    private translate:TranslateService,
    private hierarchy:HierarchyHandlerService
  ) {}

  async lanzarLLamada(){
    const request = new UsersConsoleRequest();
    this.filterList.push.apply(this.filterList, this.hierarchy.getFilterableValues(this.data.hierarchy));
    request.filterAndPagBean = new FilterAndPagBean("DESC", 'serialNumber', this.filterList,  null,  null, null);
    let response = await this.servicios.find(request);
    if(response){
      this.source = response.data.map((terminal:VendingMachineAtento)=>{
        return {...terminal, displayName: `${terminal.serialNumber} - ${terminal.manufacID} - ${terminal.modelNumber}`}
      });     
    }
    this.confirmed = new Array<any>();
  }

  
  async ngOnInit(): Promise<void> {
    if(!this.data.hierarchy) this.filterColumns.push.apply(this.filterColumns, this.hierarchy.getFilterFields());
    this.lanzarLLamada();
  }

  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter) => {
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.lanzarLLamada();
  }

  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close({ result: FormCreateActions.EXIT});
          
  }

  onFormSubmit(): void { 
    const dialogData = new ConfirmDialogData(); 
    dialogData.titleI18n = this.translate.instant('confirmDialog.selectMachineTitle');
    dialogData.textI18n = this.translate.instant('confirmDialog.selectMachineText');
    dialogData.svgIcon = 'assets/img/confirm._icon.svg';

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        this.dialogRef.close({ data: this.confirmed , result: FormCreateActions.SAVED});  
      }
    });
  }

}

