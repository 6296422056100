import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { FilterAndPagBean, FilterableValue } from 'src/app/models/FilterAndPagBean';
import { UtilService } from 'src/app/services/util.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { VentasService } from './ventas.service';
import { MonitoringService } from '../../monitoring.service';
import { Ventas } from 'src/app/models/ventas.model';
import { AppliedFilter, FieldType, FilterField, FilterOption } from 'src/app/models/filter.model';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { TranslateService } from '@ngx-translate/core';

const Columns = {
  dateCreate: 'dateCreate',
  numProduct: 'numProduct',
  nameProduct: 'nameProduct',
  opType: 'opType',
  valueVend: 'valueVend',
  typePeripheral: 'typePeripheral',
  discountValue: 'discountValue',
  numHostAutori: 'numHostAutori',
  numHostOper: 'numHostOper',
  status: 'status',
  pan: 'pan'
}

const ColumnsAdmin = { ...Columns };

const PERIPHERAL_OPTIONS:FilterOption[] = [
  { value:"CA", label: "Efectivo" },
  { value:"DA", label: "Tarjeta" },
  { value:"DB", label: "Tarjeta Privada" },
]

const DISCOUNT_VALUE_OPTIONS:FilterOption[] = [
  { value:"0", label: "INDEFINIDO" },
  { value:"1", label: "NORMAL" },
  { value:"2", label: "DISCOUNTS" },
  { value:"3", label: "SURCHARGED" },
  { value:"4", label: "FREE" },
]

const OP_TYPE_OPTIONS:FilterOption[] = [
  { value:"0", label: "INDEFINIDO" },
  { value:"1", label: "CLESS_TARJETA_EMV" },
  { value:"2", label: "CLESS_MOVIL_EMV" },
  { value:"3", label: "CLESS_TARJETA_BANDA" },
  { value:"4", label: "CLESS_MOVIL_BANDA" },
  { value:"5", label: "TARJETA_EMV" },
  { value:"6", label: "TARJETA_BANDA" },
]

const STATUS_OPTIONS:FilterOption[] = [
  { value:"0", label: "INDEFINIDO" },
  { value:"1", label: "ACEPTADA" },
  { value:"2", label: "DENEGADA" },
  { value:"3", label: "CANCELADA" },
  { value:"4", label: "ANULADA" },
  { value:"5", label: "HOST_NO_RESPONDE" },
  { value:"6", label: "HOST_ERROR" },
  { value:"7", label: "ERROR_DISPENSACION" },
  { value:"11", label: "DEVOLUCION" },
  { value:"13", label: "TIMEOUT" },
]


const FILTER_COLUMNS:{ field:string, type: FieldType, options?:FilterOption[]}[] = [
  { field:'numHostOper', type:'number' },
  { field:'numHostAutori', type:'string' },
  { field:'numProduct', type:'string' },
  { field:'nameProduct', type:'string' },
  { field:'pan', type:'string' },
  { field:'valueVend', type:'number' },
  { field:'dateCreate', type:'date' },
  { field:'status', type:'number', options:STATUS_OPTIONS },
  { field:'typePeripheral', type:'string', options:PERIPHERAL_OPTIONS },
  { field:'discountValue', type:'number', options:DISCOUNT_VALUE_OPTIONS },
  { field:'opType', type:'string', options:OP_TYPE_OPTIONS }
]

@Component({
  selector: 'app-ventas',
  templateUrl: './ventas.component.html',
  styleUrls: ['./ventas.component.css'],
  providers:[{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})
export class VentasComponent implements OnInit, AfterViewInit {
  displayedColumns = Object.keys(Columns);
  dataSource = new MatTableDataSource<Ventas>();
  searchTerminal = "";
  isAdmin: boolean = true;
  pageLength = 0;
  pageSize = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  sortId: string | null = Columns.dateCreate + "";
  direccion: string | null = "DESC";
  exportFileName: string | undefined;

  public filterColumns:FilterField[] = FILTER_COLUMNS.map((column)=>{
    const fielterField = new FilterField(column.field, `sells.${column.field}`, column.type, column.options);
    return fielterField;
  })

  filterList: FilterableValue[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { terminalId:number },
    private servicios: VentasService,
    private utilService: UtilService,
    public dialog: MatDialog, 
    public commonService: CommonService,
    private translate:TranslateService,
    private monitoringService: MonitoringService) {

  }

  private getFilterableValue(column:string): FilterableValue | undefined {
    return this.filterList.find((filter) => filter.column === column);
  }

  async lanzarLLamada() {
    const idVMFilter = this.getFilterableValue("idVM");
    let idVM = null;
    if (this.data && !idVMFilter) {
      idVM = this.data.terminalId;
      this.filterList.push(new FilterableValue("idVM", idVM, "long", "EQUALS"));
    }
  
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize:this.pageSize};
    const startPage = pageIndex*pageSize;
    const endPage=startPage+pageSize;
    
    const request =  new FilterAndPagBean(
      this.direccion, 
      this.sortId, 
      this.filterList, 
      startPage,
      endPage,
      pageIndex
    );

    let value = await this.servicios.find(request);
    let pagCount = await this.servicios.countTotal(request);
    if (value) {
      this.dataSource = new MatTableDataSource<Ventas>(value.data);
      if (pagCount) {
        this.pageLength = pagCount.data?.total ?? 0;
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.dataSource.paginator = this.paginator;
    this.exportFileName = this.translate.instant('export.fileSells');
    this.dataSource.sort = this.sort;
    this.isAdmin = await this.utilService.isAdmin();
    if (this.isAdmin) {
      this.displayedColumns = Object.keys(ColumnsAdmin);
    }

    this.lanzarLLamada();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.resetPaginatorState();
    this.lanzarLLamada();
  }

  resetPaginatorState(){
    this.paginator.firstPage();
  }

  pageEvent() {
    this.lanzarLLamada();
  }

  announceSortChange(sortState: Sort) {
    console.log("ordenar=" + JSON.stringify(sortState))
    let active = sortState.active.replace("_", ".")
    this.sortId = active;

    if (sortState.direction) {
      this.direccion = sortState.direction.toUpperCase()
    } else {
      this.direccion = null
      this.sortId = null
    }
    this.resetPaginatorState()
    this.lanzarLLamada();
  }

  refrescar() {
    this.lanzarLLamada();
  }

  async getMachine(row: any): Promise<any> {
    return await this.monitoringService.getMachine(row.idVM);
  }

  public getOpTypeOption(opType:string): string {
    if(opType) return 'sells.opTypeOptions.' + opType;
    return 'common.emptyData';
  }

  
  public getDiscountValueOption(discountValue:string): string {
    if(discountValue) return 'sells.discountValueOptions.' + discountValue;
    return 'common.emptyData';
  }
}


