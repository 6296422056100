<div class="edit-panel">
  <div class="header">
    <div class="title">{{ 'clients.title' | translate }}</div>
    <div class="action-buttons">
      <ng-container *ngIf="!readOnly">
        <button *ngIf="client.canByInput(client.functIds.edit_clients)" mat-flat-button class="custom-add-button" (click)="onFormSubmit()" 
        [disabled]="registerForm.pristine || registerForm.invalid">{{ 'common.submit' | translate }}</button>
      </ng-container>
      <button class="close-button" (click)="formExit()">{{ 'editPanel.close' | translate }}</button>
    </div>
  </div>

  <!-- Formulario principal de Clientes -->
  <form [formGroup]="registerForm">
    <fieldset class="edit-container bordered">
      <legend class="details-header">
        <label class="title-small">{{ 'clients.details' | translate }}</label>
      </legend>
      <div class="edit-content">
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'clients.name' | translate }}<span class="obligatorio"></span></mat-label>
                <input matInput formControlName="name" type="text" required />
                <mat-error *ngIf="registerForm.get('name')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                <mat-error *ngIf="registerForm.get('name')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'clients.cod' | translate }}</mat-label>
                <input matInput formControlName="cod" type="text" />
                <mat-error *ngIf="registerForm.get('cod')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- Dropdown para seleccionar operadores, visible solo para ADMIN -->
        <div class="row" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_1)">
          <div class="col-12">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'clients.operator' | translate }}<span class="obligatorio"></span></mat-label>
                <mat-select formControlName="idOperator" required>
                  <mat-option *ngFor="let operator of operators" [value]="operator.id">
                    {{ operator.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="registerForm.get('idOperator')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'clients.cif' | translate }}</mat-label>
                <input matInput formControlName="cif" type="text" />
                <mat-error *ngIf="registerForm.get('cif')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'clients.localidad' | translate }}</mat-label>
                <input matInput formControlName="localidad" type="text" />
                <mat-error *ngIf="registerForm.get('localidad')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'clients.codPostal' | translate }}</mat-label>
                <input matInput formControlName="codPostal" type="text" />
                <mat-error *ngIf="registerForm.get('codPostal')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'clients.web' | translate }}</mat-label>
                <input matInput formControlName="web" type="text" />
                <mat-error *ngIf="registerForm.get('web')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'clients.email' | translate }}</mat-label>
                <input matInput formControlName="email" type="email" />
                <mat-error *ngIf="registerForm.get('email')?.hasError('email')">{{ 'error.email' | translate }}</mat-error>
                <mat-error *ngIf="registerForm.get('email')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'clients.phone' | translate }}</mat-label>
                <input matInput formControlName="phone" type="phone" />
                <mat-icon matSuffix matTooltip="{{ 'info.phoneValidation' | translate }}" class="custom-icon-color">info</mat-icon>
                <mat-error *ngIf="registerForm.get('phone')?.hasError('pattern')">{{ 'error.pattern' | translate }}</mat-error>
                <mat-error *ngIf="registerForm.get('phone')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'clients.personContact' | translate }}</mat-label>
                <input matInput formControlName="personContact" type="text" />
                <mat-error *ngIf="registerForm.get('personContact')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </form>
  <div class="footer" *ngIf="!readOnly">
    <button *ngIf="client.canByInput(client.functIds.delete_clients)" mat-stroke-button class="custom-white-button" (click)="onDelete(data.clientId)">
      {{ 'editPanel.deleteVersion' | translate }}
      <img class="delete-icon" src="assets/img/delete_icon.svg" alt="Delete icon">

    </button>
  </div>
</div>
