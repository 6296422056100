import { Injectable } from '@angular/core';
import { ConsoleRequest, ObjectsRequest } from 'src/app/models/console.request.model';
import { FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { FirmwareUpload } from 'src/app/models/firmwareUpload.model';
import { RestService } from 'src/app/services/merchant/Rest.service';

@Injectable({
  providedIn: 'root',
})
export class AppsService {

  constructor(private merchantRest: RestService) { }
  
  async find(filterAndPagBean: FilterAndPagBean, id?:number) {
    let _body: ObjectsRequest = new ObjectsRequest();
    _body.filterAndPagBean = filterAndPagBean;
    _body.id = id;

    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getListFirmware/',true,true )   
       .catch(err => {
         return null
       })

    return value;
  }

  async countTotal(filterAndPagBean: FilterAndPagBean) {
    let _body: ObjectsRequest = new ObjectsRequest();
    _body.filterAndPagBean = new FilterAndPagBean(filterAndPagBean?.orderSentido, filterAndPagBean?.order, filterAndPagBean?.filterGeneric, null, null, null);
    
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'countFirmware/',true,true )   
    .catch(err => {
      return null
    })
      return value;
  }

  async addUpdate(apps: FirmwareUpload) {
    let value = await this.merchantRest.commonRestCallConsolaRest(apps, 'setFirmwareAtento/', true, true)
      .catch(err => {
        return null
      })

    return value;
  }

  async get(id: number) {
    let value = await this.merchantRest.commonRestCallConsolaRest({ id }, 'getFirmware/', true, true)
      .catch(err => {
        return null
      })

    return value;
  }

  async delete(id: number) {
    let value = await this.merchantRest.commonRestCallConsolaRest({ id }, 'deleteFullFirmware/', true, true)
      .catch(err => {
        return null
      })

    return value;
  }

  async deleteVersion(id: number) {
    let value = await this.merchantRest.commonRestCallConsolaRest({ id }, 'deleteFirmware/', true, true)
      .catch(err => {
        return null
      })

    return value;
  }

}