import { Pipe, PipeTransform } from '@angular/core';
import { HierarchyOptionsService } from 'src/app/services/hierarchy-options.service';
import { LEVEL_HIERARCHY } from 'src/app/util/constants';

type HierarchyLevelType = keyof typeof LEVEL_HIERARCHY; 

@Pipe({
  name: 'levelName',
  standalone:true
})
export class HierarchyLevelNamePipe implements PipeTransform {
  private _state = new Map<string, any>();

  constructor(private service: HierarchyOptionsService) {
    this.service.state.subscribe((state:Map<string, any>) =>  this._state = state);
  }

  transform(levelId?: number, hierarchy?:HierarchyLevelType): string {
    if(!levelId || !hierarchy) return '-';
    const item = this._state.get(hierarchy)?.get(levelId);
    return item ? item.name : '';
  }
}
