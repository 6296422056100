import { inject, Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { getDateFromTerminalFormat } from 'src/app/util/util';

@Pipe({
    standalone:true,
    name: 'dateFromTerminal',
})
export class DateFromTerminalPipe implements PipeTransform {
  private authService  = inject(AuthService);
  private lang = this.authService.language;
  private timeZone = this.authService.timeZone;

  transform(value:string): string {
    const parsedDate = getDateFromTerminalFormat(value)?.toLocaleString((this.lang ?? "en-US"),{ timeZone: this.timeZone });  
    return parsedDate ?? '';
  }
}
