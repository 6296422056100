<header id="header" class="header fixed-top d-flex align-items-center">
    <div class="d-flex align-items-center justify-content-between"> 
      <i (click)="sidebarToggle()" class="bi bi-list toggle-sidebar-btn"></i>
      <a routerLink="/monitoring" class="logo d-flex align-items-center"> 
         <span class="img"></span> 
         <span class="d-none d-lg-block"><b>MDM</b> {{version}} </span> 
         <span class="d-none d-lg-block mx-2" *ngIf="hierarchy.hierarchyLevelName" ><b> - {{ hierarchy.hierarchyLevelName }}</b></span> 
      </a>
   </div>

    <nav class="header-nav ms-auto">
       <ul class="d-flex align-items-center">

            <li class="nav-item dropdown pe-3">
             <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown"> 
                        <span class="profilelogo "> </span>
                        <span class="d-none d-md-block dropdown-toggle ps-2">{{username}}</span> </a>
             <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li class="dropdown-header">
                   <h6>{{fullName}}</h6>
                   <span></span>
                </li>
                <li>
                   <hr class="dropdown-divider">
                </li>
                <li> <a class="dropdown-item d-flex align-items-center" routerLink="/user-profile"> <i class="bi bi-person"></i> <span i18n>{{ 'loggedUser.profile' | translate }}</span> </a></li>
                <li>
                   <hr class="dropdown-divider">
                </li>
                <li>
                  <form action="{{logoutBaseUrl}}" method="post" name="headLogoutForm" #headLogoutForm>
                     <input type="hidden" id="head_redirect" name="redirect" value="{{redirect}}"/>

                     <a class="dropdown-item d-flex align-items-center" role="button" routerLink="/" (click)="logout()"> <i class="bi bi-box-arrow-right"></i> <span i18n>{{ 'loggedUser.logOut' | translate }}</span> </a>
                  </form>
               </li>
             </ul>
          </li>
       </ul>
    </nav>
 </header>
