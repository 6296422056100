
<ng-template #wifiIcon><i class="bi bi-wifi"></i></ng-template>
<ng-template #ethIcon><i class="bi bi-ethernet"></i></ng-template>
<ng-template #simIcon><span>{{ type }}</span></ng-template>

<div [ngClass]="stateClass" [matTooltip]="tooltip" class="coverage-icon">
    <ng-container *ngIf="isActive; else noDataIcon">
        <!-- Íconos de señal -->
        <i class="bi {{icon}}"></i>
        <!-- Íconos de conexión -->
        <ng-container *ngTemplateOutlet="typeIconTemplate"></ng-container>
    </ng-container>
    <ng-template #noDataIcon>
        <!-- Íconos tachados para "Sin datos" -->
        <i class="bi bi-reception-0"></i>
        <i class="bi bi-wifi-off"></i>
    </ng-template>
  </div>