import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialog, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacySelectChange, MatLegacySelectModule } from "@angular/material/legacy-select";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Parameters } from "src/app/models/parameters.model";
import { PerfilApp } from "src/app/models/perfilApp.model";
import { NewPerfilAppComponent } from "../new-perfiles/new-perfil-app/new-perfil-app.component";
import { FilterableValue, FilterAndPagBean, OPERATION_FILTER, TYPE_FILTER } from "src/app/models/FilterAndPagBean";
import { AppsService } from "../../aplications/aplications.service";
import { ParamService } from "../../param/param.service";
import { NotifierService } from "angular-notifier";
import { Profile } from "src/app/models/profiles.model";
import { ConfirmDialogComponent } from "src/app/components/confirm-dialog/confirm-dialog.component";
import { ConfirmDialogActions, ConfirmDialogData } from "src/app/components/confirm-dialog/confirm-dialog.model";
import { PerfilesService } from "../perfiles.service";
import { Aplications } from "src/app/models/aplications.model";
import { HierarchyInput } from "src/app/components/hierarchy-selector/hierarchy-selector.component";
import { HierarchyHandlerService } from "src/app/services/hierarchy-handler.services";

@Component({
  standalone:true,
  imports:[CommonModule, 
    TranslateModule, 
    MatLegacyButtonModule, 
    MatLegacyFormFieldModule,
    MatLegacySelectModule,
    NewPerfilAppComponent
  ],
  selector: 'app-edit-apps',
  templateUrl: './edit-apps.component.html',
  styleUrls: ['./edit-apps.component.css']
})
export class EditAppsComponent implements OnInit{
  public resultListApp:PerfilApp[] = [];
  public parameters: Parameters[] = [];
  public appsBBDD:any[] = [];
  public versionBBDD:any[] = [];

  constructor(public dialogRef: MatDialogRef<EditAppsComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: { profile:Profile, selected:PerfilApp }, 
    private dialog:MatLegacyDialog,
    private appsService:AppsService,
    private paramService:ParamService,
    private profileService:PerfilesService,
    private notifier:NotifierService,
    private translate:TranslateService,
    private hierarchy:HierarchyHandlerService
  ){}

  public async ngOnInit(): Promise<void>{
    const profile = this.data.profile;
    const hierarchy: HierarchyInput = { idOperator: profile?.idOperator, idClient: profile?.idClient, idSubClient: profile?.idSubClient};
    await this.loadAvailableApps(hierarchy);
    this.loadParameters(hierarchy);

    if(this.data?.selected){
      const selectedPerfilApp = new PerfilApp;
      selectedPerfilApp.map(this.data.selected);
      this.resultListApp.push(selectedPerfilApp);
    }
  }

  public cancelChanges(){
    this.dialogRef.close();
  }

  public submitChanges(){
    const dialogData = new ConfirmDialogData();
    dialogData.enableCancel = false;
    dialogData.titleI18n = this.translate.instant('confirmDialog.updateProfileTitle');
    dialogData.textI18n = this.translate.instant('confirmDialog.updateProfileText');
    dialogData.svgIcon = 'assets/img/confirm._icon.svg';

    const confirmSave = this.dialog.open(ConfirmDialogComponent, {
      width: '35%', panelClass: 'custom-modalbox',
      data:dialogData
    });

    confirmSave.afterClosed().subscribe((response:ConfirmDialogActions) => {
      if (response === ConfirmDialogActions.CONFIRM){
        if(this.data?.selected){
          this.updatePerfilApp();
          return;
        } 

        this.addPerfilApps();
      }
    });
  }

  private mapToPerfilAppFromSelected(resultListApp:PerfilApp[]): PerfilApp[]{
    return resultListApp.map((app)=> app.mapToServer());
  }

  public async addPerfilApps(){
    const profile = { ...this.data.profile };
    profile.listApp = [ ...profile.listApp ?? [], ...this.mapToPerfilAppFromSelected(this.resultListApp) ]
    const response = await this.profileService.saveApps(profile);
    if(response!.status >= 0 ) this.dialogRef.close({ result:1 });
  }

  public async updatePerfilApp(){
    const editedPerfilApp:PerfilApp = this.resultListApp[0];
    const profile = { ...this.data.profile };
    profile.listApp = profile.listApp?.map((perfilApp)=>{
      if(perfilApp.idApp === editedPerfilApp.idApp){
        return editedPerfilApp.mapToServer();
      } 
      return perfilApp;
    }) 
    const response = await this.profileService.saveApps(profile);
    if(response!.status >= 0 ) this.dialogRef.close({ result:1 });
  }


  public removeApp(app: PerfilApp): void {
    this.resultListApp = this.resultListApp.filter(a => a.idApp !== app.idApp);
  }

  private async loadAvailableApps(hierarchy:HierarchyInput): Promise<void> {
    // Limpiar la lista de aplicaciones antes de cargar nuevas
    this.appsBBDD = [];
    const filterList = this.hierarchy.getFilterableValues(hierarchy);
    filterList.push(new FilterableValue("isLast", "true", "boolean", OPERATION_FILTER.EQUALS));
    const request = new FilterAndPagBean("ASC","name",filterList,null,null,null);
    const result = await this.appsService.find(request);
  
    if (result?.status === 0) this.appsBBDD = result.data;
  }

  private async loadParameters(hierarchy:HierarchyInput): Promise<void> {
    const filterList = this.hierarchy.getFilterableValues(hierarchy);
    const request = new FilterAndPagBean("ASC","name",filterList,null,null,null);
    const result = await this.paramService.find(request);
    if (result?.status === 0) {
      this.parameters = result.data;
    }
  }

  public async onAppSelect(event: MatLegacySelectChange): Promise<void> {
    let selectedApp = event.value;

    this.versionBBDD = [];
    const exists = this.existsApplication(selectedApp);
    if (exists) return;
       
    const filterList = [];
    filterList.push(new FilterableValue("pakage", selectedApp.pakage, TYPE_FILTER.String, OPERATION_FILTER.EQUALS));
    const request = new FilterAndPagBean("ASC","version",filterList,null,null,null);
    const result = await this.appsService.find(request);
    if (result?.status === 0) this.versionBBDD = result.data;
  }

  public onVersionSelect(event: MatLegacySelectChange) {
    let version = event.value;
    
    const exists = this.existsApplication(version);
    if (exists) return;

    let perfilApp: PerfilApp = new PerfilApp();
    perfilApp.idApp = version.id;
    perfilApp.appName = version.name;
    perfilApp.appVersion = version.version;
    perfilApp.appPakage = version.pakage;
    perfilApp.manufacID = version.manufacID;
    perfilApp.modelNumber = version.modelNumber;
    this.resultListApp.push(perfilApp);
  }

  private existsApplication(appToAdd:Aplications):boolean{
    const exists = this.resultListApp.some(app => app.appPakage === appToAdd.pakage);
    const existsInProfile = this.data.profile.listApp?.some(app => app.appPakage === appToAdd.pakage);
    if(exists || existsInProfile) {
      this.notifier.notify('warning', this.translate.instant('warning.duplicateApp'));
      return true;
    }

    return false;
  }
}
