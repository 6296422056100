import { inject, Injectable } from "@angular/core";
import { OperatorService } from "../pages/admin/operator/operator.service";
import { ClientService } from "../pages/admin/client/client.service";
import { SubClientService } from "../pages/admin/sub-client/sub-client.service";
import { FilterAndPagBean } from "../models/FilterAndPagBean";
import { BehaviorSubject } from "rxjs";
import { Operator } from "../models/operator.model";
import { Client, SubClient } from "../models/client.model";
import { LEVEL_HIERARCHY } from "../util/constants";

type HierarchyLevelType = keyof typeof LEVEL_HIERARCHY; 

@Injectable({
    providedIn: 'root',
})
export class HierarchyOptionsService {
    operators = inject(OperatorService);
    clients = inject(ClientService);
    subclients = inject(SubClientService);

    private _hierarchyMap = new Map<HierarchyLevelType, any>();
    private hierarchyState$: BehaviorSubject<Map<HierarchyLevelType, any>> = new BehaviorSubject(this._hierarchyMap);

    load(){
        this.getLevel1();
        this.getLevel2();
        this.getLevel3();
    }
    
    private async getLevel1(): Promise<void> {
        const _request = new FilterAndPagBean(null,null, [], null, null, null);
        const response = await this.operators.find(_request);
        if (response && response.data) {
            const options =  new Map();
            response.data.forEach((item:Operator) => (options.set(item.id, item)));
            this._hierarchyMap.set('LEVEL_1', options);
            this.hierarchyState$.next(this._hierarchyMap);
        }
    }

    private async getLevel2(): Promise<void> {
        const _request = new FilterAndPagBean(null,null, [], null, null, null);
        const response = await this.clients.find(_request);
        if (response && response.data) {
            const options =  new Map();
            response.data.forEach((item:Client) => (options.set(item.id, item)));
            this._hierarchyMap.set('LEVEL_2', options);
            this.hierarchyState$.next(this._hierarchyMap);
        }
    }

    private async getLevel3(): Promise<void> {
        const _request = new FilterAndPagBean(null,null, [], null, null, null);
        const response = await this.subclients.find(_request);
        if (response && response.data) {
            const options =  new Map();
            response.data.forEach((item:SubClient) => (options.set(item.id, item)));
            this._hierarchyMap.set('LEVEL_3', options);
            this.hierarchyState$.next(this._hierarchyMap);
        }
    }

    public get state() {
        return this.hierarchyState$;
    }

    public getFilteredLevelToOption(level:string, filterBy?: string, filterValue?:number):{ label:string, value:number }[]{
        if(!this._hierarchyMap.get(level as HierarchyLevelType)) return [];
        return Array.from(this._hierarchyMap.get(level as HierarchyLevelType).values())
            .filter((item:any)=>{
                if(filterBy){
                    const [fieldParend, fieldChild] = filterBy.split('.');
                    const compareValue = fieldChild ? item[fieldParend][fieldChild] : item[fieldParend];
                    return compareValue === filterValue;
                }  
                return true;
            })
            .map((item:any)=> ({ label: item.name, value:item.id })); 
    }
}
