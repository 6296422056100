<div class="row text-align-end mb-3">
    <div class="col">
       <select class="form-select" name="signal" id="signal" [(ngModel)]="signal" (ngModelChange)="filterChartBySignal()">
          <option value="3G" selected>3G</option>
          <option value="2G">2G</option>
          <option value="4G">4G</option>
       </select>
    </div>
 </div>

<div *ngIf="options">
    <apx-chart
        [series]="options.series"
        [chart]="options.chart"
        [xaxis]="options.xaxis"
        [yaxis]="options.yaxis"
        [tooltip]="options.tooltip"
        [stroke]="options.stroke"
    ></apx-chart>
</div>
