import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FilterDisplay } from 'src/app/models/filter.model';

@Component({
  standalone:true,
  imports:[
    CommonModule, 
    MatIconModule,
    TranslateModule
  ],
  selector: 'app-filter-pill',
  templateUrl: './display-pill.component.html',
  styleUrls: ['./display-pill.component.css']
})
export class DisplayPillComponent {
  @Input() filter?:FilterDisplay;
  @Output() remove = new EventEmitter;
}
