<div class="pagetitle">
    <h1 i18n>Group</h1>
</div>
<section class="section">
    <form [formGroup]="registerForm">


        <div class="row">
            <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>Name <span class="obligatorio">*</span></h5>
                        <input formControlName="name" matInput type="text" required>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>Code</h5>
                        <input formControlName="cod" matInput type="text">
                    </mat-form-field>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>Operator <span class="obligatorio">*</span></h5>
                        <mat-select formControlName="opeMarcado" [compareWith]="compareFn"
                            (selectionChange)="selectOrg($event.value)">
                            <mat-option *ngFor="let op of listaOpe" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('opeMarcado')?.hasError('required')" i18n>Field
                            required!</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>Terminal</h5>
                        <mat-select (selectionChange)="selectTerminal($event.value)">
                            <mat-option *ngFor="let op of listaTerminal" [value]="op">
                                {{op.serialNumber}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-list style="max-height: 270px; overflow: auto;">
                        <mat-list-item *ngFor="let vm of listCommerceSelect">

                            <button mat-icon-button (click)="deleteVMSelect(vm)" matListItemMeta> <img
                                    class="delete-icon" src="assets/img/delete_icon.svg" alt="Delete icon">
                            </button>
                            <p>{{ vm.serialNumber }}</p>
                        </mat-list-item>
                    </mat-list>
                </div>
            </div>

        </div>




        <div class="row botonera">
            <div class="col-lg-12">
                <button class="btn btn-cancelar" (click)="formExit()" i18n>Cancel</button>
                <button class="btn btn-aceptar" (click)="onFormSubmit()" i18n>Submit</button>
            </div>
        </div>



    </form>
</section>