<div class="file-display file-display--{{ this.invalid ? 'invalid': 'valid'}}">
    <div class="file-display-content">
        <div class="file-display-icon">
            <mat-icon svgIcon="generic-file"></mat-icon>
        </div>
        <div class="file-display-label">
            <label>{{ 'common.selectedFile' | translate }}</label>
            <p [title]="fileName">{{ fileName }}</p>
        </div>        
    </div>

    <div class="file-display-actions">
        <ng-container *ngIf="canDownload">
            <button mat-icon-button (click)="downloadFile()">
                <mat-icon svgIcon="download-01"></mat-icon>
            </button>
        </ng-container>
        
        <ng-container *ngIf="canDelete">
            <button mat-icon-button class="delete-icon" (click)="deleteFile()">
                <mat-icon svgIcon="trash-01"></mat-icon>
            </button>
        </ng-container>
    </div>

</div>