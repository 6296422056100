<main id="main" class="main">

  <div class="pagetitle">
    <h1>{{ 'monitoring.title' | translate }}</h1>
    
    <div class="action-buttons">
      <!-- Botón Refrescar -->
      <button mat-raised-button class="custom-white-button" (click)="refrescar()">
        <i class="bi bi-arrow-clockwise"></i> {{ 'common.refresh' | translate }}
      </button>

      <!-- Botón Map  -->
      <button mat-flat-button class="custom-add-button" (click)="toggleMap()">
        <i class="bi bi-geo-alt"></i> {{ 'monitoring.map' | translate }}
      </button>
      
      <!-- Botón Subir Csv  -->
      <ng-container *ngIf="rol.canByInput(rol.functIds.edit_terminals)">
        <button mat-flat-button class="custom-add-button custom-add-button--with-icon" (click)="openUploadModal()">
          <mat-icon svgIcon="share-01"></mat-icon>{{ 'monitoring.uploadFile' | translate }}
        </button>
      </ng-container>
    </div>
  </div>

  <div class="map-container" *ngIf="showMap">
    <app-monitoring-map></app-monitoring-map>
  </div>

  <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

  <div id="tableDescription" hidden>{{ 'monitoring.tableDescription' | translate }}</div>

  <div class="mat-elevation-z2 tablaPersonal">
    <table mat-table class="mat-table" [dataSource]="dataSource" aria-describedby="tableDescription" matSort
      (matSortChange)="announceSortChange($event)" matTableExporter #exporter="matTableExporter"
      [hiddenColumns]="getNotExportColumns()">

      <!-- serialNumber Column -->
      <ng-container matColumnDef="serialNumber">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'monitoring.serialNumber' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.serialNumber ?? '-'}}</td>
      </ng-container>

      <!-- Manufacture Column -->
      <ng-container matColumnDef="manufacID">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'monitoring.manufacID' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.manufacID ?? '-'}}</td>
      </ng-container>
      <!-- modelNumber Column -->
      <ng-container matColumnDef="modelNumber">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'monitoring.modelNumber' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.modelNumber ?? '-'}}</td>
      </ng-container>

      <!-- Operator Column -->
      <ng-container matColumnDef="idOperator">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'hierarchy.level_1' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.idOperator | levelName:'LEVEL_1' }} </td>
      </ng-container>

      <!-- Client Column -->
      <ng-container matColumnDef="idClient">
        <th mat-header-cell *matHeaderCellDef class="mat-column-region">
          <div mat-sort-header>{{ 'hierarchy.level_2' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="mat-column-region"> {{element.idClient | levelName:'LEVEL_2' }}
        </td>
      </ng-container>

      <!-- SubClient Column -->
      <ng-container matColumnDef="idSubClient">
        <th mat-header-cell *matHeaderCellDef class="mat-column-region">
          <div mat-sort-header>{{ 'hierarchy.level_3' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="mat-column-region"> {{element.idSubClient | levelName:'LEVEL_3' }}
        </td>
      </ng-container>

      <!-- swRev Column -->
      <!--ng-container matColumnDef="swRev">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'monitoring.softwareRevision' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.swRev ?? '-'}}</td>
      </ng-container!-->
      <!-- commerce Column -->
      <!--ng-container matColumnDef="commerce">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'monitoring.commerce' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.commerce ?? '-'}}</td>
      </ng-container!-->
      
      <!-- puntoVenta Column -->
      <ng-container matColumnDef="puntoVenta">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'monitoring.puntoVenta' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.puntoVenta ?? '-'}}</td>
      </ng-container>

      <!-- lastUpdate Column -->
      <ng-container matColumnDef="lastConect">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'monitoring.lastConnection' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"
          matTooltip="{{ ('monitoring.lastConnection' | translate)+': '+(element.lastConect | dateOnTimeZone | diasDesdeFecha)}}">
          {{ element.lastConect | dateOnTimeZone:true }}
        </td>
      </ng-container>
      <ng-container matColumnDef="coverage">
        <th mat-header-cell *matHeaderCellDef>
          <div>{{ 'monitoring.coverageLevel' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <app-coverage-icon [level]="element.coverageLevel" [type]="element.coverageType" [isInactive]="element.isInactive"></app-coverage-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="listPerfil">
        <th mat-header-cell *matHeaderCellDef>
          <div>{{ 'monitoring.profile' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="button">
          <ng-container *ngFor="let item of element.listPerfil; last as isLast">
            <span>{{item.name}}</span> <span *ngIf="!isLast">, </span>
          </ng-container>
        </td>
      </ng-container>

      <!-- status Column (Nueva columna de estado) -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="no-wrap">
          <div>{{ 'monitoring.status.status' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="no-wrap">
          <div class="status-indicator">
            <i [ngClass]="element.statusClass" class="status-icon"></i>
            <span>{{ element.statusLabel | translate }}</span>
          </div>
        </td>
      </ng-container>

      <!-- view Column -->
      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef style="width: 60px;"></th>
        <td mat-cell *matCellDef="let element" style="width: 60px;">
          <button *ngIf="rol.canByInput(rol.functIds.view_terminals)" matTooltip="{{ 'common.view' | translate }}"
            (click)="onView(element)" mat-icon-button class="iconoTabla">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <!-- Empty state -->
    <ng-container *ngIf="!dataSource.data.length">
      <app-table-empty-state></app-table-empty-state>
    </ng-container>

    <button id="export" class="icoDownload" mat-icon-button
      (click)="exporter.exportTable('xlsx', { fileName: exportFileName })">
      <i class="bi bi-file-earmark-arrow-down"></i>
    </button>
    <span class="text-red">
      {{ 'monitoring.inactive' | translate }} {{ inactiveTerminals }}
    </span>

    <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent()"
      showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>

  </div>

</main>