import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { FormCreateActions, LEVEL_HIERARCHY, RegexpValidators } from 'src/app/util/constants';
import { NotifierService } from 'angular-notifier';
import { Client, SubClient } from 'src/app/models/client.model';
import { Operator } from 'src/app/models/operator.model';
import { UtilService } from 'src/app/services/util.service';
import { SubClientService } from '../sub-client.service';
import { CommonModule } from '@angular/common';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { ClientService } from '../../client/client.service';
import { FilterableValue, FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';
import { RolHandlerService } from 'src/app/services/rol-handler.service';

@Component({
  standalone:true,
  imports:[CommonModule, 
    FormsModule, 
    ReactiveFormsModule, 
    MatLegacyFormFieldModule, 
    MatLegacySelectModule, 
    MatLegacyInputModule,
    TranslateModule,
    MatLegacyButtonModule,
    MatIconModule,
    MatLegacyTooltipModule
  ],
  selector: 'app-form-sub-client',
  templateUrl: './form-sub-client.component.html',
  styleUrls: ['./form-sub-client.component.css']
})
export class FormSubClientComponent implements OnInit {
  registerForm!: FormGroup;
  listCommerce: string[] = [];
  operators: Operator[] = []; // Lista de operadores como objetos
  clients:Client[] = [];
  isAdmin: boolean = true;  // Control de visibilidad para perfil ADMIN

  private subClientBBDD: SubClient | undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { clientId: number },
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private service: SubClientService,
    private clientService:ClientService,
    private utilServicios: UtilService,
    private translate:TranslateService,
    public hierarchy:HierarchyHandlerService,
    public rol:RolHandlerService,
    public dialogRef: MatDialogRef<FormSubClientComponent>,
    public dialog: MatDialog) {
  }

  async ngOnInit(): Promise<void> {
    // Obtener el rol del usuario actual
    this.isAdmin = this.utilServicios.isAdmin();
    // Inicializar el formulario
    this.registerForm = this.formBuilder.group({
      name: [null, Validators.required],
      cod: [null],
      cif:[null],
      localidad:[null],
      codPostal:[null],
      web:[null],
      email: [null, Validators.compose([Validators.email, 
        Validators.maxLength(255),
      ])] ,
      phone:[null],
      personContact:[null],
      idOperator:[ null, Validators.required ],
      idClient:[ null, Validators.required ]
    });
    
    this.registerForm.get('idOperator')?.valueChanges.subscribe((value)=>{
      if(value) this.loadClients(value);
    })

    // Cargar operadores si es admin
    if (this.isAdmin){
      this.registerForm.get('idOperator')?.enable();  
      await this.loadOperators();
    }
    
    if (this.data) {
      this.cargar()
    } else {
      // Si es un subCliente nuevo, seteo los datos del usuario loggeado  
      this.registerForm.get('idOperator')?.setValue(this.utilServicios.getOperatorId());
      this.registerForm.get('idClient')?.setValue(this.utilServicios.getClientId());
    }
  }

  async cargar(): Promise<void> {
    this.subClientBBDD = await this.service.getItem(this.data.clientId);
    if(this.subClientBBDD){
      this.registerForm.patchValue({
        name: this.subClientBBDD.name,
        cod: this.subClientBBDD.cod,
        cif:this.subClientBBDD.cif,
        localidad: this.subClientBBDD.localidad,
        codPostal: this.subClientBBDD.codPostal,
        web: this.subClientBBDD.web,
        email: this.subClientBBDD.email,
        phone: this.subClientBBDD.phone,
        personContact: this.subClientBBDD.personContact,
      });
      // Actualizo los controladores de forma individual para enviar el evento al valueChanges; 
      this.registerForm.get('idOperator')?.setValue(this.subClientBBDD.idOperator)
      this.registerForm.get('idClient')?.setValue(this.subClientBBDD.idClient)
    }
  }

  // Método que carga operadores reales
  private async loadOperators(): Promise<void> {
    this.utilServicios.findOperators().subscribe((operators:Operator[]) => {
        this.operators = operators; // Almacena la lista de operadores
    });
  }

  private async loadClients(idOperator:(number|null) = null):Promise<void>{
    let filters = [];
    if(idOperator) filters.push(new FilterableValue("idOperator", idOperator, "long", "EQUALS")) 
    const request = new FilterAndPagBean(null,null,filters,null,null, null);
    const response = await this.clientService.getListed(request);
    if(response && response.data) this.clients = response.data;
  }

  onFormSubmit(): void {
    if (this.registerForm.invalid) return;
    
    const data = this.registerForm.value;
    let objNew: SubClient = new SubClient();
    objNew.name = data.name;
    objNew.cod = data.cod;
    objNew.cif = data.cif;
    objNew.localidad = data.localidad;
    objNew.codPostal = data.codPostal;
    objNew.web = data.web;
    objNew.email = data.email;
    objNew.phone = data.phone;
    objNew.personContact = data.personContact;
    objNew.idClient = data.idClient;
    objNew.idOperator = this.utilServicios.getOperatorId() as number;
    
    if (this.isAdmin) objNew.idOperator = data.idOperator;  // Agregar operador seleccionado si es ADMIN
    if (this.subClientBBDD) objNew.id = this.subClientBBDD.id;

    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = this.translate.instant('confirmDialog.createSubclientTitle'); 
    dialogData.textI18n = this.translate.instant('confirmDialog.createSubclientText'); 
    dialogData.svgIcon = 'assets/img/confirm._icon.svg';

    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) this.saveClient(objNew);
    });
  }

  async saveClient(objNew:SubClient){
    const val = await this.service.addUpdate(objNew);
    if (val!.status >= 0) {
      this.notifier.notify('success', this.translate.instant('success.subClientSaved'));
      this.dialogRef.close(FormCreateActions.SAVED);
    }
  }

  formExit(): void {
    this.dialogRef.close(FormCreateActions.EXIT);
  }


}
