import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { InstalledApp } from './installed-apps.model';
import { TableEmptyStateComponent } from 'src/app/components/table-empty-state/table-empty-state.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { TerminalService } from '../info-terminal/terminal.service';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatTableModule } from '@angular/material/table';
import { MatLegacyPaginator, MatLegacyPaginatorIntl, MatLegacyPaginatorModule } from '@angular/material/legacy-paginator';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { MatLegacyTableDataSource } from '@angular/material/legacy-table';
import { environment } from 'src/environments/environment';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { AppliedFilter, FieldType, FilterField } from 'src/app/models/filter.model';
import { Aplications } from 'src/app/models/aplications.model';

const Columns = {
  appLabel: 'appLabel',
  packageName: 'packageName',
  versionCode: 'versionCode',
  versionName: 'versionName',
  commonParamVersion: 'commonParamVersion',
  specificParamVersion: 'specificParamVersion',
}

const FILTER_COLUMNS: { field: string, type: FieldType }[] = [
  { field: 'appLabel', type: 'string' },
]


@Component({
  selector: 'app-installed-apps',
  standalone: true,
  imports: [
    CommonModule, 
    TranslateModule, 
    MatTableModule,
    MatLegacyPaginatorModule,
    TableEmptyStateComponent, 
    MatTableExporterModule,
    MatLegacyButtonModule,
    MatSortModule,
    FilterComponent
  ],
  templateUrl: './installed-apps.component.html',
  styleUrls: ['./installed-apps.component.css'],
  providers:[{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})
export class InstalledAppsComponent implements OnInit,AfterViewInit {
  dataSource = new MatLegacyTableDataSource<InstalledApp>();
  appsList: InstalledApp[] = [];
  displayedColumns = Object.keys(Columns);
  exportFileName?: string;
  filterColumns:FilterField[] = FILTER_COLUMNS.map((item)=> new FilterField(item.field, `installedApps.${item.field}`, item.type));

  pageLength = 0;  
  pageSize = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;

  @ViewChild(MatLegacyPaginator) paginator!: MatLegacyPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: { terminalId:number, terminalName:string },
    private services:TerminalService,
    private translate:TranslateService
  ){}

  ngOnInit(): void {
    this.exportFileName = this.translate.instant('export.fileInstalledApps',{ value: this.data.terminalName });
    this.loadTerminal();
  }
  
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  
  async loadTerminal() {
    const { terminalId } = this.data;
    if(!terminalId) return;
    const response = await this.services.findById(terminalId);
    if(response && response.data){
      this.appsList = this.mapDataToInstalledApps(response.data);
      this.dataSource.data = [...this.appsList];
      this.pageLength = this.appsList.length;
    }
  }

  mapDataToInstalledApps(data:VendingMachineAtento):InstalledApp[]{
    if(!data.appList) return [];
    
    const parsedList = JSON.parse(data.appList); 
    return parsedList.map((app:InstalledApp)=> new InstalledApp(
      app.appLabel, 
      app.packageName, 
      app.versionCode, 
      app.versionName,
      app.commonParamVersion,
      app.specificParamVersion
    ));
  }

  
  applyFilter(appliedFilters: AppliedFilter[]) {
    const applied = appliedFilters[0];
    if(applied){
      this.dataSource.data = [...this.appsList].filter((item:InstalledApp)=>{ 
        if(applied.filter.operation === 'LIKE'){
          return item.appLabel.includes(applied.filter.value as string);
        } 
        return !item.appLabel.includes(applied.filter.value as string);
      })  
    } else {
      this.dataSource.data = [...this.appsList];
    }
    
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
