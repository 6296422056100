import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DisplayPillComponent } from '../display-pill/display-pill.component';
import { AppliedFilter } from 'src/app/models/filter.model';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  standalone:true,
  imports:[
    CommonModule,
    DisplayPillComponent, 
    MatLegacyButtonModule, 
    MatIconModule
  ],
  selector: 'app-filter-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.css']
})
export class DisplayComponent {
  @Input() filters:AppliedFilter[] = [];
  @Output() onTrigger = new EventEmitter;
  @Output() onRemove = new EventEmitter;
  
  @ViewChild('trigger', { static: false }) triggerElem: ElementRef | undefined;


  public removeItem(index:number){
    this.onRemove.emit(index);
  }

}
