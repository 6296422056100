import { Component, forwardRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyAutocompleteModule, MatLegacyAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-autocomplete',
  standalone: true,
  imports: [CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyInputModule,
    MatLegacyFormFieldModule,
    MatLegacyAutocompleteModule,
    MatIconModule
  ],
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AutocompleteComponent), multi: true
    }
  ]
})
export class AutocompleteComponent implements ControlValueAccessor{
  @Input() options: string[] = [];
  @Input() title:string = '';
  
  filteredOptions: Observable<string[]>;
  filterControl = new FormControl('', Validators.required);
  
  constructor(){
    this.filteredOptions = this.filterControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  emitValue(event:MatLegacyAutocompleteSelectedEvent){
    const option  = event.option;
    if(!this.disabled) this.onChange(option.value);
  }

  onClosePanel(){
    const inputValue = this.filterControl.value;
    if(!inputValue) this.onChange(''); 
  }

  disabled:boolean = false;
  onChange = (value:(string|null))=>{};
  onTouched = ()=>{};

  writeValue(value: string): void {
    this.filterControl.setValue(value); 
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
