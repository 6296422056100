<div class="pagetitle">
    <h1>{{ 'operators.title' | translate }}</h1>
  </div>
  <section class="section">
    <form [formGroup]="registerForm">
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'clients.name' | translate }}<span class="obligatorio"></span></mat-label>
              <input matInput class="form-field" formControlName="nombre" type="text" required>
              <mat-error *ngIf="registerForm.get('nombre')?.hasError('required')" >{{ 'error.required.field' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'clients.cod' | translate }}<span class="obligatorio"></span></mat-label>
              <input matInput class="form-field" formControlName="cod" type="text">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="button-group">
        <button mat-buttonmat-stroke-button class="custom-white-button" (click)="formExit()">{{ 'common.cancel' | translate }}</button>
        <button mat-flat-button class="custom-add-button" (click)="onFormSubmit()">{{ 'common.submit' | translate }}</button>
      </div>
    </form>
  </section>