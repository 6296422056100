<div class="dialog" style="position: relative;">
    <img *ngIf="data.svgIcon" [src]="data.svgIcon" style="padding: 12px;" alt="Icon">
    <ng-container *ngIf="data.icon">
        <div class="dialog-icon {{data.class}}">
            <mat-icon [svgIcon]="data.icon"></mat-icon>
        </div>  
    </ng-container>

    <!-- Botón de cerrar arriba a la derecha -->
    <button class="dialog-close" (click)="onCancel()">
        {{ 'editPanel.close' | translate }}
    </button>

    <div class="dialog-body">
        <h1 mat-dialog-title>{{ data.titleI18n }}</h1>
        <div mat-dialog-content><p>{{ data.textI18n }}</p></div>
    </div>
    
    <div *ngIf="visibleButtom" class="dialog-footer">
        <ng-container *ngIf="enableCancel">
            <button mat-stroke-button class="custom-white-button"(click)="cancel()">
                {{ 'common.cancel' | translate }}
            </button>
        </ng-container>
        <button mat-flat-button [ngClass]="{ 'red-button': data.isDeleteAction, 'blue-button': !data.isDeleteAction }" (click)="confirm()">
            {{ 'common.confirm' | translate }}
        </button>
    </div>
</div>
