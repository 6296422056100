<div *ngIf="options">
    <apx-chart 
        [series]="options.series" 
        [chart]="options.chart" 
        [yaxis]="options.yaxis"
        [xaxis]="options.xaxis" 
        [stroke]="options.stroke"
        [tooltip]="options.tooltip"
        [fill]="options.fill"
    ></apx-chart>
</div>
