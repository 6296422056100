import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';

enum TransactionTypeTotype  {
  PAYMENT = 'SALE',
  PREAUTH = 'PRE_AUTH',
  TOPUP = 'MOD_PRE_AUTH',
  CONFIRM = 'CONFIRMATION',
  REFUND= 'REFUND',
  MANUAL_VOID= 'MANUAL_VOID',
  DUPLICATE= 'DUPLICATE',
  VOID= 'VOID',
  DIGITIZED_SIGNATURE= 'DIGITIZED_SIGNATURE',
  TOTALS= 'TOTALS',		
  SETTLEMENT= 'SETTLEMENT',
  TRANSACTIONS_DETAIL= 'DETAIL',
  COMMUNICATION_TEST= 'COMM_TEST',
  OTHERS= 'OTHERS',
}	

export interface toastPayload {
  message: string;
  title: string;
  ic: IndividualConfig;
  type: string;
}

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
      private toastr: ToastrService,
      private authService: AuthService) {}

  showSuccess(toast: toastPayload) {
    this.toastr.success(
      toast.message,
      toast.title,
      toast.ic);
  }

  showWarning(toast: toastPayload) {
    this.toastr.warning(
      toast.message,
      toast.title,
      toast.ic);
  }

  isOrganizationlevel():boolean {
    try {
      let accesslvl = 100;
      let tk = this.authService.getUserAccessToken();
      let domains = tk!.auth!.domains as Array<string>;
      domains.forEach(element => {
        let lvl = element.split('.');
        if (lvl != undefined) {
          if (lvl.length < accesslvl) 
            accesslvl = lvl.length;
        }
      });
      return accesslvl == 1;
    } catch(err) {
    }
    return false;
  }

  isMerchantlevel():boolean {
    try {
      let accesslvl = 100;
      let tk = this.authService.getUserAccessToken();
      let domains = tk!.auth!.domains as Array<string>;
      domains.forEach(element => {
        let lvl = element.split('.');
        if (lvl != undefined) {
          if (lvl.length < accesslvl) 
            accesslvl = lvl.length;
        }
      });
      return accesslvl == 2;
    } catch(err) {      
    }
    
    return false;
  }


  public checkItemRights(item:string, perm: string): boolean  {
    if (this.authService.hasValidAccessToken() && this.authService.hasValidIdToken()) {
      try {
        let tk = this.authService.getUserAccessToken();

        let permissions = tk!.auth!.permissions!['merchantsApi'];        
        let permissions_Xiibero = tk!.auth!.permissions!['adminXiibero'];

        let permission : string = permissions![item];
        let permissionXi : string = permissions_Xiibero![item];

        let result = (permission?.length > 0 && permission.includes(perm)) ||
        (permissionXi?.length > 0 && permissionXi.includes(perm));
        
        return result;
      } catch(err) {
        return false;
      }
    } else {
      return false;
    }
  }

  
  public checkAuthServerItemRights(item:string, perm: string): boolean  {
    if (this.authService.hasValidAccessToken() && this.authService.hasValidIdToken()) {
      try {
        let tk = this.authService.getUserAccessToken();
        let permissions = tk!.auth!.permissions!['authserver'];
        let permission : string = permissions![item];
        return ((permission != undefined) && (permission.length > 0) && (permission.includes(perm)));
      } catch(err) {
        return false;
      }
    } else {
      return false;
    }
  }

  phoneticLookup(val:string):string {
    let result = "";
  
    // Only change code below this line
    enum lookup  {
      "alpha"= "Adams",
      "bravo"= "Boston",
    };
    
    result = <lookup>val;
    
    // Only change code above this line
    return result;
  }

  public getClientType(tp : string): string {

    let typedString = tp as keyof typeof TransactionTypeTotype;

    return TransactionTypeTotype[typedString];
  }
}