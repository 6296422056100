import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Coordinates } from 'src/app/models/coordinates.model';
import { MonitoringService } from '../monitoring.service';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MapComponent } from 'src/app/components/map/map/map.component';
import { MachineWithCoordinates } from './machine-coordinates.model';

@Component({
  selector: 'app-monitoring-map',
  standalone: true,
  imports: [CommonModule, MapComponent, TranslateModule],
  templateUrl: './monitoring-map.component.html',
  styleUrls: ['./monitoring-map.component.css']
})
export class MonitoringMapComponent implements OnInit{
  coordinates: Coordinates[] = [];
  
  constructor(
    private servicios: MonitoringService,
    private translate:TranslateService
  ){}

  ngOnInit(): void {
    this.lanzarLLamada();
  }
  
  async lanzarLLamada() {
    const request = new UsersConsoleRequest();
    let value = await this.servicios.findWithCoordinates(request);
    if (value && value.data) {
      this.coordinates = this.mapDataToCoordinate(value.data);
    }
  }
  
  private isCoordinateValid(coordinate: MachineWithCoordinates): boolean {
    return  coordinate && coordinate.latitude != null && coordinate.longitude != null;
  }
  
  private getTooltipItems(vm:MachineWithCoordinates): { label:string, value:string }[]{
    const tooltipList = [];
    tooltipList.push({ label:this.translate.instant('monitoring.manufacID'), value:vm.manufacID ?? '' });
    tooltipList.push({ label:this.translate.instant('monitoring.modelNumber'), value:vm.modelNumber ?? '' });
    tooltipList.push({ label:this.translate.instant('monitoring.softwareRevision'), value:vm.swRev  ?? ''});
    tooltipList.push({ label:this.translate.instant('monitoring.commerce'), value:vm.commerce  ?? ''});
    return tooltipList;
  }

  private mapDataToCoordinate(vmList:MachineWithCoordinates[]):Coordinates[]{
    const coordinates:Coordinates[] = []  
    vmList.forEach((vm)=>{
      if(!this.isCoordinateValid(vm)) return;
      coordinates.push(<Coordinates>{
        label: vm.serialNumber,
        type: 'pointer',
        lat: vm.latitude,
        lng: vm.longitude,
        tooltip: this.getTooltipItems(vm)
      })
    });
    return coordinates;
  }
}
