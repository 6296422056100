import { Injectable } from "@angular/core";
import { RestService } from "src/app/services/merchant/Rest.service";
import { Tag } from "./tag.model";
import { FilterAndPagBean } from "src/app/models/FilterAndPagBean";

@Injectable({
    providedIn:'root'
})
export class TagsService {
    constructor(private service:RestService) { }

    async find(filterAndPagBean:FilterAndPagBean){
        let value = await this.service.commonRestCallConsolaRest({ filterAndPagBean },'getListEtiquetas/', true, true)   
            .catch(err => {
            return null
            })
        return value;   
    }

    async countTotal(){
        const filterAndPagBean = new FilterAndPagBean(null,null,[],null,null,null);
        let value = await this.service.commonRestCallConsolaRest({ filterAndPagBean },'countFindEtiquetas/', true, true)   
            .catch(err => {
            return null
            })
        return value;   
    }

    async save(_body:Tag){
      let value = await this.service.commonRestCallConsolaRest(_body,'addEtiquetas/', true, true)   
        .catch(err => {
          return null
        })
      return value;   
    }

    async delete(_id:number){
        let value = await this.service.commonRestCallConsolaRest({ id:_id },'deleteEtiquetas/', true, true)   
            .catch(err => {
                return null
            })
        return value;   
    }
}