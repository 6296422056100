import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { StadisticBean } from 'src/app/models/stadisticBean.model';
import { RestService } from 'src/app/services/merchant/Rest.service';


@Injectable({
  providedIn: 'root',
})
export class VentasService {

  constructor(private merchantRest : RestService,private authService:AuthService) {}

 
  
  async find(_body: FilterAndPagBean ){

    let userSession = this.authService.currentUserValue();
   /* _body.idEntorno=userSession?.entorno?.id
    if(userSession.operator){
      _body.idOperator=userSession.operator?.id
      if (userSession.client){
        _body.idClient=userSession.client?.id
      }
    } */
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getListVend/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }


  async countTotal(_body: FilterAndPagBean){
   
    _body.pagIndexEnd=null;
    _body.pagIndexIni=null;
    _body.page=null;

    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getListVendCount/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }
 


}