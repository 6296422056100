import { FilterAndPagBean } from "./FilterAndPagBean";

export class UserHistoryQueryDto {

	  idUser: number | undefined;	
	  filterAndPagBean: FilterAndPagBean | undefined;		
	  total: number | undefined;
	

   
  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
  
}



