import {  Injectable } from '@angular/core';
import { Users } from 'src/app/models/users.model';
import { CommonService } from 'src/app/services/common.service';
import { RestService } from 'src/app/services/merchant/Rest.service';


@Injectable({
  providedIn: 'root',
})
export class UsersProfileService {
  
  constructor(
    private merchantRest : RestService,
    public commonService: CommonService) {

    }


  async editProfile(_body: Users){
    let value = await this.merchantRest.commonRestCallConsolaRest(_body, 'addUser/',true,true)   
    .catch(err => {
      return null
    })

    return value;   
  }



 
}