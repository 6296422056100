import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrls: ['./enrollment.component.css']
})
export class EnrollmentComponent implements OnInit {
  qrCodeUrl: string = '';
  code: string = '';
  profileName:string = '';
  
  constructor(@Inject(MAT_DIALOG_DATA) public data:{ code:string, profileName:string }) {
    this.code = data.code; 
    this.profileName = data.profileName
  }

  ngOnInit(): void {
    this.generateQRCode();
  }

  generateQRCode(): void {
    this.qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(this.code)}&size=150x150`;
  }
}
