<div class="header-container">
    <div class="pagetitle">
        <h1>{{ 'parameters.new' | translate }}</h1>
    </div>
</div>

<mat-stepper [linear]="isLinear" #stepper>

    <!-- Paso 1: Detalles -->
    <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
            <ng-template matStepLabel>{{ 'common.details' | translate }}</ng-template>

            <div class="details-container">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'parameters.name' | translate }} <span class="obligatorio"></span></mat-label>
                        <input matInput formControlName="name"
                            placeholder="{{ 'parameters.placeholder.name' | translate }}" required>
                        <mat-error *ngIf="firstFormGroup.get('name')?.hasError('required')">
                            {{ 'error.required.field' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-group-inline">
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'parameters.version' | translate }} <span
                                    class="obligatorio"></span></mat-label>
                            <input matInput formControlName="version"
                                placeholder="{{ 'parameters.placeholder.version' | translate }}" required>
                            <mat-error *ngIf="firstFormGroup.get('version')?.hasError('required')">
                                {{ 'error.required.field' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- Campo de tipo de archivo-->
                    <div class="form-group pt-3">
                        <mat-checkbox class="checkbox-input" formControlName="storageType">{{ 'maintenace.storageType.publicDirectory' | translate }}</mat-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <!-- Hierarchy Form-->
                    <app-hierarchy-selector formControlName="hierarchy"></app-hierarchy-selector>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'parameters.description' | translate }} <span
                                class="obligatorio"></span></mat-label>
                        <textarea matInput formControlName="description" rows="4" cdkTextareaAutosize   cdkAutosizeMinRows="3" cdkAutosizeMaxRows="6"
                            placeholder="{{ 'parameters.placeholder.description' | translate }}" required></textarea>
                        <mat-error *ngIf="firstFormGroup.get('description')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                        <mat-error *ngIf="firstFormGroup.get('description')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
                    </mat-form-field>
                </div>
                
                <!-- selector de etiquetas -->
                <div class="form-group">
                    <app-tags-selector formControlName="tags" [hierarchy]="firstFormGroup.get('hierarchy')"></app-tags-selector>
                </div>
            </div>

            <div class="button-group">
                <button mat-stroke-button class="custom-white-button" matStepperNext>{{ 'common.next' | translate }}</button>
            </div>
        </form>
    </mat-step>

    <!-- Paso 2: Archivos -->
    <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
            <ng-template matStepLabel>{{ 'common.files' | translate }}</ng-template>

            <div class="terminals-container">

                <div class="file-upload-container mb-3">
                    <app-input-files class="w-100" [invalid]="fileUploadError && !selectedFile" (onChanges)="onFileSelected($event)"></app-input-files>
                </div>

                <div *ngIf="selectedFile" class="mb-3">
                    <app-file-display [fileName]="selectedFile.name" (delete)="deleteFile()"></app-file-display>
                </div>
            </div>

            <div class="button-group">
                <button mat-stroke-button class="custom-white-button" matStepperPrevious>{{ 'common.back' | translate }}</button>
                <button mat-flat-button class="custom-add-button" (click)="onFormSubmit()">{{ 'common.submit' | translate }}</button>
            </div>
        </form>
    </mat-step>

</mat-stepper>