<main id="main" class="main">
  <div class="pagetitle">
    <h1> {{ 'maintenace.tabs.getFile' | translate }}</h1>
  </div>

  <section class="row w-100">
    <ng-container *ngIf="!hasVendingMachineSelected">
      <article class="bloque col-lg-6">
        <app-select-machine-detail [dataSource]="selectedVendingMachines"></app-select-machine-detail>
      </article>  
    </ng-container>
    
    <article class="bloque col-lg-6 form-container">
      <form [formGroup]="firstFormGroup">
        <!-- Tipo de Archivo -->
        <div class="form-group" style="margin-top: 10px;">
          <mat-form-field appearance="outline">
            <mat-label> {{ 'common.directoryType' | translate }}</mat-label>
            <mat-select formControlName="storageType" required>
              <mat-option *ngFor="let type of storageTypes" [value]="type">{{ 'maintenace.storageType.'+type | translate }}</mat-option>
            </mat-select>
            <mat-error *ngIf="firstFormGroup.get('storageType')?.hasError('required')">
              {{ 'error.required.field' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
  
        
        <!-- Path -->
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'maintenace.input.path' | translate }} <span class="obligatorio"></span></mat-label>
            <input matInput formControlName="path" placeholder="{{ 'maintenace.placeholder.path' | translate }}" required>
            <mat-icon matSuffix matTooltip="{{ 'maintenace.tooltip.path' | translate }}" class="custom-icon-color">info</mat-icon>
            <mat-error *ngIf="firstFormGroup.get('path')?.hasError('required')">
              {{ 'error.required.field' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

          <!-- Nombre del paquete -->
          <div class="form-group"  *ngIf="verPaquete">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'maintenace.input.packageName' | translate }}<span class="obligatorio"></span></mat-label>
              <input matInput formControlName="packageName" placeholder="{{ 'maintenace.placeholder.packageName' | translate }}">
              <mat-error *ngIf="firstFormGroup.get('packageName')?.hasError('required')">
                {{ 'error.required.field' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
  
        <!-- Nombre del Fichero 
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'maintenace.input.fileName' | translate }}<span class="obligatorio"></span></mat-label>
            <input matInput formControlName="fileName" placeholder="{{ 'maintenace.placeholder.fileName' | translate }}" required>
            <mat-error *ngIf="firstFormGroup.get('fileName')?.hasError('required')">
              {{ 'error.required.field' | translate }}
            </mat-error>
          </mat-form-field>
        </div>-->
  
        <div class="botonera">
          <button mat-flat-button class="custom-add-button" (click)="onFormSubmit()">
              {{ 'common.request' | translate }}
          </button>
        </div>
      </form>
    </article>

  </section>
  

</main>
