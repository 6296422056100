import { Injectable } from '@angular/core';
import { ConsoleRequest } from 'src/app/models/console.request.model';
import { StadisticBean } from 'src/app/models/stadisticBean.model';
import { StadisticSensor } from 'src/app/models/stadisticSensor.model';
import { RestService } from 'src/app/services/merchant/Rest.service';

@Injectable({
  providedIn: 'root',
})
export class SensorService {

  constructor(private merchantRest: RestService) {}

  async getSensors(bean: StadisticBean): Promise<StadisticSensor> {
    let _body: ConsoleRequest = new ConsoleRequest();
    _body.data = bean;

    const value = await this.merchantRest.commonRestCallConsolaRest(bean,'generateReport/',true,true )   
    .catch(err => {
      return null
    })

    return value?.data as StadisticSensor;
  };

}



    // endpoint to call generateReport/ and replace the mocks  

    // Simulación de datos
    // let value: StadisticSensor = new StadisticSensor();
    
    // Simulación de datos para coveragePeriod
    /*value?.data.listCoverage = [
      { id: 1, idVM: 101, period: new Date(), lastValue: Math.random() * 100, lastType: '2G', min: 10, max: 90, average: 50, count: 10, min_2g: 5, max_2g: 85, average_2g: 45, count_2g: 8, min_4g: 20, max_4g: 95, average_4g: 55, count_4g: 12 },
      { id: 2, idVM: 102, period: new Date(new Date().getTime() - 86400000), lastValue: Math.random() * 100, lastType: '4G', min: 20, max: 80, average: 50, count: 15, min_2g: 10, max_2g: 70, average_2g: 40, count_2g: 10, min_4g: 30, max_4g: 85, average_4g: 55, count_4g: 10 },
      { id: 3, idVM: 103, period: new Date(new Date().getTime() - 2 * 86400000), lastValue: Math.random() * 100, lastType: '3G', min: 30, max: 70, average: 50, count: 20, min_2g: 15, max_2g: 65, average_2g: 40, count_2g: 12, min_4g: 40, max_4g: 75, average_4g: 50, count_4g: 15 }
    ];

    // Simulación de datos para DataPeriod
    value.listData = [
      { id: 1, idVM: 101, period: new Date(), txData: Math.random() * 100, rxData: Math.random() * 100, dataTotal: Math.random() * 100 },
      { id: 2, idVM: 102, period: new Date(new Date().getTime() - 86400000), txData: Math.random() * 100, rxData: Math.random() * 100, dataTotal: Math.random() * 100 },
      { id: 3, idVM: 103, period: new Date(new Date().getTime() - 2 * 86400000), txData: Math.random() * 100, rxData: Math.random() * 100, dataTotal: Math.random() * 100 }
    ];*/