
<form [formGroup]="hierarchyForm">
    <div class="row">
        <div class="col-12" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_1)">
            <div class="form-group">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'hierarchy.level_1'| translate }}<span
                            class="obligatorio"></span></mat-label>
                    <mat-select formControlName="idOperator" (selectionChange)="updateFormState('idOperator')">
                        <mat-option [value]="null"></mat-option>
                        <mat-option *ngFor="let op of operatorsList" [value]="op.id">
                            {{ op.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_2)">
            <mat-form-field appearance="outline">
                <mat-label>{{ 'hierarchy.level_2' | translate }}<span
                        class="obligatorio"></span></mat-label>
                <mat-select formControlName="idClient" (selectionChange)="updateFormState('idClient')">
                    <mat-option [value]="null"></mat-option>
                    <mat-option *ngFor="let cl of clientsList" [value]="cl.id">
                        {{ cl.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-6" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_3)">
            <mat-form-field appearance="outline">
                <mat-label>{{ 'hierarchy.level_3' | translate }}<span
                        class="obligatorio"></span></mat-label>
                <mat-select formControlName="idSubClient">
                    <mat-option [value]="null"></mat-option>
                    <mat-option *ngFor="let subCl of subClientsList" [value]="subCl.id">
                        {{ subCl.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</form>