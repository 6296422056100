<div class="edit-panel">
  <div class="header">
    <div class="title">{{ 'subClient.edit' | translate }}</div>
    <div class="action-buttons">
      <ng-container *ngIf="!readOnly">
        <button *ngIf="rol.canByInput(rol.functIds.edit_subclients)" mat-flat-button class="custom-add-button"
          (click)="onFormSubmit()" [disabled]="registerForm.pristine || registerForm.invalid">{{ 'editPanel.save' |
          translate }}</button>
      </ng-container>
      <button class="close-button" (click)="formExit()">{{ 'editPanel.close' | translate }}</button>
    </div>
  </div>

  <!-- Formulario -->
  <form [formGroup]="registerForm">
    <!-- Details Section -->
    <fieldset class="edit-container bordered">
      <legend class="details-header">
        <label class="title-small">{{ 'subClient.details' | translate }}</label>
      </legend>
      <div class="edit-content">
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>{{ 'subClient.name' | translate }}<span class="obligatorio"></span></mat-label>
                <input matInput class="form-field" formControlName="name" type="text" required>
                <mat-error *ngIf="registerForm.get('name')?.hasError('required')">{{ 'error.required.field' | translate
                  }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>{{ 'subClient.cod' | translate }}</mat-label>
                <input matInput class="form-field" formControlName="cod" type="text">
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- Dropdown para seleccionar operadores -->
        <div class="row" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_1)">
          <div class="col-12">
            <div class="form-group">
              <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>{{ 'subClient.operator' | translate }}<span class="obligatorio"></span></mat-label>
                <mat-select formControlName="idOperator" required>
                  <mat-option *ngFor="let operator of operators" [value]="operator.id">
                    {{ operator.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="registerForm.get('idOperator')?.hasError('required')">{{ 'error.required.field' |
                  translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- Selección de cliente -->
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>{{ 'subClient.client' | translate }}<span class="obligatorio"></span></mat-label>
                <mat-select formControlName="idClient" required>
                  <mat-option *ngFor="let client of clients" [value]="client.id">
                    {{ client.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="registerForm.get('idClient')?.hasError('required')">{{ 'error.required.field' |
                  translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- Resto de los campos -->
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>{{ 'subClient.cif' | translate }}</mat-label>
                <input matInput class="form-field" formControlName="cif" type="text">
              </mat-form-field>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>{{ 'subClient.localidad' | translate }}</mat-label>
                <input matInput class="form-field" formControlName="localidad" type="text">
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>{{ 'subClient.codPostal' | translate }}</mat-label>
                <input matInput class="form-field" formControlName="codPostal" type="text">
              </mat-form-field>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>{{ 'subClient.web' | translate }}</mat-label>
                <input matInput class="form-field" formControlName="web" type="text">
                <mat-error *ngIf="registerForm.get('web')?.hasError('pattern')">{{ 'error.pattern' | translate
                  }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>{{ 'subClient.email' | translate }}</mat-label>
                <input matInput class="form-field" formControlName="email" type="email">
                <mat-error *ngIf="registerForm.get('email')?.hasError('email')">{{ 'error.email' | translate
                  }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>{{ 'subClient.phone' | translate }}</mat-label>
                <input matInput class="form-field" formControlName="phone" type="text">
                <mat-icon matSuffix matTooltip="{{ 'info.phoneValidation' | translate }}" class="custom-icon-color">info</mat-icon>
                <mat-error *ngIf="registerForm.get('phone')?.hasError('pattern')">{{ 'error.pattern' | translate
                  }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>{{ 'subClient.personContact' | translate }}</mat-label>
                <input matInput class="form-field" formControlName="personContact" type="text">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </fieldset>

    <!-- Footer Section -->
    <div class="footer" *ngIf="!readOnly">
      <button *ngIf="rol.canByInput(rol.functIds.delete_subclients)" mat-stroke-button class="custom-white-button"
        (click)="onDelete(data)">
        {{ 'editPanel.deleteVersion' | translate }}
        <img class="delete-icon" src="assets/img/delete_icon.svg" alt="Delete icon">

      </button>
    </div>
  </form>
</div>