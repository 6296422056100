import { EventEmitter, Injectable } from '@angular/core';
import { NavigationSectionType } from '../util/constants';



@Injectable({
  providedIn: 'root'
})
export class EventsService {
    
  navigationChange = new EventEmitter<NavigationSectionType>()

  navigateTo(section:NavigationSectionType){
    this.navigationChange.emit(section);
  }
  
}
