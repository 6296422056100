<main id="main" class="main">
  <div class="pagetitle">
    <h1>{{ 'users.title' | translate }}</h1>
    <div>
      <button mat-raised-button class="custom-white-button" (click)="refrescar()">
        <i class="bi bi-arrow-clockwise"></i> {{ 'common.refresh' | translate }}
      </button>
      <ng-container *ngIf=" rol.canByInput(rol.functIds.edit_users)">
        <button mat-raised-button class="custom-add-button" (click)="onNew()">
          <i class="bi bi-plus"></i> {{ 'users.newUser' | translate }}
        </button>
      </ng-container>
    </div>
  </div>

  <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

  <div id="tableDescription" style="display:none;">{{ 'users.tableDescription' | translate }}</div>

  <div class="mat-elevation-z2 tablaPersonal">
    <table mat-table [dataSource]="dataSource" aria-describedby="tableDescription" matSort
      (matSortChange)="announceSortChange($event)" matTableExporter [hiddenColumns]="getNotExportColumns()" #exporter="matTableExporter">




      <!-- nickName Column -->
      <ng-container matColumnDef="nickName">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'users.nickName' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.nickName}} </td>
      </ng-container>

      <!-- name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
          <div mat-sort-header>{{ 'users.name' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="tamano_columna_max" title="{{element.name}}"> {{element.name}}
        </td>
      </ng-container>

      <!-- identifier Column
      <ng-container matColumnDef="identifier">
        <th mat-header-cell *matHeaderCellDef>
          <div>{{ 'users.identification' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.identification}} </td>
      </ng-container> -->

      <!-- rol_name Column -->
      <ng-container matColumnDef="rol_name">
        <th mat-header-cell *matHeaderCellDef>
          <div>{{ 'users.rolName' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.rol?.name}} </td>
      </ng-container>

      <!-- operator_name Column -->
      <ng-container matColumnDef="idOperator" *ngIf="isAdmin">
        <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
          <div>{{ 'users.operator' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="tamano_columna_max" title="{{element.operator?.name}}">
          {{element.operator?.name}}
        </td>
      </ng-container>

      <!-- Client Column -->
      <ng-container matColumnDef="idClient">
        <th mat-header-cell *matHeaderCellDef class="mat-column-region">
          <div>{{ 'hierarchy.level_2' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="mat-column-region"> {{element.client?.name }}
        </td>
      </ng-container>

      <!-- SubClient Column -->
      <ng-container matColumnDef="idSubClient">
        <th mat-header-cell *matHeaderCellDef class="mat-column-region">
          <div>{{ 'hierarchy.level_3' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="mat-column-region"> {{element.subClient?.name }}
        </td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'users.email'  | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.email }}
        </td>
      </ng-container>

      <!-- status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef style="width: 60px">
          <div>{{ 'users.status' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" style="width: 60px">
          <!-- Verde para ACTIVO -->
          <span *ngIf="!element.dateDelete" class="status-active">
            <div class="status-circle-container">
              <span class="status-circle"></span>
              {{ 'users.active' | translate }}
            </div>
          </span>

          <!-- Rojo para BLOQUEADO -->
          <span *ngIf="element.dateDelete" class="status-blocked">
            <div class="status-circle-container">
              <span class="status-circle"></span>
              {{ 'users.blocked' | translate }}
            </div>
          </span>
        </td>
      </ng-container>


      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef style="width:120px;"></th>
        <td mat-cell *matCellDef="let element" class="button" style="width:120px;">
          <div>
            <ng-container *ngIf="rol.canByInput(rol.functIds.edit_users)">
              <button matTooltip="{{ 'common.view' | translate }}" (click)="onEdit(element)" mat-icon-button
                class="iconoTabla">
                <mat-icon>chevron_right</mat-icon>
              </button>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <!-- Empty state -->
    <ng-container *ngIf="!dataSource.data.length">
      <app-table-empty-state></app-table-empty-state>
    </ng-container>

    <button id="export" class="icoDownload" mat-icon-button
      (click)="exporter.exportTable('xlsx', { fileName: exportFileName })"><i class="bi bi-file-earmark-arrow-down"></i>
    </button>

    <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
      (page)="pageEvent($event)" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</main>