export enum COMMAND_TYPES {
    BLOCK="BLOCK",
    UNBLOCK="UNBLOCK",
    RESET="RESET",
    ERASE="ERASE",
    SWITCHOFF="SWITCHOFF",
    UNINSTALL="UNINSTALL",
    ACTIVATE_LOG="ACTIVATE_LOG",
    REQUEST_LOG="REQUEST_LOG",
    FORCE_CHECKINFO="FORCE_CHECKINFO"
 }
 
export enum LEVEL_TYPES {
    TRACE = "TRACE",
    DEBUG = "DEBUG",
    INFO = "INFO",
    WARN = 'WARN',
    ERROR = "ERROR",
    OFF = 'OFF'
 }

export interface CommandParam {
    command: COMMAND_TYPES;
    packageName?: string;
    level?:LEVEL_TYPES;
    recurrent?:boolean;
}

export class Command {
    machines: { id: number }[] = [];
    params: CommandParam[] = [];
    idPerfil?: number;

}