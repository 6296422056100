<main class="main">
    <div class="pagetitle">
        <h1> {{ 'installedApps.title' | translate }} </h1>
    </div>

    
    <app-filter [fields]="filterColumns" (onApply)="applyFilter($event)" [multiple]="false"></app-filter>

    <div id="tableDescription" hidden> {{ 'sells.tableDescription' | translate }}</div>
    <div class="mat-elevation-z2 tablaPersonal">
        <table mat-table matTableExporter [dataSource]="dataSource" aria-describedby="tableDescription" matSort #exporter="matTableExporter">
            <!-- appLabel Column -->
            <ng-container matColumnDef="appLabel">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header>{{ 'installedApps.appLabel' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" [title]="element.appLabel"> {{ element.appLabel }} </td>
            </ng-container>
        
            <!-- packageName Column -->
            <ng-container matColumnDef="packageName">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header>{{ 'installedApps.packageName' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" [title]="element.packageName"> {{ element.packageName }} </td>
            </ng-container>
        
            <!-- versionCode Column -->
            <ng-container matColumnDef="versionCode">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header>{{ 'installedApps.versionCode' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" [title]="element.versionCode"> {{ element.versionCode | translate }} </td>
            </ng-container>

            <!-- versionName Column -->
            <ng-container matColumnDef="versionName">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header>{{ 'installedApps.versionName' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" [title]="element.versionName"> {{ element.versionName | translate }} </td>
            </ng-container>

                    
            <!-- commonParamVersion Column -->
            <ng-container matColumnDef="commonParamVersion">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header>{{ 'installedApps.commonParamVersion' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" [title]="element.commonParamVersion"> {{ element.commonParamVersion | translate }} </td>
            </ng-container>

            <!-- specificParamVersion Column -->
            <ng-container matColumnDef="specificParamVersion">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header>{{ 'installedApps.specificParamVersion' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" [title]=" element.specificParamVersion"> {{ element.specificParamVersion | translate }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    
        <!-- Empty state -->
        <ng-container *ngIf="!dataSource.data.length">
            <app-table-empty-state></app-table-empty-state>
        </ng-container>
    
        <button class="icoDownload" mat-icon-button (click)="exporter.exportTable('xlsx', { fileName: exportFileName })"><i
            class="bi bi-file-earmark-arrow-down"></i>
        </button>
        
        <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
      </div>
</main>