import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { StadisticBean } from 'src/app/models/stadisticBean.model';
import { RestService } from 'src/app/services/merchant/Rest.service';


@Injectable({
  providedIn: 'root',
})
export class StadisticService {

  constructor(private merchantRest : RestService,private authService:AuthService) {}

 
  
  async find(_body: StadisticBean ){

    let userSession = this.authService.currentUserValue();
    _body.idEntorno=userSession!.entorno!.id
          
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'generateReport/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }



}