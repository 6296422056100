import { VendingMachineAtento } from "./vendingMachineAtento.model";

export class GetFile {
	id!:number;
  	machines!: VendingMachineAtento[];
	listIdAtento!:string[];
	listFile!:FileInfoGet[];	
}

export class FileInfoGet {
	packageName!: string;
	storageType!: string;	
	path!: string;
	fileName!: string;
}
