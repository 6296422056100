import { conversionByteToMegaByte } from "src/app/util/util";

export class CosumeChartPeriod {
    period:string;
    txData: number;
    rxData: number;

 
    constructor(period:string,txData:number,rxData:number){
        this.period = period;
        this.txData = txData;
        this.rxData = rxData;
    }
 
    private get total(): number {
        return this.txData + this.rxData;
    }
 
    getTxSeriesData():{ x:string, y:(number|null) }{
        return {
            x: this.period,
            y: conversionByteToMegaByte(this.txData)
          }
    }

    getRxSeriesData():{ x:string, y:(number|null) } {
        return {
            x: this.period,
            y: conversionByteToMegaByte(this.rxData)
          }
    }
    
    getTotalSeriesData():{ x:string, y:(number|null) } {
        return {
            x: this.period,
            y: conversionByteToMegaByte(this.total)
          }
    }

 }