<div class="pagetitle">
    <h2>{{ 'monitoring.uploadFile' | translate }}</h2>
</div>

<div class="terminals-container">
    <app-terminals-selector [(machines)]="terminals" [fixDropZone]="true" [hideButtons]="true"></app-terminals-selector>
</div>

<div class="botonera mt-3">
    <button mat-stroke-button class="custom-white-button" (click)="cancelChanges()">
        {{ 'common.cancel' | translate }}
    </button>
    <button mat-flat-button class="custom-add-button" [disabled]="!terminals.length" (click)="submitChanges()">
        {{ 'common.submit' | translate }}
    </button>
</div>