import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NotifierService } from 'angular-notifier';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { Aplications } from 'src/app/models/aplications.model';
import { AppsService } from '../aplications.service';
import { FormCreateActions } from 'src/app/util/constants';
import { FirmwareUpload } from 'src/app/models/firmwareUpload.model';
import { convertFileToBase64, extractVersion } from 'src/app/util/util';
import { TranslateService } from '@ngx-translate/core';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';
import { Tag } from '../../tags/tag.model';
import { Factories, TerminalModel } from 'src/app/models/factories.model';
import { FactoriesService } from 'src/app/services/factories.service';

@Component({
  selector: 'app-new-app',
  templateUrl: './new-app.component.html',
  styleUrls: ['./new-app.component.css']
})
export class NewAppComponent implements OnInit {
  isLinear = true;
  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  selectedFile: File | null = null; isEdit: boolean = false;
  idUser: number | undefined = undefined;
  uploadProgress = 0;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  isCloseParam: boolean = false;
  dialogNewParam: any;
  fileUploadError: boolean = false;


  factories?: Factories;
  terminalModels: TerminalModel[] = [];
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogNewApp: MatDialogRef<NewAppComponent>,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private service: AppsService,
    private translate: TranslateService,
    private factoriesService:FactoriesService,
    public hierarchy: HierarchyHandlerService,
  ) {}

  private async loadFactoriesData(){
    const response = await this.factoriesService.getFactoriesList();
    if(response && response.data){
      this.factories = new Factories(response.data);
    }
  }

  async ngOnInit(): Promise<void> {
    this.loadFactoriesData();
    this.firstFormGroup = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required,Validators.maxLength(50)])],
      description: ['', Validators.compose([Validators.required, Validators.maxLength(200)])],
      packageName: ['', Validators.compose([Validators.required,Validators.maxLength(50)])],
      tags: [[]],
      hierarchy:[{ idOperator:null, idClient:null, idSubClient:null }],
      manufacID: [null, Validators.required],
      modelNumber: [null]
    });

    this.secondFormGroup = this.formBuilder.group({
      uploadFiles: [null, Validators.required], // Requerir archivo
      fileBase64: ['', Validators.required],
      version: [{value:'', disabled:true}, Validators.compose([Validators.required,Validators.maxLength(25)])]
    });

    this.firstFormGroup.get('manufacID')?.valueChanges.subscribe((value)=>{
      this.terminalModels = this.factories ? this.factories.getModelList(value) : [];
    });

  }

  private mapFileToBase64(file: File) {
    convertFileToBase64(file).then((base64String) => {
      if (!base64String) {
        this.fileUploadError = true;
        this.notifier.notify('warning', this.translate.instant('error.fileEmpty'));
        return;
      }

      this.selectedFile = file;
      this.fileUploadError = false; // Ocultar error al seleccionar un archivo
      this.secondFormGroup.patchValue({
        uploadFiles: this.selectedFile,
        fileBase64: base64String,
        version: extractVersion(file.name) // Extraer versión del nombre del archivo
      });
      this.secondFormGroup.get('version')?.enable();
      this.secondFormGroup.get('uploadFiles')?.markAsTouched();
    }).catch(() => {
      this.fileUploadError = true;
      this.notifier.notify('warning', this.translate.instant('error.fileInvalid'))
    });
  }

  async onFileSelected(files: File[]): Promise<void> {
    if (files.length > 0) {
      const file = files[0];
      this.mapFileToBase64(file);
    }
  }

  deleteFile(): void {
    this.selectedFile = null; // Eliminar archivo seleccionado
    this.secondFormGroup.get('uploadFiles')?.reset();
    this.secondFormGroup.get('version')?.reset();
    this.secondFormGroup.get('version')?.disable();
    this.fileUploadError = true; // Mostrar error si no hay archivo
  }

  async onFormSubmit(): Promise<void> {
    if (this.firstFormGroup.invalid || this.secondFormGroup.invalid || !this.selectedFile) {
      this.fileUploadError = !this.selectedFile; // Si no hay archivo, mostrar el error
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }

    const formData = {
      ...this.firstFormGroup.getRawValue(),
      ...this.secondFormGroup.value,
      selectedFile: this.selectedFile?.name,
      fileBase64: this.secondFormGroup.get('fileBase64')?.value,
      version: this.secondFormGroup.get('version')?.value
    };

    let objNew: FirmwareUpload = new FirmwareUpload();
    let lstAppNew: Aplications[] = [];
    let appNew: Aplications = new Aplications();

    appNew.name = formData.name;
    appNew.description = formData.description;
    appNew.fileName = formData.selectedFile;
    appNew.pakage = formData.packageName.trim();
    appNew.version = formData.version;
    appNew.base64 = formData.fileBase64;
    appNew.listEtiquetas = formData.tags.length ? formData.tags.map((tag: Tag) => ({ id: tag.id })) : [];
    
    appNew.manufacID = formData.manufacID;
    appNew.modelNumber = formData.modelNumber;
    
    const { idOperator, idClient, idSubClient } = formData.hierarchy;
    objNew.idOperator = idOperator;
    objNew.idClient = idClient;
    objNew.idSubClient = idSubClient;

    lstAppNew[0] = appNew;
    objNew.fileList = lstAppNew;


    const dialogData = new ConfirmDialogData();

    dialogData.titleI18n = this.translate.instant('confirmDialog.createAppTitle');
    dialogData.textI18n = this.translate.instant('confirmDialog.createAppText');
    dialogData.svgIcon = 'assets/img/confirm._icon.svg';

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        const response = await this.service.addUpdate(objNew);
        if (response && response.status >= 0) {
          this.dialogNewApp.close(FormCreateActions.SAVED);
          if(response.status === 0) this.notifier.notify('success', this.translate.instant('success.appCreated'));
          if(response.status === 1) this.notifier.notify('success', this.translate.instant('success.versionSaved'));
        }
      }
    });
  }

  formExit(): void {
    this.dialogNewApp.close(FormCreateActions.EXIT);
  }

}
