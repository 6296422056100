<main id="main" class="main">
   <div class="pagetitle">
      <h1 i18n>{{'userProfile.title' | translate }}</h1>
   </div>
   <section class="section profile">
      <div class="row">
         <div class="col-xl-4">
            <div class="card">
               <div class="card-body profile-card pt-4 d-flex flex-column align-items-center">
                  <span class="profilelogo big"> </span>
                  <h2>{{userProfile.name}}</h2>
                  <h3 *ngIf="userProfile.hierarchy">{{userProfile.hierarchy}}</h3>
                  <h3>{{ 'userProfile.rol' | translate }}: {{userProfile.rolName}}</h3>
               </div>
            </div>
         </div>
         <div class="col-xl-8">
            <div class="card">
               <div class="card-body pt-3">
                  <mat-tab-group animationDuration="0ms" selectedIndex="0" (selectedTabChange)="changeTab()">  
                     <mat-tab>
                       <ng-template mat-tab-label>
                         <span>{{'userProfile.overview' | translate }}</span>
                       </ng-template>
                       <div class="profile-overview profile-tab-container my-4">
                           <div class="row my-4">
                              <div class="col-lg-3 col-md-4 label">{{'userProfile.username' | translate }}</div>
                              <div class="col-lg-9 col-md-8">{{userProfile.nickName}}</div>
                           </div>
                           <div class="row my-4">
                              <div class="col-lg-3 col-md-4 label">{{'userProfile.fullName' | translate }}</div>
                              <div class="col-lg-9 col-md-8">{{userProfile.name}}</div>
                           </div>
                           <div class="row my-4">
                              <div class="col-lg-3 col-md-4 label">{{'userProfile.email' | translate }}</div>
                              <div class="col-lg-9 col-md-8">{{userProfile.email}}</div>
                           </div>
                           <div class="row my-4">
                              <div class="col-lg-3 col-md-4 label">{{'userProfile.phone' | translate }}</div>
                              <div class="col-lg-9 col-md-8">{{userProfile.phone}}</div>
                           </div>
                           <div class="row my-4">
                              <div class="col-lg-3 col-md-4 label">{{'userProfile.language' | translate }}</div>
                              <div class="col-lg-9 col-md-8"> {{ 'languages.'+userProfile.lang | translate }} </div>
                           </div>
                        </div>
                     </mat-tab>
                     <mat-tab>
                        <ng-template mat-tab-label>
                          <span>{{ 'userProfile.editProfile' | translate }}</span>
                        </ng-template>
                        <div class="profile-edit profile-tab-container my-4">
                           <form [formGroup]="registerForm">
                              <div class="row">
                                 <label for="firstname" class="col-md-4 col-lg-3 col-form-label" i18n>{{'userProfile.fullName' | translate }}</label>
                                 <div class="col-md-8 col-lg-9">
                                    <mat-form-field appearance="outline" class="w-100">
                                       <input matInput name="firstname" type="text" formControlName="firstname" id="firstname"/>
                                    </mat-form-field> 
                                 </div>
                              </div>
                              <div class="row">
                                 <label for="Email" class="col-md-4 col-lg-3 col-form-label" i18n>{{'userProfile.email' | translate }}</label>
                                 <div class="col-md-8 col-lg-9">
                                    <mat-form-field appearance="outline" class="w-100">
                                       <input matInput name="email" type="email" id="email" formControlName="email"/>
                                    </mat-form-field>
                                 </div> 
                              </div>
                              <div class="row">
                                 <label for="Phone" class="col-md-4 col-lg-3 col-form-label" i18n>{{'userProfile.phone' | translate }}</label>
                                 <div class="col-md-8 col-lg-9">
                                    <mat-form-field appearance="outline" class="w-100">
                                       <input matInput name="phone" type="text" id="phone" formControlName="phone"/>
                                       <mat-icon matSuffix matTooltip="{{ 'info.phoneValidation' | translate }}" class="custom-icon-color">info</mat-icon>
                                       <mat-error *ngIf="registerForm.get('phone')?.hasError('pattern')">{{ 'error.pattern' | translate }}</mat-error>
                                    </mat-form-field> 
                                 </div>
                              </div>
                              <div class="row">
                                 <label for="Phone" class="col-md-4 col-lg-3 col-form-label" i18n>{{'userProfile.language' | translate }}</label>
                                 <div class="col-md-8 col-lg-9">
                                    <mat-form-field appearance="outline" class="w-100">
                                       <mat-select name="lang" id="lang" formControlName="lang">
                                          <mat-option *ngFor="let language of languages" [value]="language">
                                             {{ 'languages.'+language | translate }}
                                          </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                 </div>
                              </div>
   
                              <div class="text-end">
                                 <button type="btn btn-aceptar" class="btn btn-primary" (click)="onFormSubmit()" i18n>{{'userProfile.saveChanges' | translate }}
                                    </button>
                              </div>
                           </form>
                        </div>
                     </mat-tab>

                     <mat-tab>
                        <ng-template mat-tab-label>
                          <span>{{'userProfile.changePassword' | translate }}</span>
                        </ng-template>
                        <div class="profile-edit profile-tab-container my-4">
                           <form id="change-password"  autocomplete="off" [formGroup]="registerFormPass">
                              <div class="row mb-3">
   
                                 <h5 class="col-md-4 col-lg-3 col-form-label">{{'userProfile.newPassword' | translate }} <span class="obligatorio">*</span></h5>
                                 <div class="col-md-8 col-lg-9">
                                    <mat-form-field appearance="outline" class="w-100">
                                       <input matInput formControlName="newpass" name="newpass" [type]="hidePassword ? 'password' : 'text'"/>
                                       <button mat-icon-button matSuffix (click)="hidePassword=!hidePassword;hideComparePassword=true" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                                          <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                                       </button>
                                       <mat-hint>{{'userProfile.errors.patternPassword' | translate }} </mat-hint>
                                       <mat-error *ngIf="registerFormPass.get('newpass')?.hasError('required')">{{'userProfile.errors.requiredPassword' | translate }}</mat-error>
                                       <mat-error *ngIf="registerFormPass.get('newpass')?.hasError('pattern')">{{'error.pattern' | translate }}</mat-error>
                                       <mat-error *ngIf="registerFormPass.get('newpass')?.hasError('minlength')">{{'userProfile.errors.minLengthPassword' | translate }}</mat-error>
                                       <mat-error *ngIf="registerFormPass.get('newpass')?.hasError('maxlength')">{{'userProfile.errors.maxLengthPassword' | translate }}</mat-error>
                                    </mat-form-field>
                                 </div>
                              </div>
                              <div class="row mb-3">
                                 <h5 class="col-md-4 col-lg-3 col-form-label" >{{'userProfile.confirmNewPassword' | translate }} <span class="obligatorio">*</span></h5>
                                 <div class="col-md-8 col-lg-9">
                                    <mat-form-field appearance="outline" class="w-100">
                                       <input matInput formControlName="repeatpass" name="repeatpass" [type]="hideComparePassword ? 'password' : 'text'"/>
                                       <button mat-icon-button matSuffix (click)="hideComparePassword=!hideComparePassword;hidePassword=true" [attr.aria-label]="'Hide compare password'" [attr.aria-pressed]="hideComparePassword">
                                          <mat-icon>{{hideComparePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                                       </button>
                                       <mat-hint>{{'userProfile.errors.patternPassword' | translate }} </mat-hint>
                                       <mat-error *ngIf="registerFormPass.get('repeatpass')?.hasError('required')">{{'userProfile.errors.requiredPassword' | translate }}</mat-error>
                                       <mat-error *ngIf="registerFormPass.get('repeatpass')?.hasError('pattern')">{{'error.pattern' | translate }}</mat-error>
                                       <mat-error *ngIf="registerFormPass.get('repeatpass')?.hasError('minlength')">{{'userProfile.errors.minLengthPassword' | translate }}</mat-error>
                                       <mat-error *ngIf="registerFormPass.get('repeatpass')?.hasError('maxlength')">{{'userProfile.errors.maxLengthPassword' | translate }}</mat-error>
                                       <mat-error *ngIf="registerFormPass.get('repeatpass')?.hasError('passNocoincide')" >{{'userProfile.errors.passwordMismatch' | translate }}</mat-error>
                                    </mat-form-field>
                                 </div>
                              </div>
   
                              <div class="text-end"> 
                                 <button class="btn btn-primary" type="submit" (click)="onFormChangePass()">
                                    {{'userProfile.saveChanges' | translate }}
                                 </button>
                              </div>
                           </form>
                        </div>
                     </mat-tab>
                  </mat-tab-group>
                  
               </div>
            </div>
         </div>
      </div>
   </section>
</main>