import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { UserHistory} from 'src/app/models/userHistory.model';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { HistoryUserService } from './historyUser.service';
import { FilterAndPagBean, FilterableValue } from 'src/app/models/FilterAndPagBean';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TabsComponent } from '../../monitoring/tabs/tabs.component';
import { MonitoringService } from '../../monitoring/monitoring.service';
import { AppliedFilter, FieldType, FilterField, FilterOption } from 'src/app/models/filter.model';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { UsersService } from 'src/app/pages/admin/users/users.service';
import { Users } from 'src/app/models/users.model';
import { TranslateService } from '@ngx-translate/core';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { UtilService } from 'src/app/services/util.service';
import { Operator } from 'src/app/models/operator.model';

const BaseColumns = {
  dateCreate: 'dateCreate',
  idVM: 'vm.id',
  idOperator:'idOperator',
  idUser: 'user.name',
  operation: 'operation',
  stringLog: 'stringLog',
  view: 'view'
};

const { idOperator, ...Columns} = BaseColumns;

@Component({
  selector: 'app-history-user',
  templateUrl: './history-user.component.html',
  styleUrls: ['./history-user.component.css'],
  providers:[{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})
export class HistoryUserComponent implements OnInit, AfterViewInit {
  private dialogRef!: MatDialogRef<TabsComponent>;
  displayedColumns = Object.values(Columns);
  dataSource = new MatTableDataSource<UserHistory>();
  isOnTerminalContext:boolean = false;
  isAdmin: boolean = true;
  exportFileName: string | undefined;
  pageLength = 0;
  pageSize = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  sortId: string | null = Columns.dateCreate + "";
  direccion: string | null = "DESC";

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  
  opertionOptions = () => {
    return Array(74).fill("").map((_, idx) => ({ value: idx, label: this.translate.instant(`historico.operation.${idx}`)}));
  }
  
  FILTER_COLUMNS: { field: string, type: FieldType, options?:FilterOption[] }[] = [
    { field: 'operation', type: 'number', options: this.opertionOptions()},
    { field: 'stringLog', type: 'string' },
    { field: 'dateCreate', type: 'date' },
  ];
  
  filterColumns:FilterField[] = this.FILTER_COLUMNS.map((column)=>{
    const fielterField = new FilterField(column.field, `historyUser.${column.field}`, column.type, column.options);
    return fielterField;
  })
  filterList: FilterableValue[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: { terminalId:number },
    private servicios: HistoryUserService,
    public dialog: MatDialog,  
    public commonService: CommonService,
    private monitoringService: MonitoringService,
    private users:UsersService,
    public rol:RolHandlerService,
    private translate:TranslateService,
    public utilService:UtilService

  ) {}

  async cargar(){   
    //operadores
    this.utilService.findOperators().subscribe((operators:Operator[]) => {
      const operationsOptions = operators.map((op:Operator)=>{ return { label:op.name, value:(op.id as number) }});
      this.filterColumns.push(new FilterField('idOperator', 'historyUser.operator', 'long', operationsOptions));
    });
  }

  async ngOnInit(): Promise<void> {
    if(this.rol.isAdmin() && !this.data.terminalId){
      this.displayedColumns = Object.values(BaseColumns);
      await this.cargar();
    }
    this.dataSource.paginator = this.paginator;
    this.exportFileName = this.translate.instant('export.fileUserHistory');
    this.dataSource.sort = this.sort;
    this.hasMachineContext();
    this.loadUsers();
    this.lanzarLLamada();
  }

      
  private getFilterableValue(column:string): FilterableValue | undefined {
    return this.filterList.find((filter) => filter.column === column);
  }

  async lanzarLLamada() {
    const idVMFilter = this.getFilterableValue(BaseColumns.idVM);
    // Si se recibe un objeto y no existe el filtro de idVM
    if (this.data.terminalId && !idVMFilter) {
      const idVM = this.data.terminalId;
      this.filterList.push(new FilterableValue(BaseColumns.idVM, idVM, "long", "EQUALS"));
    }
  
    const paginatorState = this.getPaginatorState();
    const request = new FilterAndPagBean(
      this.direccion,
      this.sortId,
      this.filterList,
      paginatorState.startPage,
      paginatorState.endPage,
      paginatorState.pageIndex
    );
  
    let response = await this.servicios.find(request);
    let pagCount = await this.servicios.countTotal(request);
  
    if (response && response.data) {
      this.dataSource = new MatTableDataSource<UserHistory>(response.data);
      if (pagCount) {
        this.pageLength = pagCount.data?.total ?? 0;
      }
    } 
  }


  private async hasMachineContext(){
    // Verificamos si la máquina está asociada
    const idVM = this.data.terminalId;
    if (idVM){
      this.isOnTerminalContext = true;
      const machine = await this.getMachine(idVM);
      if (machine && machine.data) {
        // Ocultamos la columna de la tabla
        this.displayedColumns = this.displayedColumns.filter(col => (col !== BaseColumns.idVM));
        return;
      }
    } 
    
    this.loadMachines();
  }
  
  private async loadMachines(){
    const request = new UsersConsoleRequest;
    request.filterAndPagBean = new FilterAndPagBean(null,null,[],null,null,null);
    const response = await this.monitoringService.find(request);
    if(response && response.data){
      const machinesOptions = response.data.map((machine:VendingMachineAtento)=> new FilterOption(machine.serialNumber as string, machine.id as number)); 
      this.filterColumns.unshift(new FilterField(BaseColumns.idVM,'common.terminalSN', 'long', machinesOptions));
    }
  }

  private async loadUsers(){
    const request = new UsersConsoleRequest;
    request.filterAndPagBean = new FilterAndPagBean(null,null,[],null,null,null);
    const response = await this.users.find(request);
    if(response && response.data){
      const userOptions = response.data.map((user:Users)=> new FilterOption(user.name as string, user.id as number)); 
      this.filterColumns.push(new FilterField('user.id','historyUser.user', 'long', userOptions));
    }
  }
  

  private getPaginatorState(){
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize:this.pageSize };
    const startPage = pageIndex*pageSize;
    const endPage = startPage+pageSize;

    return { startPage, endPage, pageIndex };
  }

  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent() {
    this.lanzarLLamada();
  }

  announceSortChange(sortState: Sort) {
    let active = sortState.active.replace("_", ".");
    this.sortId = active;

    if (sortState.direction) {
      this.direccion = sortState.direction.toUpperCase();
    } else {
      this.direccion = null;
      this.sortId = null;
    }
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  refrescar() {
    this.lanzarLLamada();
  }

  async getMachine(idVM:number) {
    return await this.monitoringService.getMachine(idVM);
  }

  onView(row: UserHistory): void {
    if(!row.vm?.id) return;

    this.getMachine(row.vm.id).then(response => {
      if(!response) return;

      const terminal = response.data;
      this.dialogRef = this.dialog.open(TabsComponent, {
        width: '90%',
        height: '95%',
        panelClass: 'custom-modalbox-big',
        data: { terminalId: terminal.id, terminalName:terminal.serialNumber }
      });

      this.dialogRef.afterClosed().subscribe(result => {
        if (result === 1) {
          console.log('CONFIRM received from dialog window');
        } else if (result === 0) {
          console.log('CANCEL received from dialog window');
        }
      });
    }).catch(error => {
      console.error('Error fetching data: ', error);
    });
  }
}
