<div class="pagetitle">
    <h1 *ngIf="isEdit">{{ 'users.edit' | translate }}</h1>
    <h1 *ngIf="!isEdit">{{ 'users.add' | translate }}</h1>
</div>
<section class="section">
    <form [formGroup]="userForm">
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'users.nickName' | translate }}<span class="obligatorio"></span></mat-label>
                        <input formControlName="username" matInput type="text" required>
                        <mat-error *ngIf="userForm.get('username')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                        <mat-error *ngIf="userForm.get('username')?.hasError('maxLength')">{{ 'error.maxLength' | translate }}</mat-error>
                        <mat-error *ngIf="userForm.get('username')?.hasError('pattern')">{{ 'error.pattern' | translate }}</mat-error>
                   
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'users.name' | translate }}<span class="obligatorio"></span></mat-label>
                        <input formControlName="name" matInput type="text" required>
                        <mat-error *ngIf="userForm.get('name')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                        <mat-error *ngIf="userForm.get('name')?.hasError('pattern')">{{ 'error.pattern' | translate }}</mat-error>
                        <mat-error *ngIf="userForm.get('name')?.hasError('maxLength')">{{ 'error.maxLength' | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'users.email' | translate }}<span class="obligatorio"></span></mat-label>
                        <input formControlName="email" matInput type="text" required>
                        <mat-error *ngIf="userForm.get('email')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                        <mat-error *ngIf="userForm.get('email')?.hasError('maxLength')">{{ 'error.maxLength' | translate }}</mat-error>
                        <mat-error *ngIf="userForm.get('email')?.hasError('email')">{{ 'error.email' | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'users.rol' | translate }}<span class="obligatorio"></span></mat-label>
                        <mat-select formControlName="idRol">
                            <mat-option *ngFor="let rol of listaRol" [value]="rol.id">
                                {{ rol.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="userForm.get('idRol')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-6" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_3)">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'users.hierarchy' | translate }}<span class="obligatorio"></span></mat-label>
                        <mat-select formControlName="hierarchyLevel">
                            <mat-option *ngFor="let opt of hierarchyOptions" [value]="opt.value">{{ opt.label | translate }}</mat-option>
                        </mat-select>
                         <mat-error *ngIf="userForm.get('hierarchyLevel')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="row">
            <div *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_1)">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'users.operator' | translate }}<span class="obligatorio"></span></mat-label>
                        <mat-select formControlName="idOperator">
                            <mat-option *ngFor="let op of listaOpe" [value]="op.id">
                                {{ op.name }}
                            </mat-option>
                        </mat-select>
                         <mat-error *ngIf="userForm.get('idOperator')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_2)">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'users.client' | translate }}<span class="obligatorio"></span></mat-label>
                        <mat-select formControlName="idClient">
                            <mat-option *ngFor="let cl of clientsList" [value]="cl.id">
                                {{cl.name}}
                            </mat-option>
                        </mat-select>
                         <mat-error *ngIf="userForm.get('idClient')?.hasError('required')">{{'error.required.field' | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-6" *ngIf="hierarchy.configureUsersByLevel(hierarchy.level.LEVEL_3)">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'users.subClient' | translate }}<span class="obligatorio"></span></mat-label>
                        <mat-select formControlName="idSubClient">
                            <mat-option *ngFor="let subCl of subClientsList" [value]="subCl.id">
                                {{ subCl.name }}
                            </mat-option>
                        </mat-select>
                         <mat-error *ngIf="userForm.get('idSubClient')?.hasError('required')">{{'error.required.field' | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="button-group">
            <button mat-stroke-button class="custom-white-button" (click)="formExit()">{{ 'common.cancel' | translate }}</button>
            <ng-container *ngIf=" rol.canByInput(rol.functIds.edit_users)">
                <button mat-flat-button class="custom-add-button" (click)="onFormSubmit()">{{ 'common.submit' | translate }}</button>
            </ng-container>
        </div>

    </form>
</section>