import { Component, Inject, OnInit } from '@angular/core';
import { UsersService } from '../users.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { Rol } from 'src/app/models/rol.model';
import { Users } from 'src/app/models/users.model';
import { FormCreateActions, LEVEL_HIERARCHY, RegexpValidators } from 'src/app/util/constants';
import { Operator } from 'src/app/models/operator.model';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/auth/auth.service';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { Client, SubClient } from 'src/app/models/client.model';
import { FilterableValue, FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { ClientService } from '../../client/client.service';
import { SubClientService } from '../../sub-client/sub-client.service';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';
import { RolHandlerService } from 'src/app/services/rol-handler.service';

const HIERARCHY_OPTIONS:{value:LEVEL_HIERARCHY, label:string}[] = [
  { value: LEVEL_HIERARCHY.LEVEL_1, label: 'hierarchy.level_1' },
  { value: LEVEL_HIERARCHY.LEVEL_2, label: 'hierarchy.level_2' },
  { value: LEVEL_HIERARCHY.LEVEL_3, label: 'hierarchy.level_3' },
]

@Component({
  selector: 'app-form-users',
  templateUrl: './form-users.component.html',
  styleUrls: ['./form-users.component.css']
})
export class FormUsersComponent implements OnInit {
  userForm!: FormGroup;

  selectedRol:Rol = new Rol();
  listaRol:Rol[] = [];
  selectedOpe:Operator = new Operator();
  listaOpe:Operator[] = [];
  clientsList: Client[] = [];
  subClientsList: SubClient[] = [];
  hierarchyOptions:{value:LEVEL_HIERARCHY, label:string}[] = []

  isEdit: boolean = false;
  idUser: number | undefined = undefined;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,   
    private notifier: NotifierService,  
    private services: UsersService,
    private utilServicios : UtilService,
    private authService:AuthService,
    private clientService:ClientService,
    private subClientService:SubClientService,
    private translate: TranslateService,
    public hierarchy:HierarchyHandlerService,
    public rol:RolHandlerService,
    public dialogRef: MatDialogRef<FormUsersComponent>,
    public dialog: MatDialog) { 
      this.hierarchyOptions = HIERARCHY_OPTIONS.filter(opt=> (this.hierarchy.configureUsersByLevel(opt.value)));
  }

  async cargar() {    
    // Operadores
    this.utilServicios.findOperators().subscribe((operators:Operator[]) => {
      this.listaOpe = operators;
    });
  
    // Roles
    this.utilServicios.findRol().subscribe((listaRolTemp: Rol[]) => {
      this.listaRol = listaRolTemp;
    });
  }
  
  private async loadClients(idOperator:(number|null) = null):Promise<void>{
    if(!idOperator){
      this.clientsList = [];
      return;
    }
    let filters = [];
    if(idOperator) filters.push(new FilterableValue("idOperator", idOperator, "long", "EQUALS")) 
    const request = new FilterAndPagBean(null,null,filters,null,null, null);
    const response = await this.clientService.getListed(request);
    if(response && response.data) this.clientsList = response.data;
  }


  private async loadSubClients(idClient:(number|null) = null):Promise<void>{
    if(!idClient){
      this.subClientsList = [];
      return;
    }

    let filters = [];
    if(idClient) filters.push(new FilterableValue("idClient", idClient, "long", "EQUALS")) 
    const request = new FilterAndPagBean(null,null,filters,null,null, null);
    const response = await this.subClientService.getListed(request);
    if(response && response.data) this.subClientsList = response.data;
  }

  async ngOnInit(): Promise<void> {
    this.userForm = this.formBuilder.group({
      name: ["", Validators.compose([Validators.required, 
        Validators.maxLength(45),
        Validators.pattern(RegexpValidators.text)
      ])] ,
      email: ["", Validators.compose([Validators.required, 
        Validators.email, 
        Validators.maxLength(255),
      ])]      ,
      username: ["", Validators.compose([Validators.required,
        Validators.minLength(6),
        Validators.maxLength(255),
        Validators.pattern(RegexpValidators.username)
      ])],
     // identifier: [""] ,
      idRol:[null, Validators.required],
      hierarchyLevel:["", Validators.required],
      idOperator:[null, Validators.required],
      idClient:[null, Validators.required],
      idSubClient:[null, Validators.required],
    });
    
    this.userForm.get('hierarchyLevel')?.valueChanges.subscribe((value:number)=>{
      if(value) this.updateSelectsState(value as LEVEL_HIERARCHY);
    });

    this.userForm.get('idOperator')?.valueChanges.subscribe((value: number) => {
      this.userForm.patchValue({ idClient: null });
      this.loadClients(value);
    });

    this.userForm.get('idClient')?.valueChanges.subscribe((value: number) => {
      this.userForm.patchValue({ idSubClient: null });
      this.loadSubClients(value);
    });

    await this.cargar();
    this.updateUserForm();
  }

  private updateUserForm(){
    this.userForm.get('idOperator')?.setValue(this.utilServicios.getOperatorId() ?? null);
    this.userForm.get('idClient')?.setValue(this.utilServicios.getClientId() ?? null);
    this.userForm.get('idSubClient')?.setValue(this.utilServicios.getSubClientId() ?? null);
  }

  onFormSubmit(): void {
    if (this.userForm.invalid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }    
    const data = this.applyFormats(this.userForm);

    let userNew : Users = new Users();

    if(this.isEdit){
      userNew.id = this.idUser;
      userNew.pass = data['password'];
    }
    userNew.name=data['name']  
    userNew.nickName=data['username']   
    userNew.email=data['email']   
    
    if(!this.validateHierarchySelection(data)){
      this.notifier.notify('warning', this.translate.instant('messages.hierarchyError'));
      return;
    }
    
    if(data['idRol']) userNew.rol = { id: data['idRol'] } as Rol;
    if(data['idOperator'] ) userNew.operator = { id: data['idOperator'] } as Operator;
    if(data['idClient']) userNew.client = { id: data['idClient'] } as Client;
    if(data['idSubClient']) userNew.subClient = { id: data['idSubClient'] } as SubClient;


    let userSession = this.authService.currentUserValue();    
    userNew.entorno= userSession.entorno;

    const dialogData = new ConfirmDialogData(); 
    dialogData.titleI18n = this.translate.instant('confirmDialog.createUserTitle');
    dialogData.textI18n = this.translate.instant('confirmDialog.createUserText');
    dialogData.svgIcon = 'assets/img/confirm._icon.svg';


    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        
          console.log('Form SAVE action');
          let val = await this.services.addUpdate(userNew)
          
          if(val!.status>=0){
            if(userNew.id){
              this.notifier.notify('success', this.translate.instant('success.userUpdate')) 
            } else {
              this.notifier.notify('success', this.translate.instant('success.userCreated'));
            }
            this.dialogRef.close(FormCreateActions.SAVED);
          }

      }
    });

  }

  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }

  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body:any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }

  /**
   * Valido requerimientos de inputs según Rol elegido 
   */ 
  private validateHierarchySelection(form:any):boolean{
    const { hierarchyLevel, idOperator, idClient, idSubClient } = form;
    if(Number(hierarchyLevel) === LEVEL_HIERARCHY.LEVEL_1 && !idOperator) return false;
    if(Number(hierarchyLevel) === LEVEL_HIERARCHY.LEVEL_2 && (!idOperator || !idClient)) return false;
    if(Number(hierarchyLevel) === LEVEL_HIERARCHY.LEVEL_3 && (!idOperator || !idClient || !idSubClient)) return false;
    return true;
  }
  

  /**
   * Actualizo el estado de los selectores según el rol seleccionado 
   */ 
  private updateSelectsState(hierarchyId: LEVEL_HIERARCHY) {
    switch (Number(hierarchyId)) {
      case (LEVEL_HIERARCHY.LEVEL_1):
        this.userForm.patchValue({idClient:null, idSubClient:null});
        this.userForm.get('idOperator')?.enable({emitEvent:false});
        this.userForm.get('idClient')?.disable({emitEvent:false});
        this.userForm.get('idSubClient')?.disable({emitEvent:false});
        break;
      case (LEVEL_HIERARCHY.LEVEL_2):
        this.userForm.patchValue({idSubClient:null});
        this.userForm.get('idOperator')?.enable({emitEvent:false});
        this.userForm.get('idClient')?.enable({emitEvent:false});
        this.userForm.get('idSubClient')?.disable({emitEvent:false});
        break;
      case (LEVEL_HIERARCHY.LEVEL_3):
        this.userForm.get('idOperator')?.enable({emitEvent:false});
        this.userForm.get('idClient')?.enable({emitEvent:false});
        this.userForm.get('idSubClient')?.enable({emitEvent:false});
        break
      default:
        this.userForm.get('idOperator')?.disable({emitEvent:false});
        this.userForm.get('idClient')?.disable({emitEvent:false});
        this.userForm.get('idSubClient')?.disable({emitEvent:false});
        break;
    }
  }
  
}




