<!-- Hidden file input -->
<input type="file" class="file-upload" (change)="onFilesSelected($event)" #fileUpload hidden [multiple]="multiple">
<!-- Drop zone -->
<div class="drop-zone" [ngClass]="{'invalid': invalid }" (drop)="onFilesDropTerminals($event)" (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)" (click)="fileUpload.click()">
    <div class="drop-zone-content">
        <div class="drop-zone-icon">
            <mat-icon svgIcon="upload-cloud-01"></mat-icon>
        </div>
        <p>{{ 'common.selectFiles' | translate }}</p>
    </div>
</div>

<!-- Mostrar advertencia si no se ha seleccionado ningún archivo -->
<div *ngIf="invalid" class="error-message">
    <span>{{ 'error.fileRequired' | translate }}</span>
</div>