<main id="main" class="main">
  <div class="pagetitle">
    <h1>{{ 'sells.title' | translate }}</h1>
    <button mat-raised-button class="custom-white-button" (click)="refrescar()">
      <i class="bi bi-arrow-clockwise"></i> {{ 'common.refresh' | translate }}
    </button>

  </div>

  <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

  <div id="tableDescription" hidden> {{ 'sells.tableDescription' | translate }}</div>

  <div class="mat-elevation-z2 tablaPersonal">
    <table mat-table [dataSource]="dataSource" aria-describedby="tableDescription" matSort
      (matSortChange)="announceSortChange($event)" matTableExporter #exporter="matTableExporter">
      <!-- numProduct Column -->
      <ng-container matColumnDef="numProduct">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'sells.numProduct' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.numProduct}} </td>
      </ng-container>

      <!-- nameProduct Column -->
      <ng-container matColumnDef="nameProduct">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'sells.nameProduct' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.nameProduct}} </td>
      </ng-container>

      <!-- opType Column -->
      <ng-container matColumnDef="opType">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'sells.opType' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ getOpTypeOption(element.opType) | translate }}
        </td>
      </ng-container>

      <!-- valueVend Column -->
      <ng-container matColumnDef="valueVend">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'sells.valueVend' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.valueVend}} </td>
      </ng-container>
      <!-- discountValue Column -->
      <ng-container matColumnDef="discountValue">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'sells.discountValue' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ getDiscountValueOption(element.discountValue) | translate }}
        </td>
      </ng-container>
      <!-- numHostAutori Column -->
      <ng-container matColumnDef="numHostAutori">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'sells.numHostAutori' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.numHostAutori}} </td>
      </ng-container>

      <!-- numHostOper Column -->
      <ng-container matColumnDef="numHostOper">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'sells.numHostOper' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.numHostOper}} </td>
      </ng-container>

      <!-- typePeripheral Column -->
      <ng-container matColumnDef="typePeripheral">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'sells.typePeripheral' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ 'sells.typePeripheralOptions.' + element.typePeripheral | translate }}
        </td>
      </ng-container>

      <!-- status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'sells.status' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ 'sells.statusOptions.' + element.status | translate }}
        </td>
      </ng-container>

      <!-- PAN Column -->
      <ng-container matColumnDef="pan">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'sells.pan' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.pan }} </td>
      </ng-container>

      <!-- dateCreate Column -->
      <ng-container matColumnDef="dateCreate">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'sells.date' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.dateCreate | dateOnTimeZone:true }}</td>
      </ng-container>

      <ng-container matColumnDef="operator" *ngIf="isAdmin">
        <th mat-header-cell *matHeaderCellDef>
          <div>{{ 'sells.idOperator' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.idOperator | levelName:'LEVEL_1' }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <!-- Empty state -->
    <ng-container *ngIf="!dataSource.data.length">
        <app-table-empty-state></app-table-empty-state>
    </ng-container>

    <button id="export" class="icoDownload" mat-icon-button (click)="exporter.exportTable('xlsx', { fileName: exportFileName })"><i
        class="bi bi-file-earmark-arrow-down"></i>
    </button>

    <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent()"
      showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</main>