
<button mat-raised-button class="custom-white-button" (click)="openWindows()">
    <i class="bi bi-search"></i>
    {{ 'vendingMachine.selector' | translate }}
</button>

<div class="tablaPersonal">
    <table  aria-describedby="" mat-table [dataSource]="dataSource" >

        <!-- serial number Column -->
        <ng-container matColumnDef="serialNumber">
            <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
                <div> {{ 'vendingMachine.serialNumber' | translate }} </div>
            </th>
            <td mat-cell *matCellDef="let element" class="tamano_columna_max"> {{element.serialNumber}} </td>
        </ng-container>

        <!-- manufacID Column -->
        <ng-container matColumnDef="manufacID">
            <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
                <div> {{ 'vendingMachine.manufacID' | translate }} </div>
            </th>
            <td mat-cell *matCellDef="let element" class="tamano_columna_max"> {{element.manufacID}} </td>
        </ng-container>

        <!-- model number Column -->
        <ng-container matColumnDef="modelNumber">
            <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
                <div> {{ 'vendingMachine.modelNumber' | translate }} </div>
            </th>
            <td mat-cell *matCellDef="let element" class="tamano_columna_max"> {{element.modelNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="view">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="button" style=" width: 50px;">
                <div>
                    <button  (click)="onDelete(element)" mat-icon-button class="iconoTabla">
                        <img class="delete-icon" src="assets/img/delete_icon.svg" alt="Delete icon">
                    </button>
                </div>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
   
    </table>

    <!-- Empty state -->
    <ng-container *ngIf="!dataSource.data.length">
        <app-table-empty-state [border]="false" [icon]="false"></app-table-empty-state>
    </ng-container>
</div>