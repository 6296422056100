import { EnabledBlockingInitialNavigationFeature } from "@angular/router";

export class CoverageChartPeriod {
    type:string;
    min:number;
    max:number;
    sumAverage:number;
    sumCount:number
    period:string;
    color:string;
 
 
    constructor(type:string, period:string, min:number = 0, max:number = 0, sumAverage:number = 0, sumCount:number = 0, color:string){
     this.type = type;
     this.period = period;
     this.min = min;
     this.max = max;
     this.sumAverage = sumAverage;
     this.sumCount = sumCount;
     this.color = color;
    }
 
    private get average(): number {
      return  this.sumAverage != 0 ? this.sumAverage / this.sumCount : 0;
    }
 
    getBoxPlotSeriesData():{ x:string, y:(number|null)[], strokeColor:string}{
      const min = this.min ?? 0;
      const max = this.max ?? 0;
      const average = this.average;
     return {
       x: this.period,
       y: [ min, average, average, average, max ],
       strokeColor: this.color,
     }
    }
 
    getLineSeriesData():{ x:string, y:(number|null), strokeColor:string }{
     return {
       x: this.period,
       y: this.average ?? 0,
       strokeColor:this.color
     }
    }
 }