<div class="edit-panel">
  <div class="header">
    <div class="title">{{ 'roles.title' | translate }}</div>
    <div class="action-buttons">
      <ng-container *ngIf="!readOnly">
        <button *ngIf="handleRol.canByInput(handleRol.functIds.edit_rols)" mat-flat-button
          class="custom-add-button" (click)="onSave()"
          [disabled]="(registerFormStep1.pristine && registerFormStep2.pristine) || registerFormStep1.invalid || registerFormStep2.invalid">
          {{ 'editPanel.save' | translate }}
        </button>

      </ng-container>
      <button class="close-button" (click)="onCancel()">{{ 'editPanel.close' | translate }}</button>
    </div>
  </div>

    <form [formGroup]="registerFormStep1">
      <!-- Step 1: Nombre y Descripción (Collapsible Section) -->
      <fieldset class="edit-container bordered">
        <legend class="details-header" (click)="toggleSection('details')">
          <label class="title-small">{{ 'roles.details' | translate }}</label>
          <mat-icon class="toggle-icon">{{ isOpen['details'] ? 'expand_less' : 'expand_more' }}</mat-icon>
        </legend>
        <div class="edit-content" *ngIf="isOpen['details']">
          <div class="row">
            <div class="col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'roles.name' | translate }}</mat-label>
                <input matInput formControlName="nombre" required>
                <mat-error *ngIf="registerFormStep1.get('nombre')?.hasError('required')">
                  {{ 'error.required.field' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'roles.description' | translate }}</mat-label>
                <input matInput formControlName="description" required>
                <mat-error *ngIf="registerFormStep1.get('description')?.hasError('required')">
                  {{ 'error.required.field' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          
          <div class="row">
            <div *ngIf="handleRol.isAdmin()">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'users.operator' | translate }}<span class="obligatorio"></span></mat-label>
                        <mat-select formControlName="idOperator">
                            <mat-option *ngFor="let op of operatorsList" [value]="op.id">
                                {{ op.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        </div>
      </fieldset>

      <!-- Step 2: Permisos y Acciones (Collapsible Section) -->
      <fieldset class="edit-container bordered">
        <legend class="details-header" (click)="toggleSection('permissions')">
          <label class="title-small">{{ 'roles.step2' | translate }}</label>
          <mat-icon class="toggle-icon">{{ isOpen['permissions'] ? 'expand_less' : 'expand_more' }}</mat-icon>
        </legend>
        <div class="edit-content" *ngIf="isOpen['permissions']">
          <form [formGroup]="registerFormStep2">
            <div class="permissions-container" [formGroup]="registerFormStep2">

              <!-- Monitorización -->
              <fieldset class="edit-container bordered">
                <div class="permission-group-container">
                  <label class="title-small">{{ 'sidebar.monitoring' | translate }}</label>
                  <div class="permission-grid">
                    <div class="permission-group horizontal">
                      <label>{{ 'roles.permissions.terminals' | translate }}</label>
                      <div class="permission-icons-row">
                        <div class="permission-item">
                          <mat-checkbox formControlName="view_terminals"></mat-checkbox>
                          <i class="bi bi-eye"></i>
                        </div>
                        <div class="permission-item">
                          <mat-checkbox formControlName="edit_terminals"></mat-checkbox>
                          <i class="bi bi-pencil"></i>
                        </div>
                        <div class="permission-item">
                          <mat-checkbox formControlName="delete_terminals"></mat-checkbox>
                          <i class="bi bi-trash"></i>
                        </div>
                      </div>
                    </div>
                    <div class="permission-group horizontal">
                      <label>{{ 'roles.permissions.view_sells' | translate }}</label>
                      <div class="permission-icons-row">
                        <div class="permission-item">
                          <mat-checkbox formControlName="view_sells"></mat-checkbox>
                          <i class="bi bi-eye"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>

              <!-- Estadísticas -->
              <fieldset class="edit-container bordered">
                <div class="permission-group-container">
                  <label class="title-small">{{ 'sidebar.statistic' | translate }}</label>
                  <div class="permission-grid">
                    <div class="permission-group horizontal">
                      <label>{{ 'roles.permissions.functionality' | translate }}</label>
                      <div class="permission-icons-row">
                        <div class="permission-item">
                          <mat-checkbox formControlName="view_functionality"></mat-checkbox>
                          <i class="bi bi-eye"></i>
                        </div>
                      </div>
                    </div>
                    <div class="permission-group horizontal">
                      <label>{{ 'roles.permissions.status' | translate }}</label>
                      <div class="permission-icons-row">
                        <div class="permission-item">
                          <mat-checkbox formControlName="view_status"></mat-checkbox>
                          <i class="bi bi-eye"></i>
                        </div>
                      </div>
                    </div>
                    <div class="permission-group horizontal">
                      <label>{{ 'roles.permissions.view_sensors' | translate }}</label>
                      <div class="permission-icons-row">
                        <div class="permission-item">
                          <mat-checkbox formControlName="view_sensors"></mat-checkbox>
                          <i class="bi bi-eye"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>

              <!-- Soporte -->
              <fieldset class="edit-container bordered">
                <div class="permission-group-container">
                  <label class="title-small">{{ 'sidebar.support' | translate }}</label>
                  <div class="permission-grid">
                    <div class="permission-group horizontal">
                      <label>{{ 'roles.permissions.alerts' | translate }}</label>
                      <div class="permission-icons-row">
                        <div class="permission-item">
                          <mat-checkbox formControlName="view_alerts"></mat-checkbox>
                          <i class="bi bi-eye"></i>
                        </div>
                      </div>
                    </div>
                    <div class="permission-group horizontal">
                      <label>{{ 'roles.permissions.incidences' | translate }}</label>
                      <div class="permission-icons-row">
                        <div class="permission-item">
                          <mat-checkbox formControlName="view_incidences"></mat-checkbox>
                          <i class="bi bi-eye"></i>
                        </div>
                        <div class="permission-item">
                          <mat-checkbox formControlName="edit_incidences"></mat-checkbox>
                          <i class="bi bi-pencil"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>

              <!-- Configuración -->
              <fieldset class="edit-container bordered">
                <div class="permission-group-container">
                  <label class="title-small">{{ 'sidebar.configuration' | translate }}</label>
                  <div class="permission-grid">
                    <div class="permission-group horizontal">
                      <label>{{ 'roles.permissions.applications' | translate }}</label>
                      <div class="permission-icons-row">
                        <div class="permission-item">
                          <mat-checkbox formControlName="view_applications"></mat-checkbox>
                          <i class="bi bi-eye"></i>
                        </div>
                        <div class="permission-item">
                          <mat-checkbox formControlName="edit_applications"></mat-checkbox>
                          <i class="bi bi-pencil"></i>
                        </div>
                        <div class="permission-item">
                          <mat-checkbox formControlName="delete_applications"></mat-checkbox>
                          <i class="bi bi-trash"></i>
                        </div>
                      </div>
                    </div>

                    <div class="permission-group horizontal">
                      <label>{{ 'roles.permissions.parameters' | translate }}</label>
                      <div class="permission-icons-row">
                        <div class="permission-item">
                          <mat-checkbox formControlName="view_parameters"></mat-checkbox>
                          <i class="bi bi-eye"></i>
                        </div>
                        <div class="permission-item">
                          <mat-checkbox formControlName="edit_parameters"></mat-checkbox>
                          <i class="bi bi-pencil"></i>
                        </div>
                        <div class="permission-item">
                          <mat-checkbox formControlName="delete_parameters"></mat-checkbox>
                          <i class="bi bi-trash"></i>
                        </div>
                      </div>
                    </div>

                    <div class="permission-group horizontal">
                      <label>{{ 'roles.permissions.profiles' | translate }}</label>
                      <div class="permission-icons-row">
                        <div class="permission-item">
                          <mat-checkbox formControlName="view_profiles"></mat-checkbox>
                          <i class="bi bi-eye"></i>
                        </div>
                        <div class="permission-item">
                          <mat-checkbox formControlName="edit_profiles"></mat-checkbox>
                          <i class="bi bi-pencil"></i>
                        </div>
                        <div class="permission-item">
                          <mat-checkbox formControlName="delete_profiles"></mat-checkbox>
                          <i class="bi bi-trash"></i>
                        </div>
                      </div>
                    </div>

                    <div class="permission-group horizontal">
                      <label>{{ 'roles.permissions.tags' | translate }}</label>
                      <div class="permission-icons-row">
                        <div class="permission-item">
                          <mat-checkbox formControlName="view_tags"></mat-checkbox>
                          <i class="bi bi-eye"></i> <!-- Icono de ver -->
                        </div>
                        <div class="permission-item">
                          <mat-checkbox formControlName="edit_tags"></mat-checkbox>
                          <i class="bi bi-pencil"></i> <!-- Icono de editar -->
                        </div>
                        <div class="permission-item">
                          <mat-checkbox formControlName="delete_tags"></mat-checkbox>
                          <i class="bi bi-trash"></i> <!-- Icono de eliminar -->
                        </div>
                      </div>
                    </div>

                    <div class="permission-group horizontal">
                      <label>{{ 'roles.permissions.custom_alerts' | translate }}</label>
                      <div class="permission-icons-row">
                        <div class="permission-item">
                          <mat-checkbox formControlName="view_custom_alerts"></mat-checkbox>
                          <i class="bi bi-eye"></i>
                        </div>
                        <div class="permission-item">
                          <mat-checkbox formControlName="edit_custom_alerts"></mat-checkbox>
                          <i class="bi bi-pencil"></i>
                        </div>
                        <div class="permission-item">
                          <mat-checkbox formControlName="delete_custom_alerts"></mat-checkbox>
                          <i class="bi bi-trash"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>

              <!-- Mantenimiento -->
              <fieldset class="edit-container bordered">
                <div class="permission-group-container">
                  <label class="title-small">{{ 'sidebar.maintenance' | translate }}</label>
                  <div class="permission-grid">
                    <div class="permission-group horizontal">
                      <label>{{ 'roles.permissions.view_file' | translate }}</label>
                      <div class="permission-icons-row">
                        <div class="permission-item">
                          <mat-checkbox formControlName="view_file"></mat-checkbox>
                          <i class="bi bi-eye"></i>
                        </div>
                        <div class="permission-item">
                          <mat-checkbox formControlName="delete_file"></mat-checkbox>
                          <i class="bi bi-trash"></i> <!-- Icono de eliminar -->
                        </div>
                      </div>
                    </div>

                    <div class="permission-group horizontal">
                      <label>{{ 'roles.permissions.send_file' | translate }}</label>
                      <div class="permission-icons-row">
                        <div class="permission-item">
                          <mat-checkbox formControlName="send_file"></mat-checkbox>
                          <i class="bi bi-eye"></i>
                        </div>
                      </div>
                    </div>

                    <div class="permission-group horizontal">
                      <label>{{ 'roles.permissions.get_file' | translate }}</label>
                      <div class="permission-icons-row">
                        <div class="permission-item">
                          <mat-checkbox formControlName="get_file"></mat-checkbox>
                          <i class="bi bi-eye"></i>
                        </div>
                      </div>
                    </div>

                    <div class="permission-group horizontal">
                      <label>{{ 'roles.permissions.commands' | translate }}</label>
                      <div class="permission-icons-row">
                        <div class="permission-item">
                          <mat-checkbox formControlName="commands"></mat-checkbox>
                          <i class="bi bi-eye"></i> <!-- Icono de ver -->
                        </div>
                      </div>
                    </div>

                    <div class="permission-group horizontal">
                      <label>{{ 'roles.permissions.pending_tasks' | translate }}</label>
                      <div class="permission-icons-row">
                        <div class="permission-item">
                          <mat-checkbox formControlName="pending_tasks"></mat-checkbox>
                          <i class="bi bi-eye"></i> <!-- Icono de ver -->
                        </div>
                        <div class="permission-item">
                          <mat-checkbox formControlName="edit_pending_tasks"></mat-checkbox>
                          <i class="bi bi-pencil"></i> <!-- Icono de editar -->
                        </div>
                        <div class="permission-item">
                          <mat-checkbox formControlName="delete_pending_tasks"></mat-checkbox>
                          <i class="bi bi-trash"></i> <!-- Icono de eliminar -->
                        </div>
                      </div>
                    </div>

                    <div class="permission-group horizontal">
                      <label>{{ 'roles.permissions.history' | translate }}</label>
                      <div class="permission-icons-row">
                        <div class="permission-item">
                          <mat-checkbox formControlName="history"></mat-checkbox>
                          <i class="bi bi-eye"></i>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </fieldset>

              <!-- Sección de Administrador -->
              <fieldset class="edit-container bordered">
                <div class="permission-group-container">
                  <label class="title-small">{{ 'sidebar.admin' | translate }}</label>
                  <div class="permission-grid">
                    <div class="permission-group">
                      <label>{{ 'roles.permissions.users' | translate }}</label>
                      <div class="permission-icons-row">
                        <div class="permission-item">
                          <mat-checkbox formControlName="view_users"></mat-checkbox>
                          <i class="bi bi-eye"></i>
                        </div>
                        <div class="permission-item">
                          <mat-checkbox formControlName="edit_users"></mat-checkbox>
                          <i class="bi bi-pencil"></i>
                        </div>
                        <div class="permission-item">
                          <mat-checkbox formControlName="delete_users"></mat-checkbox>
                          <i class="bi bi-trash"></i>
                        </div>
                      </div>
                    </div>
                    <!-- Sección de Clientes -->
                    <div class="permission-group-container">
                      <div class="permission-group horizontal">
                        <label>{{ 'roles.permissions.clients' | translate }}</label>
                        <div class="permission-icons-row">
                          <div class="permission-item">
                            <mat-checkbox formControlName="view_clients"></mat-checkbox>
                            <i class="bi bi-eye"></i>
                          </div>
                          <div class="permission-item">
                            <mat-checkbox formControlName="edit_clients"></mat-checkbox>
                            <i class="bi bi-pencil"></i>
                          </div>
                          <div class="permission-item">
                            <mat-checkbox formControlName="delete_clients"></mat-checkbox>
                            <i class="bi bi-trash"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Sección de Subclientes -->
                    <div class="permission-group-container">
                      <div class="permission-group horizontal">
                        <label>{{ 'roles.permissions.subclients' | translate }}</label>
                        <div class="permission-icons-row">
                          <div class="permission-item">
                            <mat-checkbox formControlName="view_subclients"></mat-checkbox>
                            <i class="bi bi-eye"></i>
                          </div>
                          <div class="permission-item">
                            <mat-checkbox formControlName="edit_subclients"></mat-checkbox>
                            <i class="bi bi-pencil"></i>
                          </div>
                          <div class="permission-item">
                            <mat-checkbox formControlName="delete_subclients"></mat-checkbox>
                            <i class="bi bi-trash"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Sección de Roles -->
                    <div class="permission-group-container">
                      <div class="permission-group horizontal">
                        <label>{{ 'roles.permissions.rols' | translate }}</label>
                        <div class="permission-icons-row">
                          <div class="permission-item">
                            <mat-checkbox formControlName="view_rols"></mat-checkbox>
                            <i class="bi bi-eye"></i>
                          </div>
                          <div class="permission-item">
                            <mat-checkbox formControlName="edit_rols"></mat-checkbox>
                            <i class="bi bi-pencil"></i>
                          </div>
                          <div class="permission-item">
                            <mat-checkbox formControlName="delete_rols"></mat-checkbox>
                            <i class="bi bi-trash"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </form>
        </div>
      </fieldset>
    </form>

    <div class="footer" *ngIf="!readOnly">
      <button *ngIf="handleRol.canByInput(handleRol.functIds.delete_rols)" mat-stroke-button
        class="custom-white-button" (click)="onDelete(data.rol)">
        {{ 'editPanel.deleteVersion' | translate }}
        <img class="delete-icon" src="assets/img/delete_icon.svg" alt="Delete icon">
      </button>
    </div>