import { SERVER_URL_CONSOLA_REST, SERVER_URL_AUTH_TMS} from 'src/environments/environment';
import { NotifierOptions } from 'angular-notifier';

export const RegexpValidators = {
  number: /^\d*$/,
  // Passwords should be at least 8 characters long and should contain one number, one character and one special character.
  password: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()\-\[\{\}\]\:\;\'\,\?\/\*\~\$\^\+\=\<\>]).{12,24}$/,
  newPassword: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()\-\[\{\}\]\:\;\'\,\?\/\*\~\$\^\+\=\<\>]).{12,24}$/,
  phone:/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$/,
  email:/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
  username:'^(?=[a-zA-Z0-9._-]{6,255}$)(?!.*[_.-]{2}).*[^_.-]',
  text:/^[A-ZÀ-ÿa-z\s\-.]*$/
};

export const Feedbacks = {
  position: 'toast-bottom-full-width' as const,
  timeoutShort: 2500 as const,
  timeoutLong: 8000 as const,
};

export const pedingWarnigAlert = {
  text: 'Terminal configuration is pending of actualization' as const,
  type: 'Warning' as const,
  position: 'toast-bottom-full-width' as const,
  timeout: 12000 as const,
};

export const HttpTimeouts = {
  default: 180000,
  short: 20000,
  long: 600000,
};

export const Separator = ","
export const AuthTmsRestUrl = SERVER_URL_AUTH_TMS;
export const ConsoleRest = SERVER_URL_CONSOLA_REST;



export enum LEVEL_ROL {
  ADMIN = 1
}

export enum LEVEL_HIERARCHY {
  ADMIN = 0,
  LEVEL_1 = 1, //Operador
  LEVEL_2 = 2, //Cliente
  LEVEL_3 = 3  //SubCliente
}

export enum FUNCTION {
  // MONITORIZACION
  DELETE_VM = 1,        // "rol.function.delete.vm"
  UNLINK_VM = 22,       // "rol.function.unlink.vm"
  EDIT_VM = 2,          // "rol.function.edit.vm"
  RESET_VM = 3,         // "rol.function.reset.vm"
  EDIT_ALARM = 4,       // "rol.function.edit.alarm"
  EDIT_PRODUCT = 5,     // "rol.function.edit.product"
  EDIT_FILE = 6,        // "rol.function.edit.file"
  EDIT_PENDING = 7,     // "rol.function.edit.pending"
  EDIT_PLANOGRAM = 8,   // "rol.function.edit.planogram"
  VIEW_PENDING = 9,     // "rol.function.view.pending"

  // CONFIGURACION
  VIEW_CONFIG = 10,     // "rol.function.view.config"
  EDIT_DISCOUNT = 11,   // "rol.function.edit.discount"
  EDIT_AUDIT = 12,      // "rol.function.edit.audit"
  EDIT_GROUP = 13,      // "rol.function.edit.group"

  // SOPORT
  VIEW_SOPORTE = 15,    // "rol.function.view.soporte"
  EDIT_INCIDENCE = 23,  // "rol.function.view.incidence"




  

  // INFORME
  VIEW_INFORME = 16,    // "rol.function.view.informe"

  // USER
  ADD_USER = 17,        // "rol.function.add.user"
  ADD_CLIENT = 24,      // "rol.function.add.client"

  // MAINTENANCE
  VIEW_MAINTENANCE = 18, // "rol.function.view.maintenance"

  // REPORTS
  VIEW_REPORTS = 19,    // "rol.function.view.reports"

  // Recarga
  RECARGAR = 26,        // "rol.function.view.reposicion"
}

export enum FormCreateActions {
  SAVED = 1,
  EXIT = 0
}


export const enum AUTHENTICACION {
  AUTH,
  TMS_SERVER
}



/**
 * Angular notifier options
 */
export const NOTIFIER_OPTIONS: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 0,
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 3000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

export enum SEND_FILE_TYPE {
  APLICATION = 1,
  PARAMETER = 2,
  UNDEFINED = 0
}

export enum STORAGE_TYPE {
  PUBLIC_DIR = 'publicDirectory',
  FILES_DIR = 'filesDir',
}

export const STORAGE_TYPES = [
  'publicDirectory',
  'filesDir',
  'externalFilesDir'
];

export const DATE_FORMATS = {
  terminal:"yyMMdd_HHmmss"
}

export const SERVER_ERRORS_LABELS:{ [key: string]: string } = {
  "-29" :"serverErrors.duplicateRegister",
  "-14":"serverErrors.terminalNotFound",
  "-8": "serverErrors.userRegistered",
  "default": "serverErrors.serverError"
}

export const SERVER_STATUS_ERRORS_LABELS:{ [key: string]: string } = {
  "401":"error.loginError",
  "413":"error.maxFileSize",
  "default": "error.serverError"
}

export const SERVER_STATUS_ERRORS = {
  UNAUTHORIZED:"Unauthorized access"
}


export enum FUNCTION_IDS {
  user_profile = 0,
  view_terminals = 1,
  edit_terminals = 2,
  delete_terminals = 50,
  view_sells = 3,
  view_statistics = 4,
  view_functionality = 5,
  view_status = 6,
  view_support = 7,
  view_alerts = 8,
  view_incidences = 11,
  edit_incidences = 12,
  view_applications = 13,
  edit_applications = 14,
  delete_applications = 15,
  view_parameters = 16,
  edit_parameters = 17,
  delete_parameters = 18,
  view_profiles = 19,
  edit_profiles = 20,
  delete_profiles = 21,
  view_custom_alerts = 22,
  edit_custom_alerts = 9,
  delete_custom_alerts = 10,
  view_pending_tasks = 24,
  delete_pending_tasks = 25,
  view_users = 26,
  edit_users = 27,
  delete_users = 28,
  view_clients = 29,
  edit_clients = 30,
  delete_clients = 31,
  view_subclients = 32,
  edit_subclients = 33,
  delete_subclients = 34,
  send_file = 35,
  get_file = 36,
  view_file = 37,
  delete_file = 38,
  commands = 39,
  pending_tasks = 40,
  edit_pending_tasks = 41,
  history = 42,
  view_sensors = 43,
  view_tags = 44,
  edit_tags = 45,
  delete_tags = 46,
  view_rols = 47,
  edit_rols = 48,
  delete_rols = 49,
};

export const NAVIGATE_IDS: { [key:number]: string }  = {
  [FUNCTION_IDS.user_profile]: '/user-profile',
  [FUNCTION_IDS.view_terminals]: '/monitoring',
  [FUNCTION_IDS.view_functionality]: '/stadistic_funcional',
  [FUNCTION_IDS.view_status]: '/stadistic_state',
  [FUNCTION_IDS.view_alerts]: '/alerts',
  [FUNCTION_IDS.view_incidences]: '/incidenceMonitoring',
  [FUNCTION_IDS.view_applications]: '/aplications',
  [FUNCTION_IDS.view_parameters]: '/parameters',
  [FUNCTION_IDS.view_profiles]: '/profile',
  [FUNCTION_IDS.view_custom_alerts]: '/customAlert',
  [FUNCTION_IDS.send_file]: '/sendFile',
  [FUNCTION_IDS.get_file]: '/getFile',
  [FUNCTION_IDS.commands]: '/commands',
  [FUNCTION_IDS.pending_tasks]: '/pendingTask',
  [FUNCTION_IDS.history]: '/history-user',
  [FUNCTION_IDS.view_users]: '/users',
  [FUNCTION_IDS.view_clients]: '/client',
  [FUNCTION_IDS.view_subclients]: '/subClient',
  [FUNCTION_IDS.view_tags]: '/tags',
  [FUNCTION_IDS.view_rols]: '/rol',
}

export const CUSTOM_ALERTS_TYPES:{ [key:string]: string } = {
  "2":"COVER_2G",
  "3":"COVER_3G",
  "4":"COVER_4G",
  "6":"LAST_VEND", 
  "7":"LAST_CONNECTION", 
  "8":"BATTERY", 
  "9":"HOUR", 
  "10":"KEY", 
  "11":"PRINT",
}

export const DEFAULT_COLORS_THEME = [
    "#404F59",
    "#F04438",
    "#F79009",
    "#17B26A",
    "#15B79E",
    "#875BF7",
    "#EE46BC"
]


export const NAVIGATION_SECTIONS  = {
  customAlerts : { 
    button: 'toc_cb_customAlet',
    path: '/customAlert',
    collapse: 'config-nav-button'
  }
}

export type NavigationSectionType = keyof typeof NAVIGATION_SECTIONS;
