import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Groups } from 'src/app/models/groups.model';
import { CommonService } from 'src/app/services/common.service';
import { RestService } from 'src/app/services/merchant/Rest.service';


@Injectable({
  providedIn: 'root',
})
export class GroupsService {
  private url: any;

  constructor(private merchantRest : RestService,private authService:AuthService, public commonService: CommonService) {
    }
  
   
  async find(){
    let _body: Groups = new Groups
    let userSession = this.authService.currentUserValue();
    _body.idEntorno=userSession!.entorno!.id
    if(userSession.operator){
      _body.idOperator=userSession!.operator!.id
    }
         
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getListGroup/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }


 

  async getItem(id:number ){

    let _body: Groups = new Groups;
    _body.id=id

    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getGroup/',true,true )   
      .catch(err => {
        return null
      })
      
    return value?.data;   
  }




  async addUpdate(grp:Groups){

    let _body= grp
         
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'addGroup/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }


  async delete(id:number){
    let _body: Groups = new Groups
    _body.id=id
         
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'deleteGroup/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }


 
}