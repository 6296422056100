<main class="login-section">
  <div class="blur"></div> 
    <div class="container">
       <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div class="container">
             <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                   <div class="card mb-3">
                      <div class="card-body">
                         <div class="pt-4 pb-2">
                            <div class="d-flex justify-content-center py-4"> 
                              <div  class="logo d-flex align-items-center w-auto"> 
                                 <img src="assets/img/logo.png" alt=""> 
                                 <span class="d-lg-block" i18n><b>MDM</b></span> 
                              </div>
                           </div>                           
                         </div>
                         <form  [formGroup]="registerForm" (ngSubmit)="onFormSubmit()" class="row g-3" novalidate>
                              <div class="login-input-container">
                                 <label>{{ 'login.Username' | translate }}</label>
                                 <mat-form-field class="w-100" appearance="outline">
                                    <input class="form-control" matInput formControlName="username" type="text">
                                    <mat-error *ngIf="registerForm.get('username')?.hasError('required')">{{ 'error.required.username' | translate }}</mat-error>
                                 </mat-form-field>
                              </div>

                              <div class="login-input-container">
                                 <label> {{ 'login.Password' | translate }}</label>
                                 <mat-form-field class="w-100" appearance="outline">
                                    <input class="form-control" matInput formControlName="password" [type]="hidePassword ? 'password' : 'text'">
                                    <span mat-icon-button matSuffix (click)="setPasswordVisibility()" [attr.aria-label]="'Hide compare password'" [attr.aria-pressed]="hidePassword">
                                       <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </span>
                                    <mat-error *ngIf="registerForm.get('password')?.hasError('required')">{{ 'error.required.password' | translate }}</mat-error>
                                 </mat-form-field>
                              </div>

                           
                              <div class="col-12"> 
                                 <input class="btn btn-primary w-100 custom-login-button" type="submit" [value]="'login.login' | translate">
                              </div>

                              <div class="col-12"> 
                                 <a mat-button color="primary" class="w-100" routerLink="/reset-password">{{ 'login.resetPassword' | translate }}</a>
                              </div>
                              
                              <div class="col-12">
                                 <p class="small mb-0">Version {{appVersion}}</p>
                              </div>
                         </form>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </section>
    </div>
 </main>