import { FilterAndPagBean } from "./FilterAndPagBean";

export class ConsoleRequest {
  data!: any | undefined;
  userID: number | undefined;	
  filterAndPagBean: FilterAndPagBean | undefined; //ELIMINAR y Pasar llamadas al nuevo objeto request	

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}


export class ObjectsRequest{
  filterAndPagBean!: FilterAndPagBean;
  id?:number;
}



