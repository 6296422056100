import { Injectable } from '@angular/core';
import { RestService } from 'src/app/services/merchant/Rest.service';
import { Command } from './command.model';

@Injectable({
  providedIn: 'root'
})
export class CommandsService {

  constructor(private service:RestService) { }

  async create(_body:Command){
    let value = await this.service.commonRestCallConsolaRest(_body,'setConfigComand/', true, true)   
      .catch(err => {
        return null
      })
    return value;   
  }

}
