import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { STORAGE_TYPES, FormCreateActions, LEVEL_HIERARCHY, STORAGE_TYPE } from 'src/app/util/constants';
import { ParamService } from '../param.service';
import { convertFileToBase64 } from 'src/app/util/util';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { Operator } from 'src/app/models/operator.model';
import { UtilService } from 'src/app/services/util.service';
import { Parameters } from 'src/app/models/parameters.model';
import { Tag } from '../../tags/tag.model';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';
import { Client, SubClient } from 'src/app/models/client.model';


@Component({
  selector: 'app-new-param',
  templateUrl: './new-param.component.html',
  styleUrls: ['./new-param.component.css']
})
export class NewParamComponent implements OnInit {
  isLinear = true;
  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  selectedFile: File | null = null;
  isCloseParam: boolean = false;
  listaOpe: Operator[] = [];
  showDropZone = false;
  isAdmin: boolean = false;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  uploadProgress = 0;
  storageTypes = STORAGE_TYPES;
  hierarchyOptions: { value: LEVEL_HIERARCHY, label: string }[] = []
  clientsList: Client[] = [];
  subClientsList: SubClient[] = [];

  fileUploadError:boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private service: ParamService,
    public hierarchy: HierarchyHandlerService,
    private utilServicios: UtilService,
    public dialogNewParam: MatDialogRef<NewParamComponent>,
    private translate: TranslateService,
    private notifier: NotifierService,
    public dialog: MatDialog) {}


  async ngOnInit(): Promise<void> {
    this.isAdmin = this.utilServicios.isAdmin();
    this.firstFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.compose([Validators.required, Validators.maxLength(400)])],
      version: ['', Validators.required],
      storageType: [false],
      hierarchy:[{ idOperator:null, idClient:null, idSubClient:null }],
      tags: ['']
    });

    this.secondFormGroup = this.formBuilder.group({
      uploadFiles: [null, Validators.required],
      fileBase64: ['']
    });
  }

  toggleDropZone(): void {
    this.showDropZone = !this.showDropZone;
  }

  private mapFileToBase64(file: File) {
    // Convertir el archivo a base64 y actualizar el formulario
    convertFileToBase64(file).then(base64String => {
      if (!base64String) {
        this.notifier.notify('warning', this.translate.instant('error.fileEmpty'));
        this.fileUploadError = true;
        return;
      }
      this.selectedFile = file;
      this.fileUploadError = false;
      this.secondFormGroup.patchValue({
        uploadFiles: this.selectedFile,
        fileBase64: base64String,
      });
      this.secondFormGroup.get('uploadFiles')?.markAsTouched();
      this.showDropZone = false;
    }).catch(error => {
      this.fileUploadError = true;
      this.notifier.notify('warning', this.translate.instant('error.fileInvalid'))
    });
  }

  async onFileSelected(files: File[]): Promise<void> {
    if (files.length > 0) {
      const file = files[0];
      this.mapFileToBase64(file);
    }
  }

  deleteFile(): void {
    this.selectedFile = null;
    this.secondFormGroup.get('uploadFiles')?.reset();
    this.uploadProgress = 0;
    this.fileUploadError = true; // Mostrar error si no hay archivo
  }


  onFormSubmit(): void {
    if (this.firstFormGroup.invalid || this.secondFormGroup.invalid || !this.selectedFile) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }

    const formData = {
      ...this.firstFormGroup.getRawValue(),
      ...this.secondFormGroup.value,
      selectedFile: this.selectedFile?.name,
      fileBase64: this.secondFormGroup.get('fileBase64')?.value

    };

    let paramNew: Parameters = new Parameters
    paramNew.name = formData.name;
    paramNew.description = formData.description;
    paramNew.version = formData.version;
    paramNew.base64 = formData.fileBase64;
    paramNew.fileName = formData.selectedFile;
    paramNew.storageType = formData.storageType ? STORAGE_TYPE.PUBLIC_DIR : STORAGE_TYPE.FILES_DIR;
    paramNew.path = formData.path;
    if (formData.tags && formData.tags.map) {
      paramNew.listEtiquetas = formData.tags.map((tag: Tag) => ({ id: tag.id }));
    }
    
    const { idOperator, idClient, idSubClient } = formData.hierarchy;
    paramNew.idOperator = idOperator;
    paramNew.idClient = idClient;
    paramNew.idSubClient = idSubClient;


    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = this.translate.instant('confirmDialog.createParamTitle');
    dialogData.textI18n = this.translate.instant('confirmDialog.createParamText');
    dialogData.svgIcon = 'assets/img/confirm._icon.svg';

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {

        console.log('Form SAVE action');
        let val: any;

        val = await this.service.addUpdate(paramNew)

        if (val!.status >= 0) {
          this.dialogNewParam.close(FormCreateActions.SAVED);
        }

      }
    });
  }

  formExit(): void {
    console.log('Form exit action');
    this.dialogNewParam.close(FormCreateActions.EXIT);
  }

}
