export const ANDROID_VERSIONS: {[key:number]: string} = {
    36: "16",
    35: "15",
    34: "14",
    33: "13",
    32: "12",
    31: "12",
    30: "11",
    29: "10",
    28: "9",
    27: "8.1",
    26: "8.0",
    25: "7.1",
    24: "7.0",
    23: "6.0",
    22: "5.1",
    21: "5.0",
    20: "4.4W",
    19: "4.4",
    18: "4.3",
    17: "4.2",
    16: "4.1",
    15: "4.0.3",
    14: "4.0",
    13: "3.2",
    12: "3.1",
    11: "3.0"
}