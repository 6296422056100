import { Injectable } from '@angular/core';
import { PaginatorIntlService } from './paginator-intl.service';
import { HierarchyOptionsService } from './hierarchy-options.service';

@Injectable({
  providedIn: 'root'
})
export class ComponentsStateService {

  constructor(
    private paginatorIntl: PaginatorIntlService,
    private hierarchyOptions: HierarchyOptionsService,
  ) {}

  update(){
    this.updateLabelsState();
    this.loadHierarchyOptions();
  }

  updateLabelsState(){
    this.paginatorIntl.updateLabelsState();
  }

  
  loadHierarchyOptions(){
    this.hierarchyOptions.load();
  }
}
