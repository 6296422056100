import { Injectable } from "@angular/core";
import { RestService } from "./merchant/Rest.service";
import { ConsoleRequest } from "../models/console.request.model";


@Injectable({
    providedIn: 'root',
})
export class FactoriesService {
    constructor(private merchantRest: RestService) {}

    async getFactoriesList(){
        const _body = new ConsoleRequest;
        let value = await this.merchantRest.commonRestCallConsolaRest(_body, 'getListFabricante/', true, true)
        .catch(err => {
          return null
        })
        return value;
    }
    
}