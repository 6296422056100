<div class="edit-panel">
    <div class="header">
        <div class="title">{{ 'users.title' | translate }}</div>
        <div class="action-buttons">
            <ng-container *ngIf="!readOnly">
                <button *ngIf="rol.canByInput(rol.functIds.edit_users)" mat-flat-button class="custom-add-button"
                    (click)="onFormSubmit()" [disabled]="userForm.pristine || userForm.invalid">{{ 'editPanel.save' |
                    translate }}</button>
            </ng-container>
            <button class="close-button" (click)="formExit()">{{ 'editPanel.close' | translate }}</button>
        </div>
    </div>

    <!-- Formulario -->
    <form [formGroup]="userForm">
        <!-- Details Section -->
        <fieldset class="edit-container bordered">
            <legend class="details-header">
                <label class="title-small">{{ 'users.details' | translate }}</label>
            </legend>
            <div class="edit-content">
                <div class="row">
                    <div class="col-lg-6 col-12">
                        <div class="form-group">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>{{ 'users.nickName' | translate }}<span
                                        class="obligatorio"></span></mat-label>
                                <input matInput class="form-field" formControlName="username" type="text" required>
                                <mat-error *ngIf="userForm.get('username')?.hasError('required')">{{
                                    'error.required.field' | translate }}</mat-error>
                                <mat-error *ngIf="userForm.get('username')?.hasError('maxLength')">{{ 'error.maxLength'
                                    | translate }}</mat-error>
                                <mat-error *ngIf="userForm.get('username')?.hasError('pattern')">{{ 'error.pattern' |
                                    translate }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-6 col-12">
                        <div class="form-group">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>{{ 'users.name' | translate }}<span class="obligatorio"></span></mat-label>
                                <input matInput class="form-field" formControlName="name" type="text" required>
                                <mat-error *ngIf="userForm.get('name')?.hasError('required')">{{ 'error.required.field'
                                    | translate }}</mat-error>
                                <mat-error *ngIf="userForm.get('name')?.hasError('pattern')">{{ 'error.pattern' |
                                    translate }}</mat-error>
                                <mat-error *ngIf="userForm.get('name')?.hasError('maxLength')">{{ 'error.maxLength' |
                                    translate }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <!-- Email Section -->
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>{{ 'users.email' | translate }}<span class="obligatorio"></span></mat-label>
                                <input matInput class="form-field" formControlName="email" type="text" required>
                                <mat-error *ngIf="userForm.get('email')?.hasError('required')">{{ 'error.required.field'
                                    | translate }}</mat-error>
                                <mat-error *ngIf="userForm.get('email')?.hasError('maxLength')">{{ 'error.maxLength' |
                                    translate }}</mat-error>
                                <mat-error *ngIf="userForm.get('email')?.hasError('email')">{{ 'error.email' | translate
                                    }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <!-- Role Section -->
                <div class="row">
                    <div class="col-lg-6 col-12">
                        <div class="form-group">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>{{ 'users.rol' | translate }}<span class="obligatorio"></span></mat-label>
                                <mat-select formControlName="idRol" required>
                                    <mat-option *ngFor="let rol of listaRol" [value]="rol.id">
                                        {{ rol.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="userForm.get('idRol')?.hasError('required')">{{ 'error.required.field'
                                    | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Hierarchy Section -->
                    <div class="col-lg-6 col-12" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_3)">
                        <div class="form-group">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>{{ 'users.hierarchy' | translate }}<span
                                        class="obligatorio"></span></mat-label>
                                <mat-select formControlName="hierarchyLevel">
                                    <mat-option *ngFor="let opt of hierarchyOptions" [value]="opt.value">{{ opt.label |
                                        translate }}</mat-option>
                                </mat-select>
                                 <mat-error *ngIf="userForm.get('hierarchyLevel')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                
                <!-- Operator Section -->
                <div class="row">
                    <div class="col-12" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_1)">
                        <div class="form-group">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>{{ 'users.operator' | translate }}<span
                                        class="obligatorio"></span></mat-label>
                                <mat-select formControlName="idOperator">
                                    <mat-option *ngFor="let op of listaOpe" [value]="op.id">
                                        {{ op.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="userForm.get('idOperator')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <!-- Client and SubClient Sections -->
                <div class="row">
                    <div class="col-lg-6 col-12" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_2)">
                        <div class="form-group">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>{{ 'users.client' | translate }}<span class="obligatorio"></span></mat-label>
                                <mat-select formControlName="idClient">
                                    <mat-option *ngFor="let cl of clientsList" [value]="cl.id">
                                        {{ cl.name }}
                                    </mat-option>
                                </mat-select> 
                                <mat-error *ngIf="userForm.get('idClient')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-6 col-12" *ngIf="hierarchy.canByLevel(hierarchy.level.LEVEL_3)">
                        <div class="form-group">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>{{ 'users.subClient' | translate }}<span
                                        class="obligatorio"></span></mat-label>
                                <mat-select formControlName="idSubClient">
                                    <mat-option *ngFor="let subCl of subClientsList" [value]="subCl.id">
                                        {{ subCl.name }}
                                    </mat-option>
                                </mat-select>
                                 <mat-error *ngIf="userForm.get('idSubClient')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

            </div>
        </fieldset>

        <div class="footer" *ngIf="!readOnly">
            <button 
                *ngIf="rol.canByInput(rol.functIds.delete_subclients) && !user?.dateDelete"
                mat-stroke-button 
                class="custom-white-button"
                (click)="onDelete()">
                {{ 'users.eliminar' | translate }}
                <img class="delete-icon" src="assets/img/delete_icon.svg" alt="Delete icon">
            </button>
            <button 
                *ngIf="rol.canByInput(rol.functIds.edit_users)" 
                matTooltip="{{ 'tooltips.resetPassword' | translate }}" 
                (click)="onResetPassword()" 
                mat-icon-button 
                class="custom-reset-button">
                <mat-icon>lock_reset</mat-icon>
            </button>
          </div>
          
    </form>
</div>