import { Component, forwardRef, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormControl, FormGroup, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { TranslateModule } from '@ngx-translate/core';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';
import { Operator } from 'src/app/models/operator.model';
import { Client, SubClient } from 'src/app/models/client.model';
import { OperatorService } from 'src/app/pages/admin/operator/operator.service';
import { ClientService } from 'src/app/pages/admin/client/client.service';
import { SubClientService } from 'src/app/pages/admin/sub-client/sub-client.service';
import { FilterableValue, FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { UtilService } from 'src/app/services/util.service';

export interface HierarchyInput {
  idOperator?:number; 
  idClient?:number;
  idSubClient?:number;
}


@Component({
  selector: 'app-hierarchy-selector',
  standalone: true,
  imports: [CommonModule, 
    FormsModule, 
    ReactiveFormsModule, 
    MatLegacyFormFieldModule,
    MatLegacySelectModule,
    TranslateModule
  ],
  templateUrl: './hierarchy-selector.component.html',
  styleUrls: ['./hierarchy-selector.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => HierarchySelectorComponent), multi: true
    }
  ]
})
export class HierarchySelectorComponent implements OnInit, ControlValueAccessor{
  public hierarchy = inject(HierarchyHandlerService);
  private operators = inject(OperatorService);
  private clients = inject(ClientService);
  private subclients = inject(SubClientService);
  private utils = inject(UtilService);

  
  hierarchyForm: FormGroup = new FormGroup({
    idOperator: new FormControl(null),
    idClient: new FormControl(null),
    idSubClient: new FormControl(null),
  }); 

  operatorsList: Operator[] = [];
  clientsList: Client[] = [];
  subClientsList: SubClient[] = [];
  disabled = false;
  
  ngOnInit(): void {
    if(this.hierarchy.canByLevel(this.hierarchy.level.LEVEL_1)) this.loadOperators();

    this.hierarchyForm.get('idOperator')?.valueChanges.subscribe((value:number) => {
      this.loadClients(value);
    });
    
    this.hierarchyForm.get('idClient')?.valueChanges.subscribe((value:number)=>{
      this.loadSubClients(value);
    });

    this.hierarchyForm.valueChanges.subscribe((value:HierarchyInput)=>{ if(!this.disabled) this.onChange(value) });
  }

  private async loadOperators(){
    const request = new FilterAndPagBean(null, null, [], null, null, null);
    const response = await this.operators.find(request);
    this.operatorsList = response?.data || [];
  }

  private async loadClients(idOperator: number | null = null): Promise<void> {
    if(!idOperator) { 
      this.clientsList = [];
      return;
    }
  
    const filters = idOperator ? [new FilterableValue("idOperator", idOperator, "long", "EQUALS")] : [];
    const request = new FilterAndPagBean(null, null, filters, null, null, null);
    const response = await this.clients.getListed(request);
    this.clientsList = response?.data || [];
  }

  private async loadSubClients(idClient: number | null = null): Promise<void> {
    if(!idClient) { 
      this.subClientsList = [];
      return;
    }
  
    const filters = idClient ? [new FilterableValue("idClient", idClient, "long", "EQUALS")] : [];
    const request = new FilterAndPagBean(null, null, filters, null, null, null);
    const response = await this.subclients.getListed(request);
    this.subClientsList = response?.data || [];
  }

  updateFormState(input:('idOperator' | 'idClient')){
    if(input === 'idOperator') {
      this.hierarchyForm.patchValue({ idClient:null, idSubClient:null });
    }
    
    if(input === 'idClient') {
      this.hierarchyForm.patchValue({ idSubClient:null });
    }
  }

  // Function to call when the value changes.
  onChange = (value:(HierarchyInput | null)) => {};

  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {};

  
  writeValue(value: HierarchyInput): void {
    this.hierarchyForm.patchValue({
      idOperator: value.idOperator ?? this.utils.getOperatorId(), 
      idClient: value.idClient ?? this.utils.getClientId(), 
      idSubClient: value.idSubClient ?? this.utils.getSubClientId(), 
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;    
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled; 
    if (this.disabled) {
      this.hierarchyForm.disable()
    } else {
      this.hierarchyForm.enable();
    } 
  }
}
