import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

enum LANGUAGES {
    en = 'en',
    es = 'es'
};

export type LangTypes = keyof typeof LANGUAGES;


@Injectable({
    providedIn: 'root',
})
  
export class LanguageService {
    public readonly availableLangs: LangTypes[] = Object.values(LANGUAGES);
    public readonly defaultLang:LangTypes = LANGUAGES.es.valueOf() as LangTypes;

    constructor(private readonly translateService:TranslateService){}

    public init(){
        this.translateService.addLangs(this.availableLangs);
        this.translateService.setDefaultLang(this.defaultLang);
        const browserLang = this.translateService.getBrowserLang();
        const initLanguage = browserLang.match(/en|es/) ? browserLang : this.defaultLang;
        this.translateService.use(initLanguage);
    }

    public setLanguage(lang:LangTypes){
        this.translateService.use(lang ?? this.defaultLang);
    }

    public getLanguage(){
        return this.translateService.currentLang;
    }

}