
<div class="applied-filters">
    <div class="applied-filters__list" *ngFor="let filter of filters; let i=index">
        <app-filter-pill [filter]="filter.display" (remove)="removeItem(i)"></app-filter-pill>
    </div>

    <div class="applied-filters__trigger" #trigger>
        <button mat-icon-button (click)="onTrigger.emit(triggerElem)">
            <mat-icon>filter_list</mat-icon>
        </button>
    </div>
</div>
